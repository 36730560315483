import React from "react";

import styles from "./StreetArt.module.css";

const StreetArt = () => (
  <div className={styles.StreetArt}>
    <svg
      width="837"
      height="1000"
      viewBox="0 0 837 1000"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="675.5" cy="295.5" rx="445.5" ry="338.5" fill="#7CDEF4" />
      <ellipse cx="846" cy="615" rx="425" ry="311" fill="#7CDEF4" />
      <ellipse cx="400" cy="585.5" rx="400" ry="292.5" fill="#7CDEF4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M203.831 390.7H206.19V389.014H203.831V390.7Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M206.405 391.272L171.655 411.406C169.934 412.403 166.926 412.278 164.937 411.127L129.811 390.813C127.822 389.662 127.607 387.921 129.328 386.928L164.078 366.794C165.799 365.798 168.807 365.922 170.796 367.073L205.922 387.387C207.911 388.536 208.126 390.276 206.405 391.272Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M166.978 367.654C166.158 367.654 165.39 367.807 164.843 368.122L130.096 388.256C129.772 388.441 129.698 388.596 129.698 388.613C129.698 388.685 129.855 389.064 130.579 389.485L165.705 409.796C167.213 410.667 169.633 410.8 170.89 410.075L205.637 389.944C205.961 389.756 206.035 389.601 206.035 389.584C206.035 389.512 205.878 389.133 205.154 388.715L170.028 368.402C169.177 367.912 168.036 367.654 166.978 367.654ZM168.724 413.623C167.147 413.623 165.506 413.227 164.172 412.455L129.046 392.144C127.513 391.256 126.631 389.969 126.631 388.613C126.631 387.407 127.336 386.305 128.56 385.597L163.31 365.466C165.525 364.176 169.075 364.303 171.561 365.745L206.687 386.056C208.22 386.942 209.099 388.226 209.102 389.582C209.102 390.794 208.397 391.892 207.173 392.601L172.423 412.735C171.396 413.329 170.083 413.623 168.724 413.623Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M206.405 388.359L171.655 408.49C169.934 409.486 166.926 409.361 164.937 408.213L129.811 387.899C127.822 386.748 127.607 385.007 129.328 384.011L164.078 363.88C165.799 362.884 168.807 363.008 170.796 364.157L205.922 384.471C207.911 385.622 208.126 387.362 206.405 388.359Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M166.978 364.741C166.158 364.741 165.39 364.893 164.843 365.208L130.096 385.342C129.772 385.528 129.698 385.683 129.698 385.699C129.698 385.771 129.855 386.15 130.579 386.568L165.705 406.885C167.216 407.751 169.639 407.884 170.89 407.161L205.637 387.025C205.961 386.842 206.035 386.69 206.035 386.671C206.035 386.599 205.878 386.219 205.154 385.802L170.028 365.488C169.177 364.995 168.036 364.741 166.978 364.741ZM168.722 410.709C167.144 410.709 165.506 410.316 164.172 409.544L129.046 389.227C127.513 388.345 126.634 387.058 126.631 385.702C126.631 384.493 127.336 383.391 128.56 382.683L163.31 362.549C165.525 361.262 169.075 361.39 171.561 362.829L206.687 383.142C208.223 384.031 209.102 385.315 209.102 386.673C209.099 387.88 208.394 388.981 207.173 389.69L172.423 409.818C171.396 410.416 170.083 410.709 168.722 410.709Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M167.503 412.079H169.862V408.897H167.503V412.079Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M129.035 391.131H131.394V387.949H129.035V391.131Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M204.157 391.895H206.516V388.713H204.157V391.895Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M166.315 399.817C165.404 400.343 165.404 401.201 166.315 401.73C167.23 402.255 168.713 402.255 169.625 401.73C170.536 401.201 170.536 400.343 169.625 399.817C168.713 399.289 167.23 399.289 166.315 399.817ZM170.625 402.308C169.161 403.155 166.779 403.155 165.318 402.308C163.854 401.461 163.854 400.083 165.318 399.239C166.779 398.392 169.161 398.392 170.625 399.239C172.086 400.083 172.086 401.461 170.625 402.308Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M166.282 390.312C165.371 390.841 165.371 391.698 166.282 392.224C167.194 392.753 168.677 392.753 169.592 392.224C170.503 391.698 170.503 390.841 169.592 390.312C168.677 389.784 167.194 389.784 166.282 390.312ZM170.589 392.803C169.128 393.649 166.746 393.649 165.282 392.803C163.821 391.959 163.821 390.581 165.282 389.734C166.746 388.887 169.128 388.887 170.592 389.734C172.053 390.581 172.053 391.959 170.589 392.803Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M181.214 391.2L184.468 393.115L187.775 391.231C187.791 391.223 187.791 391.209 187.777 391.2L184.52 389.319L181.214 391.2ZM188.775 391.809L185.52 393.691C184.954 394.02 184.034 394.02 183.468 393.694L180.214 391.809C179.647 391.483 179.647 390.951 180.214 390.622L183.468 388.74C184.034 388.414 184.954 388.414 185.52 388.74L188.775 390.622C189.341 390.949 189.341 391.483 188.775 391.809Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M156.456 395.996L159.71 397.908L163.02 396.026C163.033 396.018 163.033 396.004 163.017 395.996L159.763 394.114L156.456 395.996ZM164.017 396.605L160.763 398.486C160.196 398.813 159.276 398.813 158.71 398.486L155.456 396.605C154.889 396.278 154.889 395.744 155.456 395.418L158.71 393.536C159.276 393.207 160.196 393.207 160.763 393.536L164.017 395.418C164.583 395.744 164.583 396.278 164.017 396.605Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M178.915 387.412L177.915 387.99L173.606 385.497L174.606 384.919L178.915 387.412Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M178.915 385.497L174.606 387.99L173.608 387.412L177.915 384.919L178.915 385.497Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M178.824 396.987L177.824 397.565L173.517 395.074L174.517 394.496L178.824 396.987Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M178.824 395.074L174.517 397.565L173.517 396.987L177.824 394.496L178.824 395.074Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M150.287 390.545C149.375 391.073 149.375 391.931 150.287 392.459C151.199 392.985 152.682 392.985 153.594 392.459C154.508 391.931 154.508 391.073 153.594 390.545C152.682 390.019 151.199 390.019 150.287 390.545ZM154.594 393.038C153.132 393.885 150.751 393.885 149.287 393.038C147.826 392.191 147.826 390.813 149.287 389.969C150.751 389.122 153.132 389.122 154.594 389.969C156.058 390.813 156.058 392.191 154.594 393.038Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M165.185 381.93L168.44 383.845L171.746 381.961C171.76 381.952 171.763 381.939 171.746 381.93L168.492 380.049L165.185 381.93ZM172.747 382.539L169.492 384.421C168.926 384.75 168.006 384.75 167.44 384.424L164.183 382.539C163.619 382.213 163.619 381.681 164.185 381.352L167.44 379.47C168.006 379.144 168.926 379.144 169.492 379.47L172.747 381.352C173.313 381.681 173.313 382.213 172.747 382.539Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M162.793 387.716L161.796 388.295L157.486 385.804L158.486 385.226L162.793 387.716Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M162.796 385.804L158.486 388.295L157.486 387.716L161.796 385.226L162.796 385.804Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M133.129 400.993L129.795 402.919C129.455 403.119 128.858 403.094 128.463 402.864L125.322 401.049C124.927 400.819 124.883 400.476 125.225 400.277L128.56 398.351C128.899 398.151 129.496 398.176 129.891 398.406L133.032 400.221C133.427 400.451 133.471 400.794 133.129 400.993Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M132.715 410.205L129.38 412.131C129.038 412.328 128.441 412.306 128.049 412.076L124.905 410.261C124.51 410.031 124.468 409.685 124.811 409.489L128.142 407.56C128.485 407.363 129.082 407.388 129.477 407.615L132.618 409.433C133.013 409.66 133.054 410.006 132.715 410.205Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M140.886 405.479L137.552 407.405C137.209 407.604 136.612 407.579 136.217 407.35L133.076 405.534C132.681 405.305 132.64 404.962 132.982 404.762L136.314 402.836C136.657 402.637 137.253 402.662 137.648 402.892L140.789 404.707C141.184 404.937 141.226 405.28 140.886 405.479Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M140.469 414.688L137.135 416.617C136.795 416.813 136.198 416.788 135.803 416.562L132.662 414.744C132.267 414.517 132.223 414.171 132.565 413.974L135.9 412.046C136.24 411.849 136.836 411.874 137.231 412.101L140.372 413.919C140.767 414.146 140.812 414.492 140.469 414.688Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M148.641 409.965L145.306 411.891C144.966 412.09 144.37 412.065 143.975 411.835L140.834 410.02C140.439 409.79 140.394 409.447 140.737 409.248L144.071 407.319C144.411 407.123 145.008 407.148 145.403 407.374L148.544 409.193C148.939 409.422 148.983 409.765 148.641 409.965Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M148.676 419.437L145.342 421.363C145.002 421.562 144.406 421.537 144.011 421.307L140.87 419.492C140.474 419.262 140.43 418.919 140.773 418.72L144.107 416.794C144.447 416.595 145.044 416.62 145.439 416.849L148.58 418.665C148.975 418.894 149.019 419.237 148.676 419.437Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M156.848 414.71L153.514 416.639C153.174 416.835 152.577 416.811 152.182 416.584L149.041 414.766C148.646 414.539 148.602 414.193 148.944 413.994L152.279 412.068C152.619 411.868 153.215 411.893 153.61 412.123L156.751 413.938C157.146 414.168 157.191 414.514 156.848 414.71Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M156.431 423.922L153.099 425.848C152.757 426.047 152.16 426.023 151.765 425.793L148.624 423.978C148.229 423.748 148.187 423.402 148.53 423.206L151.862 421.277C152.204 421.08 152.801 421.105 153.196 421.332L156.337 423.15C156.732 423.377 156.773 423.723 156.431 423.922Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M164.603 419.196L161.271 421.122C160.928 421.321 160.332 421.296 159.937 421.066L156.796 419.251C156.401 419.022 156.359 418.678 156.699 418.479L160.033 416.553C160.376 416.354 160.973 416.379 161.368 416.609L164.509 418.424C164.904 418.654 164.945 418.997 164.603 419.196Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M164.188 428.408L160.854 430.334C160.514 430.533 159.917 430.508 159.522 430.278L156.381 428.463C155.986 428.234 155.942 427.888 156.285 427.691L159.619 425.762C159.959 425.566 160.555 425.591 160.95 425.818L164.091 427.636C164.486 427.863 164.531 428.209 164.188 428.408Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M172.36 423.681L169.025 425.607C168.686 425.807 168.089 425.782 167.694 425.552L164.553 423.737C164.158 423.507 164.114 423.164 164.456 422.965L167.791 421.039C168.13 420.84 168.727 420.864 169.122 421.094L172.263 422.909C172.658 423.139 172.702 423.482 172.36 423.681Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M180.531 418.955L177.197 420.884C176.857 421.08 176.26 421.055 175.865 420.829L172.724 419.01C172.329 418.784 172.285 418.438 172.628 418.238L175.959 416.313C176.302 416.113 176.899 416.138 177.294 416.368L180.435 418.183C180.83 418.413 180.874 418.759 180.531 418.955Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M188.703 414.229L185.369 416.158C185.029 416.354 184.429 416.329 184.037 416.102L180.893 414.284C180.501 414.057 180.457 413.711 180.799 413.515L184.131 411.586C184.473 411.39 185.07 411.415 185.465 411.641L188.606 413.46C189.001 413.686 189.043 414.032 188.703 414.229Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M196.875 409.503L193.54 411.431C193.198 411.63 192.601 411.606 192.209 411.376L189.065 409.558C188.673 409.331 188.628 408.985 188.971 408.789L192.303 406.86C192.645 406.663 193.242 406.688 193.637 406.915L196.778 408.733C197.173 408.96 197.214 409.306 196.875 409.503Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M205.046 404.779L201.712 406.705C201.369 406.904 200.772 406.879 200.38 406.65L197.236 404.834C196.844 404.605 196.8 404.261 197.143 404.062L200.474 402.136C200.817 401.937 201.413 401.962 201.808 402.192L204.949 404.007C205.345 404.237 205.386 404.58 205.046 404.779Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M180.567 428.427L177.233 430.356C176.893 430.552 176.296 430.528 175.901 430.301L172.76 428.483C172.365 428.256 172.321 427.91 172.664 427.711L175.995 425.785C176.338 425.588 176.935 425.61 177.33 425.84L180.471 427.655C180.866 427.885 180.91 428.231 180.567 428.427Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M188.739 423.701L185.404 425.63C185.065 425.826 184.468 425.801 184.073 425.574L180.932 423.756C180.537 423.529 180.493 423.183 180.835 422.987L184.167 421.058C184.509 420.862 185.106 420.887 185.501 421.114L188.642 422.932C189.037 423.158 189.081 423.504 188.739 423.701Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M196.91 418.977L193.576 420.903C193.236 421.102 192.637 421.078 192.245 420.848L189.101 419.033C188.708 418.803 188.664 418.457 189.007 418.261L192.338 416.332C192.681 416.135 193.278 416.16 193.673 416.387L196.814 418.205C197.209 418.432 197.25 418.778 196.91 418.977Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M205.082 414.251L201.748 416.177C201.405 416.376 200.808 416.351 200.416 416.122L197.272 414.306C196.88 414.077 196.836 413.733 197.178 413.534L200.51 411.608C200.853 411.409 201.449 411.434 201.844 411.664L204.985 413.479C205.38 413.709 205.422 414.052 205.082 414.251Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M213.254 409.525L209.919 411.453C209.577 411.65 208.98 411.625 208.588 411.398L205.444 409.58C205.049 409.353 205.007 409.007 205.35 408.808L208.682 406.882C209.024 406.683 209.621 406.708 210.016 406.937L213.157 408.753C213.552 408.982 213.593 409.328 213.254 409.525Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M213.182 400.072L209.847 402.001C209.505 402.197 208.908 402.172 208.516 401.945L205.372 400.127C204.98 399.9 204.936 399.555 205.278 399.358L208.61 397.429C208.952 397.233 209.549 397.258 209.944 397.485L213.085 399.303C213.48 399.53 213.522 399.876 213.182 400.072Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M867.512 748.944L832.765 769.075C831.044 770.071 828.035 769.946 826.046 768.798L790.92 748.484C788.931 747.333 788.713 745.592 790.437 744.596L825.184 724.465C826.908 723.469 829.917 723.593 831.903 724.742L867.031 745.056C869.018 746.207 869.236 747.947 867.512 748.944Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M828.085 725.326C827.267 725.326 826.496 725.478 825.952 725.793L791.202 745.927C790.882 746.113 790.807 746.268 790.807 746.284C790.807 746.356 790.965 746.735 791.688 747.153L826.814 767.467C828.32 768.336 830.743 768.471 831.997 767.746L866.747 747.613C867.067 747.427 867.142 747.272 867.142 747.256C867.142 747.184 866.984 746.805 866.263 746.387L831.135 726.073C830.287 725.583 829.146 725.326 828.085 725.326ZM829.831 771.294C828.256 771.294 826.613 770.898 825.278 770.126L790.152 749.812C788.619 748.927 787.741 747.64 787.741 746.284C787.741 745.078 788.442 743.976 789.669 743.268L824.416 723.134C826.635 721.847 830.185 721.975 832.671 723.414L867.797 743.727C869.33 744.61 870.206 745.897 870.208 747.253C870.208 748.462 869.507 749.563 868.28 750.272L833.533 770.406C832.505 771.001 831.19 771.294 829.831 771.294Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M867.512 746.03L832.765 766.161C831.044 767.157 828.035 767.033 826.046 765.881L790.92 745.568C788.931 744.419 788.713 742.679 790.437 741.683L825.184 721.549C826.908 720.552 829.917 720.68 831.903 721.828L867.031 742.142C869.018 743.293 869.236 745.034 867.512 746.03Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M828.085 722.412C827.267 722.412 826.496 722.564 825.952 722.88L791.202 743.011C790.882 743.199 790.807 743.354 790.807 743.37C790.807 743.44 790.965 743.821 791.688 744.239L826.814 764.553C828.323 765.422 830.745 765.555 831.997 764.83L866.747 744.696C867.067 744.51 867.142 744.358 867.142 744.342C867.142 744.27 866.984 743.891 866.263 743.47L831.135 723.159C830.287 722.666 829.146 722.412 828.085 722.412ZM829.828 768.377C828.254 768.377 826.615 767.987 825.278 767.215L790.152 746.899C788.619 746.013 787.744 744.729 787.741 743.37C787.741 742.161 788.442 741.063 789.669 740.354L824.416 720.22C826.635 718.934 830.185 719.061 832.671 720.5L867.797 740.814C869.333 741.702 870.208 742.986 870.208 744.344C870.206 745.551 869.504 746.652 868.28 747.361L833.533 767.489C832.505 768.084 831.19 768.377 829.828 768.377Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M828.61 769.75H830.969V766.568H828.61V769.75Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M790.144 748.802H792.503V745.62H790.144V748.802Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M827.425 757.486C826.513 758.014 826.513 758.872 827.425 759.401C828.336 759.927 829.82 759.927 830.731 759.401C831.646 758.872 831.646 758.014 830.731 757.486C829.82 756.96 828.336 756.96 827.425 757.486ZM831.731 759.979C830.27 760.823 827.889 760.823 826.425 759.979C824.963 759.132 824.963 757.754 826.425 756.908C827.889 756.064 830.27 756.064 831.731 756.908C833.196 757.754 833.196 759.132 831.731 759.979Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M827.389 747.983C826.477 748.509 826.477 749.37 827.392 749.895C828.303 750.424 829.787 750.424 830.698 749.895C831.61 749.37 831.61 748.509 830.698 747.983C829.787 747.455 828.303 747.455 827.389 747.983ZM831.698 750.474C830.234 751.321 827.853 751.321 826.392 750.474C824.927 749.627 824.927 748.252 826.392 747.405C827.853 746.558 830.234 746.558 831.698 747.405C833.163 748.252 833.163 749.627 831.698 750.474Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M817.563 753.667L820.819 755.579L824.126 753.698C824.14 753.689 824.14 753.675 824.126 753.667L820.872 751.783L817.563 753.667ZM825.126 754.276L821.872 756.158C821.306 756.484 820.386 756.484 819.819 756.158L816.565 754.276C815.999 753.947 815.999 753.415 816.563 753.089L819.819 751.204C820.383 750.878 821.306 750.878 821.872 751.207L825.126 753.089C825.693 753.415 825.693 753.947 825.126 754.276Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M840.022 745.081L839.022 745.659L834.715 743.169L835.715 742.59L840.022 745.081Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M840.022 743.169L835.715 745.659L834.715 745.081L839.022 742.59L840.022 743.169Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M839.931 754.658L838.933 755.236L834.624 752.746L835.624 752.167L839.931 754.658Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M839.934 752.746L835.624 755.236L834.624 754.658L838.933 752.167L839.934 752.746Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M811.397 748.216C810.485 748.744 810.485 749.602 811.397 750.131C812.308 750.656 813.792 750.656 814.703 750.131C815.615 749.602 815.615 748.744 814.703 748.216C813.792 747.69 812.308 747.69 811.397 748.216ZM815.703 750.709C814.239 751.553 811.858 751.553 810.396 750.709C808.932 749.862 808.932 748.484 810.396 747.637C811.858 746.793 814.239 746.793 815.703 747.637C817.167 748.484 817.167 749.862 815.703 750.709Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M826.292 739.602L829.546 741.514L832.856 739.632C832.87 739.624 832.87 739.61 832.856 739.602L829.599 737.72L826.292 739.602ZM833.853 740.21L830.599 742.092C830.033 742.421 829.113 742.421 828.546 742.092L825.292 740.21C824.726 739.884 824.726 739.35 825.292 739.023L828.546 737.142C829.113 736.812 830.035 736.812 830.599 737.142L833.856 739.023C834.42 739.35 834.42 739.884 833.853 740.21Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M823.902 745.388L822.902 745.966L818.596 743.476L819.596 742.897L823.902 745.388Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M823.902 743.476L819.596 745.966L818.596 745.388L822.902 742.897L823.902 743.476Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M794.238 758.665L790.904 760.591C790.561 760.79 789.965 760.765 789.57 760.535L786.429 758.72C786.034 758.49 785.992 758.145 786.335 757.948L789.666 756.019C790.009 755.823 790.606 755.848 791.001 756.075L794.142 757.893C794.537 758.12 794.578 758.466 794.238 758.665Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M793.821 767.874L790.489 769.803C790.147 769.999 789.55 769.974 789.155 769.747L786.014 767.929C785.619 767.702 785.575 767.356 785.918 767.16L789.252 765.231C789.592 765.035 790.188 765.06 790.583 765.286L793.727 767.104C794.119 767.331 794.164 767.677 793.821 767.874Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M801.993 763.15L798.661 765.076C798.319 765.273 797.722 765.251 797.327 765.021L794.186 763.206C793.791 762.976 793.746 762.63 794.089 762.434L797.423 760.505C797.763 760.309 798.36 760.333 798.755 760.56L801.896 762.378C802.291 762.605 802.335 762.951 801.993 763.15Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M801.578 772.359L798.244 774.288C797.901 774.485 797.305 774.46 796.912 774.233L793.769 772.415C793.376 772.188 793.332 771.842 793.675 771.645L797.006 769.717C797.349 769.52 797.946 769.545 798.341 769.772L801.482 771.59C801.877 771.817 801.918 772.163 801.578 772.359Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M809.75 767.633L806.416 769.562C806.073 769.758 805.476 769.733 805.084 769.506L801.94 767.688C801.545 767.461 801.504 767.116 801.846 766.919L805.178 764.99C805.52 764.794 806.117 764.819 806.512 765.046L809.653 766.864C810.048 767.091 810.09 767.437 809.75 767.633Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M809.786 777.105L806.452 779.034C806.109 779.23 805.512 779.208 805.12 778.979L801.976 777.161C801.581 776.934 801.54 776.588 801.882 776.391L805.214 774.463C805.557 774.266 806.153 774.291 806.548 774.518L809.689 776.336C810.084 776.563 810.126 776.909 809.786 777.105Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M817.958 772.382L814.623 774.308C814.281 774.507 813.684 774.482 813.289 774.252L810.148 772.437C809.753 772.207 809.711 771.864 810.051 771.665L813.386 769.736C813.728 769.54 814.325 769.564 814.72 769.794L817.861 771.609C818.256 771.839 818.297 772.182 817.958 772.382Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M817.54 781.591L814.209 783.519C813.866 783.716 813.269 783.691 812.874 783.464L809.733 781.646C809.338 781.419 809.294 781.073 809.637 780.877L812.971 778.948C813.311 778.751 813.908 778.776 814.303 779.003L817.444 780.821C817.839 781.048 817.883 781.394 817.54 781.591Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M825.712 776.867L822.378 778.793C822.038 778.992 821.441 778.967 821.046 778.738L817.905 776.923C817.51 776.693 817.466 776.347 817.808 776.15L821.143 774.222C821.482 774.025 822.079 774.05 822.474 774.277L825.615 776.095C826.01 776.322 826.055 776.668 825.712 776.867Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M825.298 786.076L821.963 788.005C821.621 788.202 821.024 788.177 820.629 787.95L817.488 786.132C817.093 785.905 817.051 785.559 817.394 785.362L820.726 783.434C821.068 783.237 821.665 783.262 822.06 783.489L825.201 785.307C825.596 785.534 825.637 785.88 825.298 786.076Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M833.466 781.35L830.135 783.279C829.792 783.478 829.195 783.453 828.8 783.223L825.659 781.408C825.264 781.178 825.223 780.832 825.566 780.636L828.897 778.707C829.24 778.511 829.836 778.536 830.231 778.763L833.372 780.581C833.768 780.808 833.809 781.153 833.466 781.35Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M841.638 776.626L838.306 778.552C837.964 778.752 837.367 778.727 836.972 778.497L833.831 776.682C833.436 776.452 833.395 776.109 833.734 775.91L837.069 773.984C837.411 773.785 838.008 773.809 838.403 774.039L841.544 775.854C841.939 776.084 841.981 776.427 841.638 776.626Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M841.674 786.098L838.342 788.024C838 788.224 837.403 788.199 837.008 787.969L833.867 786.154C833.472 785.924 833.43 785.581 833.773 785.382L837.105 783.456C837.447 783.257 838.044 783.282 838.439 783.511L841.58 785.326C841.975 785.556 842.016 785.899 841.674 786.098Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M835.674 633.267C835.674 651.852 848.696 674.449 864.763 683.741C880.83 693.03 893.855 685.498 893.855 666.916C893.855 648.332 880.83 625.735 864.763 616.443C848.696 607.151 835.674 614.683 835.674 633.267Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M844.961 615.732C839.958 618.623 837.207 624.852 837.207 633.267C837.207 651.331 849.915 673.378 865.531 682.41C872.808 686.616 879.571 687.346 884.568 684.452C889.568 681.56 892.322 675.331 892.322 666.916C892.322 648.849 879.615 626.806 863.998 617.774C859.653 615.261 855.489 613.991 851.777 613.991C849.274 613.991 846.975 614.567 844.961 615.732ZM863.998 685.069C847.533 675.55 834.138 652.311 834.138 633.267C834.138 623.707 837.439 616.534 843.428 613.072C849.417 609.608 857.266 610.333 865.531 615.112C881.996 624.634 895.389 647.873 895.389 666.916C895.389 676.474 892.09 683.647 886.101 687.111C883.609 688.553 880.794 689.269 877.758 689.269C873.507 689.269 868.821 687.861 863.998 685.069Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M639.279 771.648C639.262 771.623 636.445 771.446 636.469 771.432C636.823 771.228 862.125 641.381 862.125 641.381C863.678 640.515 866.031 641.046 868.498 643.392C870.402 645.202 871.742 647.551 872.333 650.111C873.131 653.551 872.286 655.872 870.606 656.667L644.986 786.646C644.962 786.66 646.343 786.934 646.332 786.907L645.655 785.216C645.412 785.108 645.166 784.989 644.915 784.842C641.503 782.869 638.735 778.068 638.735 774.122C638.735 773.785 638.762 773.475 638.801 773.173L639.279 771.648Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M621.682 742.933C621.604 742.825 621.626 742.682 621.74 742.615C623.405 741.655 629.939 737.678 629.939 737.678C637.246 733.597 648.315 736.104 659.937 747.153C668.893 755.671 675.198 766.731 677.991 778.785C681.742 794.97 677.767 805.895 669.86 809.633L662.158 814.346C662.04 814.415 661.888 814.362 661.838 814.235L658.658 806.271C657.512 805.768 656.352 805.203 655.166 804.52C639.099 795.227 626.074 772.631 626.074 754.046C626.074 752.466 626.201 750.997 626.383 749.58L621.682 742.933Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M630.707 739.009L630.185 739.325C628.911 740.1 625.726 742.034 623.754 743.21L627.98 749.181L627.906 749.776C627.701 751.351 627.61 752.704 627.61 754.046C627.61 772.11 640.315 794.156 655.934 803.188C656.951 803.778 658.012 804.312 659.274 804.868L659.849 805.12L662.686 812.226L669.208 808.244C677.013 804.555 679.805 793.401 676.496 779.131C673.753 767.287 667.659 756.614 658.882 748.266C651.373 741.126 643.779 737.346 637.392 737.346C634.967 737.346 632.712 737.894 630.707 739.009ZM661.479 815.818L660.675 815.461L657.465 807.419C656.34 806.91 655.357 806.401 654.398 805.848C637.936 796.329 624.541 773.087 624.541 754.046C624.541 752.746 624.621 751.442 624.789 749.984L620.43 743.824L620.425 743.816C620.137 743.406 620.035 742.894 620.146 742.407L620.394 741.619L620.977 741.284C622.339 740.498 626.991 737.673 628.596 736.699C628.812 736.566 629.011 736.447 629.198 736.35L629.193 736.339C637.713 731.574 649.6 735.204 660.993 746.038C670.208 754.802 676.601 766.006 679.482 778.436C683.137 794.195 679.7 806.681 670.661 810.945L662.935 815.671C662.661 815.829 662.354 815.912 662.045 815.912C661.854 815.912 661.661 815.881 661.479 815.818Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M611.982 762.14C611.982 780.722 625.008 803.319 641.075 812.611C657.142 821.903 670.164 814.371 670.164 795.786C670.164 777.205 657.142 754.605 641.075 745.316C625.008 736.024 611.982 743.556 611.982 762.14Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M621.27 744.605C616.27 747.496 613.518 753.723 613.518 762.14C613.518 780.204 626.223 802.248 641.843 811.28C649.114 815.491 655.874 816.219 660.879 813.322C665.88 810.43 668.631 804.201 668.631 795.786C668.631 777.722 655.926 755.676 640.307 746.644C635.961 744.134 631.801 742.861 628.088 742.861C625.585 742.861 623.284 743.437 621.27 744.605ZM640.307 813.939C623.845 804.42 610.449 781.181 610.449 762.14C610.449 752.58 613.748 745.407 619.737 741.943C625.729 738.486 633.572 739.206 641.843 743.985C658.305 753.507 671.7 776.745 671.7 795.786C671.7 805.347 668.402 812.517 662.413 815.981C659.921 817.426 657.106 818.14 654.072 818.14C649.815 818.14 645.133 816.731 640.307 813.939Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M615.568 754.132C615.505 754.041 615.524 753.922 615.621 753.866C617.019 753.058 622.513 749.713 622.513 749.713C628.66 746.281 637.967 748.39 647.738 757.679C655.271 764.841 660.57 774.144 662.918 784.278C666.073 797.889 662.733 807.076 656.084 810.22L649.605 814.183C649.506 814.241 649.379 814.196 649.338 814.088L646.663 807.392C645.699 806.968 644.724 806.493 643.727 805.92C630.218 798.105 619.265 779.103 619.265 763.477C619.265 762.148 619.372 760.912 619.524 759.719L615.568 754.132Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M623.312 751.027C623.312 751.027 619.798 753.166 617.665 754.439L621.121 759.32L621.046 759.915C620.878 761.238 620.798 762.373 620.798 763.477C620.798 778.588 631.428 797.031 644.495 804.589C645.34 805.076 646.249 805.535 647.277 805.983L647.854 806.238L650.169 812.041L655.429 808.831C661.882 805.779 664.178 796.506 661.426 784.626C659.128 774.714 654.028 765.779 646.683 758.792C640.403 752.826 634.069 749.668 628.779 749.668C626.787 749.668 624.947 750.114 623.312 751.027ZM648.943 815.646L648.174 815.311L645.47 808.54C644.567 808.125 643.738 807.699 642.959 807.248C629.047 799.204 617.728 779.568 617.728 763.477C617.728 762.411 617.795 761.335 617.93 760.126L614.297 754.992C614.029 754.594 613.935 754.101 614.04 753.637L614.273 752.867L614.853 752.532C616.248 751.73 621.718 748.401 621.77 748.373C629.027 744.319 639.13 747.38 648.793 756.564C656.575 763.966 661.979 773.43 664.413 783.932C667.512 797.3 664.57 807.904 656.882 811.529L650.379 815.508C650.111 815.666 649.813 815.746 649.512 815.746C649.321 815.746 649.13 815.713 648.943 815.646Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M607.416 770.281C607.416 785.907 618.367 804.91 631.878 812.724C645.387 820.536 656.341 814.202 656.341 798.576C656.341 782.949 645.387 763.947 631.878 756.135C618.367 748.321 607.416 754.655 607.416 770.281Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M615.347 755.748C611.22 758.136 608.949 763.297 608.949 770.281C608.949 785.393 619.577 803.836 632.643 811.393C638.685 814.888 644.282 815.494 648.409 813.109C652.534 810.723 654.805 805.563 654.805 798.576C654.805 783.464 644.177 765.021 631.11 757.464C627.494 755.372 624.035 754.315 620.958 754.315C618.894 754.315 617.002 754.791 615.347 755.748ZM631.11 814.052C617.198 806.005 605.88 786.37 605.88 770.281C605.88 762.154 608.698 756.047 613.814 753.089C618.933 750.128 625.618 750.737 632.643 754.804C646.556 762.849 657.874 782.484 657.874 798.576C657.874 806.706 655.056 812.813 649.942 815.771C647.807 817.005 645.401 817.616 642.804 817.616C639.185 817.616 635.207 816.421 631.11 814.052Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M612.444 765.204C612.394 765.134 612.408 765.04 612.482 764.999C613.56 764.376 617.789 761.803 617.789 761.803C622.519 759.163 629.682 760.784 637.202 767.932C642.998 773.444 647.078 780.606 648.884 788.404C651.313 798.88 648.741 805.95 643.625 808.369L638.638 811.418C638.564 811.462 638.464 811.429 638.431 811.346L636.373 806.194C635.633 805.867 634.881 805.502 634.116 805.059C623.718 799.043 615.289 784.422 615.289 772.395C615.289 771.372 615.372 770.42 615.488 769.504L612.444 765.204Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M618.588 763.117C618.588 763.117 616.212 764.561 614.565 765.547L617.085 769.105L617.007 769.7C616.88 770.688 616.825 771.546 616.825 772.393C616.825 783.91 624.922 797.97 634.878 803.728C635.597 804.143 636.298 804.481 636.989 804.785L637.566 805.037L639.249 809.249L642.97 806.98C647.774 804.708 649.47 797.721 647.393 788.752C645.638 781.189 641.749 774.377 636.146 769.047C631.378 764.514 626.585 762.115 622.615 762.115C621.146 762.115 619.787 762.445 618.588 763.117ZM637.271 812.575L635.182 807.337C634.577 807.057 633.967 806.747 633.348 806.387C622.546 800.139 613.756 784.889 613.756 772.393C613.756 771.59 613.8 770.788 613.897 769.91L611.192 766.095L610.778 765.397L611.134 764.003L611.717 763.665C612.789 763.048 616.994 760.491 617.043 760.461C622.765 757.259 630.701 759.636 638.257 766.817C644.299 772.564 648.489 779.908 650.379 788.058C652.802 798.518 650.467 806.833 644.426 809.68L639.434 812.73L638.704 813.114L637.271 812.575Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M606.167 777.631C606.167 789.657 614.598 804.281 624.994 810.295C635.392 816.308 643.821 811.432 643.821 799.408C643.821 787.382 635.392 772.758 624.994 766.745C614.598 760.732 606.167 765.605 606.167 777.631Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M612.449 766.753C609.386 768.527 607.703 772.387 607.703 777.631C607.703 789.148 615.803 803.202 625.762 808.966C630.298 811.587 634.483 812.049 637.541 810.286C640.602 808.513 642.287 804.65 642.287 799.406C642.287 787.886 634.185 773.832 624.229 768.073C621.499 766.499 618.9 765.699 616.593 765.699C615.065 765.699 613.668 766.05 612.449 766.753ZM624.229 811.626C613.424 805.375 604.634 790.128 604.634 777.631C604.634 771.244 606.866 766.438 610.913 764.094C614.969 761.75 620.237 762.218 625.762 765.414C636.566 771.659 645.357 786.909 645.357 799.406C645.357 805.795 643.125 810.602 639.075 812.946C637.376 813.928 635.461 814.415 633.406 814.415C630.558 814.415 627.433 813.48 624.229 811.626Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M615.938 784.693C615.924 784.671 615.93 784.646 615.949 784.632C616.261 784.452 619.911 782.244 619.911 782.244C621.273 781.48 623.339 781.948 625.508 784.009C627.179 785.6 628.356 787.665 628.875 789.914C629.577 792.933 628.834 794.973 627.359 795.67L623.491 798.017C623.469 798.031 623.441 798.019 623.433 797.997L622.839 796.511C622.624 796.417 622.408 796.312 622.187 796.182C619.19 794.45 616.759 790.233 616.759 786.765C616.759 786.469 616.784 786.195 616.817 785.932L615.938 784.693Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M620.519 783.254C620.519 783.254 618.814 784.286 617.612 785.011L618.049 785.628L617.988 786.087C617.958 786.3 617.938 786.525 617.938 786.765C617.938 789.798 620.157 793.642 622.781 795.161C622.961 795.266 623.132 795.352 623.303 795.427L623.754 795.617L624.03 796.312L626.856 794.602C627.892 794.112 628.237 792.377 627.726 790.183C627.254 788.141 626.204 786.303 624.696 784.867C623.463 783.699 622.234 783.066 621.298 783.066C621.008 783.066 620.745 783.129 620.519 783.254ZM622.541 798.944L621.914 797.38C621.809 797.325 621.704 797.267 621.599 797.206C618.223 795.255 615.579 790.667 615.579 786.765C615.579 786.591 615.587 786.419 615.598 786.253L614.656 784.922L614.919 783.868L615.355 783.613C615.665 783.434 619.298 781.231 619.336 781.209C621.281 780.124 623.894 780.849 626.317 783.154C628.165 784.908 629.447 787.153 630.027 789.646C630.961 793.683 629.519 795.955 627.969 796.68L624.099 799.029L623.599 799.314L622.541 798.944Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M614.129 788.276C614.129 791.743 616.56 795.961 619.557 797.693C622.555 799.428 624.986 798.022 624.986 794.555C624.986 791.088 622.555 786.871 619.557 785.135C616.56 783.403 614.129 784.809 614.129 788.276Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M616.308 785.78C615.673 786.148 615.308 787.056 615.308 788.276C615.308 791.309 617.524 795.153 620.146 796.669C621.198 797.275 622.173 797.419 622.806 797.051C623.441 796.683 623.806 795.772 623.806 794.555C623.806 791.519 621.588 787.676 618.966 786.159C618.3 785.775 617.668 785.578 617.132 785.578C616.822 785.578 616.543 785.645 616.308 785.78ZM618.966 798.717C615.59 796.766 612.949 792.181 612.949 788.276C612.949 786.159 613.723 784.546 615.129 783.732C616.535 782.922 618.314 783.06 620.146 784.112C623.521 786.062 626.165 790.65 626.165 794.555C626.165 796.672 625.392 798.285 623.986 799.096C623.378 799.447 622.704 799.624 621.985 799.624C621.032 799.624 620.008 799.32 618.966 798.717Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M633.127 814.531C633.392 814.506 639.666 813.884 642.851 808.562L640.829 807.348C638.268 811.62 632.967 812.171 632.914 812.176L633.127 814.531Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M648.984 816.357C649.208 816.272 654.468 814.13 655.871 808.712L653.583 808.122C652.481 812.387 648.163 814.144 648.119 814.16L648.984 816.357Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M609.079 769.836C609.159 769.653 611.107 765.37 616.452 765.35L616.441 762.987C609.521 763.015 607.018 768.654 606.916 768.895L609.079 769.836Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M613.286 756.838C613.322 756.808 616.822 753.905 619.77 754.021L619.853 751.658C615.98 751.55 611.946 754.885 611.772 755.023L613.286 756.838Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M667.089 755.557L778.68 691.242L777.152 688.58L665.559 752.895L667.089 755.557Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M786.31 686.845L805.308 676.233L803.813 673.549L784.815 684.164L786.31 686.845Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M740.314 733.514L878.988 653.659L877.46 650.994L738.783 730.852L740.314 733.514Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M833.422 685.262L836.756 687.191C837.099 687.388 837.696 687.365 838.088 687.136L841.232 685.318C841.624 685.091 841.668 684.745 841.326 684.549L837.994 682.62C837.652 682.423 837.055 682.448 836.66 682.675L833.519 684.493C833.124 684.72 833.082 685.066 833.422 685.262Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M675.576 813.707L678.91 815.635C679.25 815.832 679.847 815.807 680.242 815.58L683.383 813.762C683.778 813.535 683.822 813.189 683.48 812.993L680.145 811.064C679.806 810.868 679.209 810.893 678.814 811.119L675.673 812.937C675.278 813.164 675.233 813.51 675.576 813.707Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M675.54 823.179L678.875 825.107C679.214 825.304 679.811 825.279 680.206 825.052L683.347 823.234C683.742 823.007 683.786 822.661 683.444 822.465L680.109 820.536C679.77 820.339 679.173 820.364 678.778 820.591L675.637 822.409C675.242 822.636 675.198 822.982 675.54 823.179Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M667.369 818.455L670.703 820.381C671.043 820.58 671.639 820.555 672.034 820.326L675.175 818.51C675.57 818.281 675.615 817.935 675.272 817.738L671.94 815.81C671.598 815.613 671.001 815.638 670.606 815.865L667.465 817.683C667.07 817.913 667.026 818.256 667.369 818.455Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M667.786 827.664L671.117 829.593C671.46 829.789 672.057 829.764 672.452 829.537L675.593 827.719C675.988 827.493 676.029 827.147 675.687 826.95L672.355 825.021C672.012 824.825 671.416 824.85 671.021 825.077L667.88 826.895C667.485 827.122 667.443 827.468 667.786 827.664Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M659.614 822.941L662.946 824.867C663.288 825.066 663.885 825.041 664.28 824.811L667.421 822.996C667.816 822.766 667.858 822.421 667.518 822.224L664.183 820.295C663.841 820.099 663.244 820.124 662.849 820.351L659.708 822.169C659.313 822.396 659.272 822.742 659.614 822.941Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M660.029 832.15L663.363 834.079C663.703 834.275 664.299 834.25 664.694 834.023L667.835 832.205C668.231 831.978 668.275 831.632 667.932 831.436L664.598 829.507C664.258 829.311 663.661 829.336 663.266 829.563L660.125 831.381C659.73 831.607 659.686 831.953 660.029 832.15Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M651.857 827.423L655.191 829.352C655.531 829.549 656.128 829.526 656.523 829.297L659.664 827.479C660.059 827.252 660.103 826.906 659.76 826.71L656.426 824.781C656.086 824.584 655.49 824.609 655.095 824.836L651.954 826.654C651.559 826.881 651.514 827.227 651.857 827.423Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M643.685 822.7L647.02 824.626C647.359 824.825 647.956 824.8 648.351 824.571L651.492 822.755C651.887 822.526 651.931 822.182 651.589 821.983L648.257 820.055C647.915 819.858 647.318 819.883 646.923 820.113L643.782 821.928C643.387 822.158 643.345 822.501 643.685 822.7Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M610.999 803.797L614.333 805.723C614.676 805.923 615.273 805.898 615.668 805.668L618.809 803.853C619.204 803.623 619.245 803.277 618.902 803.081L615.571 801.152C615.228 800.955 614.632 800.98 614.237 801.207L611.096 803.025C610.701 803.255 610.659 803.598 610.999 803.797Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M602.83 799.071L606.162 800.997C606.504 801.196 607.101 801.171 607.496 800.942L610.637 799.126C611.032 798.897 611.074 798.553 610.731 798.354L607.399 796.428C607.057 796.229 606.46 796.254 606.065 796.484L602.924 798.299C602.529 798.529 602.488 798.874 602.83 799.071Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M643.649 832.172L646.984 834.098C647.324 834.297 647.92 834.272 648.315 834.042L651.456 832.227C651.851 831.997 651.895 831.654 651.553 831.455L648.221 829.529C647.879 829.33 647.282 829.355 646.887 829.585L643.746 831.4C643.351 831.63 643.307 831.973 643.649 832.172Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M635.478 827.446L638.812 829.374C639.152 829.571 639.749 829.546 640.144 829.319L643.285 827.501C643.68 827.274 643.724 826.928 643.382 826.729L640.05 824.803C639.707 824.604 639.111 824.629 638.716 824.858L635.575 826.674C635.179 826.903 635.138 827.249 635.478 827.446Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M627.306 822.719L630.641 824.648C630.98 824.844 631.58 824.82 631.972 824.593L635.116 822.775C635.508 822.548 635.552 822.202 635.21 822.005L631.878 820.077C631.536 819.88 630.939 819.905 630.544 820.132L627.403 821.95C627.008 822.177 626.967 822.523 627.306 822.719Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M619.135 817.993L622.469 819.922C622.811 820.118 623.408 820.096 623.8 819.866L626.944 818.048C627.339 817.821 627.381 817.475 627.038 817.279L623.707 815.35C623.364 815.154 622.767 815.179 622.372 815.406L619.231 817.224C618.836 817.451 618.795 817.796 619.135 817.993Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M610.963 813.269L614.297 815.195C614.64 815.395 615.237 815.37 615.629 815.14L618.773 813.325C619.168 813.095 619.209 812.752 618.867 812.553L615.535 810.624C615.192 810.428 614.596 810.453 614.201 810.679L611.06 812.497C610.665 812.727 610.623 813.07 610.963 813.269Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M602.794 808.543L606.126 810.469C606.468 810.668 607.065 810.643 607.46 810.416L610.601 808.598C610.996 808.371 611.038 808.025 610.695 807.826L607.363 805.9C607.021 805.701 606.424 805.726 606.029 805.956L602.888 807.771C602.493 808.001 602.452 808.346 602.794 808.543Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M594.623 803.817L597.954 805.746C598.297 805.942 598.893 805.917 599.288 805.69L602.429 803.872C602.825 803.645 602.866 803.299 602.523 803.103L599.192 801.174C598.849 800.977 598.253 801.002 597.857 801.229L594.716 803.047C594.321 803.274 594.28 803.62 594.623 803.817Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M627.723 831.931L631.055 833.86C631.398 834.056 631.994 834.031 632.389 833.804L635.53 831.986C635.925 831.757 635.967 831.414 635.627 831.214L632.293 829.289C631.95 829.089 631.353 829.114 630.961 829.344L627.817 831.159C627.425 831.389 627.381 831.732 627.723 831.931Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M619.552 827.205L622.883 829.134C623.226 829.33 623.823 829.305 624.218 829.078L627.359 827.26C627.754 827.033 627.795 826.687 627.455 826.491L624.121 824.562C623.781 824.366 623.182 824.388 622.789 824.617L619.646 826.436C619.253 826.662 619.209 827.008 619.552 827.205Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M611.38 822.479L614.712 824.407C615.054 824.604 615.651 824.579 616.046 824.352L619.187 822.534C619.582 822.307 619.624 821.961 619.284 821.765L615.949 819.836C615.61 819.639 615.013 819.664 614.618 819.891L611.477 821.709C611.082 821.936 611.038 822.282 611.38 822.479Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M603.209 817.755L606.54 819.681C606.883 819.88 607.479 819.855 607.874 819.626L611.015 817.81C611.41 817.581 611.455 817.235 611.112 817.038L607.778 815.109C607.438 814.913 606.841 814.938 606.446 815.165L603.305 816.983C602.91 817.21 602.866 817.556 603.209 817.755Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M595.037 813.029L598.371 814.955C598.711 815.154 599.308 815.129 599.703 814.899L602.844 813.084C603.239 812.854 603.283 812.511 602.941 812.312L599.606 810.386C599.266 810.187 598.67 810.212 598.275 810.441L595.134 812.257C594.739 812.486 594.694 812.829 595.037 813.029Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M317.929 736.151H320.288V733.076H317.929V736.151Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M321.653 734.466L188.51 811.551C185.363 813.369 179.874 813.139 176.244 811.042L112.128 773.961C108.498 771.864 108.106 768.687 111.25 766.869L244.393 689.784C247.537 687.966 253.029 688.193 256.659 690.293L320.772 727.371C324.402 729.471 324.797 732.648 321.653 734.466Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M249.705 690.091C247.982 690.091 246.349 690.426 245.161 691.115L112.015 768.198C111.509 768.491 110.658 769.111 110.658 769.949C110.658 770.821 111.495 771.825 112.896 772.633L177.012 809.711C180.156 811.529 185.07 811.761 187.742 810.22L320.885 733.135C321.393 732.841 322.241 732.224 322.241 731.383C322.241 730.514 321.407 729.51 320.007 728.699L255.893 691.621C254.145 690.614 251.855 690.091 249.705 690.091ZM183.167 814.315C180.495 814.315 177.7 813.66 175.476 812.37L111.36 775.293C108.968 773.909 107.592 771.961 107.592 769.949C107.592 768.181 108.62 766.618 110.482 765.538L243.628 688.453C247.255 686.356 253.313 686.585 257.427 688.962L321.54 726.04C323.935 727.426 325.308 729.374 325.308 731.383C325.308 733.151 324.283 734.717 322.421 735.794L189.275 812.882C187.612 813.842 185.435 814.315 183.167 814.315Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M321.653 731.876L188.51 808.964C185.363 810.782 179.874 810.552 176.244 808.454L112.128 771.374C108.498 769.277 108.106 766.1 111.25 764.282L244.393 687.197C247.537 685.379 253.029 685.606 256.659 687.706L320.772 724.783C324.402 726.884 324.797 730.058 321.653 731.876Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M249.705 687.504C247.982 687.504 246.349 687.839 245.161 688.528L112.015 765.61C111.509 765.904 110.658 766.524 110.658 767.362C110.658 768.234 111.495 769.235 112.896 770.043L177.012 807.124C180.156 808.942 185.073 809.171 187.742 807.633L320.885 730.547C321.393 730.254 322.241 729.637 322.241 728.796C322.241 727.927 321.407 726.922 320.007 726.112L255.893 689.034C254.145 688.027 251.855 687.504 249.705 687.504ZM183.167 811.725C180.498 811.725 177.702 811.072 175.479 809.783L111.36 772.703C108.968 771.322 107.592 769.374 107.592 767.362C107.592 765.597 108.62 764.03 110.482 762.951L243.628 685.866C247.255 683.766 253.313 683.995 257.427 686.375L321.54 723.452C323.935 724.839 325.308 726.787 325.308 728.796C325.308 730.564 324.283 732.13 322.421 733.207L189.275 810.292C187.612 811.255 185.435 811.725 183.167 811.725Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M183.65 794.475C182.739 795.003 182.739 795.861 183.65 796.387C184.562 796.915 186.045 796.915 186.96 796.387C187.871 795.861 187.871 795.003 186.96 794.475C186.045 793.946 184.562 793.946 183.65 794.475ZM187.957 796.965C186.496 797.812 184.114 797.812 182.65 796.965C181.189 796.121 181.189 794.743 182.65 793.896C184.114 793.05 186.496 793.05 187.96 793.896C189.421 794.743 189.421 796.121 187.957 796.965Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M183.617 784.972C182.705 785.498 182.705 786.356 183.617 786.884C184.529 787.41 186.012 787.41 186.924 786.884C187.838 786.356 187.838 785.498 186.924 784.972C186.012 784.444 184.529 784.444 183.617 784.972ZM187.924 787.463C186.463 788.309 184.081 788.309 182.617 787.463C181.153 786.616 181.153 785.238 182.617 784.394C184.081 783.547 186.463 783.547 187.924 784.394C189.388 785.238 189.388 786.616 187.924 787.463Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M208.375 780.177C207.463 780.705 207.463 781.563 208.375 782.089C209.287 782.617 210.77 782.617 211.682 782.089C212.593 781.563 212.593 780.702 211.682 780.177C210.77 779.648 209.287 779.648 208.375 780.177ZM212.682 782.667C211.218 783.514 208.839 783.514 207.375 782.667C205.911 781.823 205.911 780.445 207.375 779.598C208.839 778.751 211.22 778.751 212.682 779.598C214.146 780.445 214.146 781.82 212.682 782.667Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M198.549 785.861L201.803 787.773L205.11 785.891C205.123 785.883 205.126 785.869 205.11 785.861L201.855 783.976L198.549 785.861ZM206.11 786.469L202.855 788.351C202.289 788.677 201.369 788.677 200.803 788.351L197.549 786.469C196.982 786.143 196.982 785.609 197.549 785.282L200.803 783.401C201.369 783.071 202.289 783.071 202.855 783.401L206.11 785.282C206.676 785.609 206.676 786.14 206.11 786.469Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M206.748 781.118L210.002 783.03L213.309 781.148C213.325 781.14 213.325 781.126 213.312 781.118L210.055 779.236L206.748 781.118ZM214.309 781.726L211.055 783.608C210.488 783.937 209.568 783.937 209.002 783.608L205.748 781.726C205.181 781.4 205.181 780.866 205.748 780.539L209.002 778.657C209.568 778.331 210.488 778.331 211.055 778.657L214.309 780.539C214.875 780.866 214.875 781.4 214.309 781.726Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M225.163 760.942C224.251 761.471 224.251 762.328 225.163 762.857C226.075 763.385 227.558 763.385 228.472 762.857C229.384 762.328 229.384 761.471 228.472 760.945C227.558 760.416 226.075 760.416 225.163 760.942ZM229.473 763.435C228.008 764.282 225.627 764.282 224.163 763.435C222.702 762.589 222.702 761.21 224.163 760.366C225.627 759.52 228.008 759.52 229.473 760.366C230.934 761.21 230.934 762.589 229.473 763.435Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M223.536 761.886L226.79 763.798L230.1 761.916C230.113 761.908 230.113 761.894 230.1 761.886L226.845 760.001L223.536 761.886ZM231.1 762.494L227.845 764.376C227.279 764.703 226.356 764.703 225.79 764.376L222.536 762.494C221.969 762.165 221.969 761.634 222.536 761.307L225.79 759.423C226.356 759.096 227.279 759.096 227.845 759.426L231.1 761.307C231.666 761.634 231.666 762.165 231.1 762.494Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M173.791 790.653L177.045 792.568L180.352 790.686C180.368 790.675 180.368 790.661 180.352 790.653L177.098 788.771L173.791 790.653ZM181.352 791.262L178.098 793.146C177.531 793.473 176.611 793.473 176.045 793.146L172.791 791.262C172.224 790.935 172.224 790.404 172.791 790.075L176.045 788.193C176.611 787.867 177.531 787.867 178.098 788.193L181.352 790.075C181.918 790.404 181.918 790.935 181.352 791.262Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M200.14 775.414C199.228 775.943 199.228 776.801 200.14 777.329C201.052 777.855 202.538 777.855 203.449 777.327C204.361 776.801 204.361 775.943 203.449 775.414C202.538 774.886 201.052 774.886 200.14 775.414ZM204.449 777.905C202.985 778.752 200.604 778.752 199.14 777.905C197.678 777.061 197.678 775.683 199.14 774.836C200.604 773.989 202.985 773.989 204.449 774.836C205.911 775.683 205.911 777.061 204.449 777.905Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M196.25 782.069L195.25 782.648L190.941 780.157L191.941 779.579L196.25 782.069Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M196.25 780.157L191.941 782.648L190.941 782.069L195.25 779.579L196.25 780.157Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M196.159 791.647L195.159 792.225L190.849 789.732L191.849 789.156L196.159 791.647Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M196.159 789.732L191.849 792.225L190.849 791.647L195.159 789.153L196.159 789.732Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M215.259 776.195L218.486 778.107L221.795 776.242C221.818 776.228 221.818 776.209 221.795 776.195L218.569 774.33L215.259 776.195ZM222.793 776.82L219.569 778.685C218.994 779.017 218.06 779.017 217.486 778.685L214.259 776.82C213.687 776.488 213.687 775.948 214.259 775.616L217.486 773.751C218.06 773.419 218.994 773.419 219.569 773.751L222.795 775.616C223.367 775.948 223.367 776.488 222.793 776.82Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M216.839 765.757C215.928 766.285 215.928 767.143 216.839 767.672C217.751 768.198 219.235 768.198 220.146 767.672C221.061 767.143 221.061 766.285 220.146 765.757C219.235 765.231 217.751 765.231 216.839 765.757ZM221.146 768.25C219.685 769.094 217.304 769.094 215.839 768.25C214.375 767.403 214.375 766.025 215.839 765.179C217.304 764.335 219.685 764.335 221.146 765.179C222.61 766.025 222.61 767.403 221.146 768.25Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M231.771 766.645L235.025 768.56L238.332 766.676C238.346 766.667 238.346 766.653 238.332 766.645L235.078 764.763L231.771 766.645ZM239.332 767.254L236.078 769.138C235.511 769.465 234.592 769.465 234.025 769.138L230.771 767.257C230.205 766.927 230.205 766.396 230.771 766.07L234.025 764.185C234.592 763.859 235.511 763.859 236.078 764.185L239.332 766.07C239.898 766.396 239.898 766.927 239.332 767.254Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M233.362 756.202C232.451 756.728 232.451 757.588 233.365 758.114C234.277 758.642 235.76 758.642 236.672 758.114C237.583 757.588 237.583 756.73 236.672 756.202C235.76 755.673 234.274 755.673 233.362 756.202ZM237.672 758.692C236.208 759.539 233.826 759.539 232.365 758.692C230.901 757.846 230.901 756.47 232.362 755.624C233.826 754.777 236.208 754.777 237.672 755.624C239.133 756.47 239.133 757.846 237.672 758.692Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M212.947 772.412L211.947 772.99L207.64 770.5L208.64 769.921L212.947 772.412Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M212.947 770.5L208.64 772.99L207.64 772.412L211.947 769.921L212.947 770.5Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M229.381 772.431L228.381 773.01L224.075 770.519L225.075 769.941L229.381 772.431Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M229.381 770.519L225.072 773.01L224.072 772.434L228.381 769.941L229.381 770.519Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M245.904 762.876L244.904 763.455L240.597 760.964L241.597 760.386L245.904 762.876Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M245.904 760.964L241.597 763.455L240.597 762.876L244.904 760.386L245.904 760.964Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M249.589 756.34C248.678 756.869 248.678 757.727 249.589 758.255C250.501 758.781 251.987 758.781 252.899 758.255C253.811 757.727 253.811 756.869 252.899 756.34C251.987 755.815 250.504 755.815 249.589 756.34ZM253.899 758.831C252.435 759.677 250.053 759.677 248.592 758.831C247.128 757.987 247.128 756.609 248.592 755.762C250.053 754.915 252.435 754.915 253.899 755.762C255.36 756.609 255.36 757.987 253.899 758.831Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M249.556 746.838C248.645 747.364 248.645 748.224 249.556 748.75C250.468 749.278 251.951 749.278 252.866 748.75C253.777 748.224 253.777 747.364 252.866 746.838C251.951 746.309 250.468 746.309 249.556 746.838ZM253.863 749.328C252.402 750.175 250.02 750.175 248.556 749.328C247.092 748.482 247.092 747.106 248.556 746.259C250.02 745.413 252.402 745.413 253.863 746.259C255.327 747.106 255.327 748.482 253.863 749.328Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M264.488 747.726L267.742 749.638L271.049 747.756C271.065 747.748 271.065 747.734 271.049 747.726L267.795 745.844L264.488 747.726ZM272.049 748.335L268.795 750.216C268.228 750.543 267.308 750.543 266.742 750.216L263.488 748.335C262.921 748.008 262.921 747.474 263.488 747.148L266.742 745.266C267.308 744.937 268.228 744.937 268.795 745.266L272.049 747.148C272.615 747.474 272.615 748.008 272.049 748.335Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M239.73 752.519L242.984 754.434L246.291 752.552C246.307 752.544 246.307 752.53 246.291 752.519L243.037 750.637L239.73 752.519ZM247.291 753.13L244.037 755.012C243.47 755.339 242.55 755.339 241.984 755.012L238.73 753.13C238.163 752.801 238.163 752.27 238.73 751.94L241.984 750.059C242.55 749.732 243.47 749.732 244.037 750.059L247.291 751.94C247.857 752.27 247.857 752.801 247.291 753.13Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M262.189 743.935L261.189 744.513L256.88 742.023L257.88 741.445L262.189 743.935Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M262.189 742.023L257.88 744.513L256.88 743.935L261.189 741.445L262.189 742.023Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M262.098 753.512L261.098 754.091L256.791 751.6L257.791 751.022L262.098 753.512Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M262.098 751.597L257.789 754.091L256.791 753.512L261.098 751.022L262.098 751.597Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M167.622 785.205C166.71 785.733 166.71 786.591 167.622 787.117C168.534 787.645 170.017 787.645 170.929 787.117C171.84 786.591 171.84 785.733 170.929 785.205C170.017 784.676 168.534 784.676 167.622 785.205ZM171.929 787.695C170.465 788.542 168.086 788.542 166.622 787.695C165.158 786.851 165.158 785.473 166.622 784.626C168.086 783.779 170.465 783.779 171.929 784.626C173.393 785.473 173.393 786.851 171.929 787.695Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M167.586 775.702C166.674 776.228 166.674 777.086 167.589 777.614C168.5 778.14 169.984 778.14 170.896 777.614C171.807 777.086 171.807 776.228 170.896 775.702C169.984 775.174 168.5 775.174 167.586 775.702ZM171.896 778.193C170.432 779.039 168.05 779.039 166.589 778.193C165.125 777.346 165.125 775.968 166.586 775.124C168.05 774.277 170.432 774.277 171.896 775.124C173.357 775.968 173.357 777.346 171.896 778.193Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M192.347 770.907C191.432 771.435 191.432 772.293 192.344 772.819C193.258 773.347 194.742 773.347 195.654 772.819C196.565 772.293 196.565 771.432 195.654 770.907C194.742 770.378 193.258 770.378 192.347 770.907ZM196.654 773.397C195.189 774.244 192.808 774.244 191.344 773.397C189.883 772.55 189.883 771.175 191.347 770.328C192.808 769.482 195.189 769.482 196.654 770.328C198.115 771.175 198.115 772.55 196.654 773.397Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M182.518 776.59L185.772 778.503L189.081 776.621C189.095 776.613 189.095 776.599 189.081 776.59L185.827 774.706L182.518 776.59ZM190.081 777.199L186.827 779.081C186.261 779.407 185.338 779.407 184.775 779.081L181.518 777.199C180.951 776.87 180.951 776.339 181.518 776.012L184.775 774.13C185.341 773.801 186.261 773.801 186.827 774.13L190.081 776.012C190.648 776.339 190.648 776.87 190.081 777.199Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M190.717 771.847L193.974 773.76L197.281 771.878C197.294 771.87 197.294 771.856 197.281 771.847L194.026 769.966L190.717 771.847ZM198.281 772.456L195.026 774.338C194.46 774.667 193.537 774.667 192.974 774.338L189.717 772.456C189.153 772.13 189.153 771.596 189.72 771.269L192.974 769.387C193.54 769.061 194.46 769.061 195.026 769.387L198.281 771.269C198.847 771.596 198.847 772.13 198.281 772.456Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.135 751.675C208.223 752.201 208.223 753.058 209.135 753.587C210.046 754.115 211.53 754.115 212.441 753.587C213.353 753.058 213.353 752.201 212.441 751.675C211.53 751.146 210.046 751.146 209.135 751.675ZM213.441 754.165C211.98 755.012 209.599 755.012 208.135 754.165C206.671 753.318 206.671 751.94 208.135 751.096C209.599 750.25 211.98 750.25 213.441 751.096C214.906 751.94 214.906 753.318 213.441 754.165Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M207.508 752.616L210.762 754.528L214.069 752.646C214.085 752.638 214.085 752.624 214.069 752.616L210.814 750.731L207.508 752.616ZM215.069 753.225L211.814 755.106C211.248 755.433 210.328 755.433 209.762 755.106L206.508 753.225C205.941 752.895 205.941 752.364 206.508 752.037L209.762 750.153C210.328 749.826 211.248 749.826 211.814 750.153L215.069 752.037C215.635 752.364 215.635 752.895 215.069 753.225Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M157.76 781.383L161.014 783.298L164.324 781.414C164.337 781.405 164.337 781.394 164.324 781.383L161.069 779.501L157.76 781.383ZM165.324 781.992L162.067 783.876C161.503 784.203 160.58 784.203 160.014 783.876L156.76 781.992C156.193 781.665 156.193 781.134 156.76 780.805L160.014 778.923C160.58 778.597 161.5 778.597 162.067 778.923L165.324 780.805C165.89 781.134 165.89 781.665 165.324 781.992Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M184.112 766.144C183.2 766.673 183.2 767.531 184.112 768.056C185.023 768.585 186.507 768.585 187.418 768.056C188.333 767.531 188.333 766.673 187.418 766.144C186.507 765.616 185.023 765.616 184.112 766.144ZM188.418 768.635C186.957 769.481 184.576 769.481 183.112 768.635C181.647 767.791 181.647 766.413 183.112 765.566C184.576 764.719 186.957 764.719 188.418 765.566C189.883 766.413 189.883 767.791 188.418 768.635Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M180.219 772.799L179.219 773.378L174.912 770.887L175.912 770.309L180.219 772.799Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M180.219 770.887L175.912 773.378L174.912 772.799L179.219 770.309L180.219 770.887Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M180.128 782.377L179.128 782.955L174.821 780.462L175.821 779.883L180.128 782.377Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M180.128 780.462L175.821 782.955L174.821 782.377L179.128 779.883L180.128 780.462Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M199.231 766.925L202.458 768.837L205.764 766.972C205.787 766.958 205.789 766.938 205.764 766.925L202.538 765.06L199.231 766.925ZM206.764 767.55L203.538 769.415C202.963 769.747 202.032 769.747 201.458 769.415L198.231 767.55C197.656 767.218 197.656 766.678 198.231 766.346L201.458 764.481C202.032 764.149 202.966 764.149 203.538 764.481L206.764 766.346C207.339 766.678 207.339 767.218 206.764 767.55Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M200.811 756.487C199.897 757.015 199.897 757.873 200.811 758.402C201.723 758.928 203.206 758.928 204.118 758.402C205.03 757.873 205.03 757.015 204.118 756.487C203.206 755.961 201.723 755.961 200.811 756.487ZM205.118 758.977C203.654 759.824 201.273 759.824 199.811 758.98C198.347 758.133 198.347 756.755 199.811 755.909C201.273 755.062 203.654 755.062 205.118 755.909C206.579 756.755 206.579 758.133 205.118 758.977Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M215.74 757.375L218.994 759.29L222.304 757.406C222.318 757.4 222.318 757.383 222.304 757.375L219.049 755.493L215.74 757.375ZM223.304 757.984L220.049 759.868C219.483 760.195 218.56 760.195 217.997 759.868L214.74 757.984C214.174 757.657 214.174 757.126 214.74 756.8L217.997 754.915C218.563 754.589 219.483 754.589 220.049 754.915L223.304 756.8C223.87 757.126 223.87 757.657 223.304 757.984Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M217.334 746.932C216.422 747.458 216.422 748.318 217.334 748.844C218.246 749.373 219.729 749.373 220.641 748.844C221.555 748.318 221.555 747.46 220.641 746.932C219.729 746.403 218.246 746.403 217.334 746.932ZM221.641 749.422C220.179 750.269 217.798 750.269 216.334 749.422C214.873 748.576 214.873 747.2 216.334 746.354C217.798 745.507 220.179 745.507 221.641 746.354C223.105 747.2 223.105 748.576 221.641 749.422Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M196.919 763.142L195.919 763.72L191.609 761.23L192.609 760.652L196.919 763.142Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M196.919 761.23L192.609 763.72L191.609 763.142L195.919 760.652L196.919 761.23Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M213.35 763.161L212.35 763.74L208.043 761.249L209.044 760.671L213.35 763.161Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M213.35 761.249L209.044 763.74L208.043 763.164L212.35 760.671L213.35 761.249Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M229.876 753.606L228.876 754.185L224.569 751.694L225.569 751.116L229.876 753.606Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M229.876 751.694L225.569 754.185L224.569 753.606L228.876 751.116L229.876 751.694Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M233.561 747.07C232.649 747.599 232.649 748.457 233.561 748.985C234.473 749.511 235.956 749.511 236.868 748.985C237.782 748.457 237.782 747.599 236.868 747.07C235.956 746.544 234.473 746.544 233.561 747.07ZM237.868 749.561C236.407 750.408 234.025 750.408 232.561 749.561C231.097 748.717 231.097 747.339 232.561 746.492C234.025 745.645 236.407 745.645 237.868 746.492C239.332 747.339 239.332 748.717 237.868 749.561Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M233.528 737.568C232.614 738.094 232.614 738.951 233.528 739.48C234.44 740.008 235.923 740.008 236.835 739.48C237.746 738.951 237.746 738.094 236.835 737.568C235.923 737.039 234.44 737.039 233.528 737.568ZM237.835 740.058C236.371 740.905 233.989 740.905 232.528 740.058C231.064 739.211 231.064 737.836 232.528 736.989C233.989 736.143 236.371 736.143 237.835 736.989C239.299 737.836 239.299 739.211 237.835 740.058Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M248.457 738.456L251.714 740.368L255.021 738.486C255.034 738.478 255.034 738.464 255.021 738.456L251.766 736.574L248.457 738.456ZM256.021 739.065L252.766 740.946C252.2 741.273 251.277 741.273 250.714 740.946L247.457 739.065C246.893 738.738 246.893 738.204 247.459 737.878L250.714 735.996C251.28 735.667 252.2 735.667 252.766 735.996L256.021 737.878C256.587 738.204 256.587 738.738 256.021 739.065Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M223.699 743.249L226.953 745.164L230.263 743.282C230.276 743.271 230.276 743.257 230.263 743.249L227.008 741.367L223.699 743.249ZM231.263 743.86L228.008 745.742C227.442 746.069 226.519 746.069 225.956 745.742L222.699 743.86C222.135 743.531 222.135 743 222.699 742.67L225.956 740.789C226.519 740.462 227.442 740.462 228.008 740.789L231.263 742.67C231.829 743 231.829 743.531 231.263 743.86Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M246.158 734.665L245.158 735.243L240.851 732.753L241.851 732.174L246.158 734.665Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M246.158 732.753L241.851 735.243L240.851 734.665L245.158 732.174L246.158 732.753Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M246.067 744.242L245.07 744.82L240.76 742.327L241.76 741.749L246.067 744.242Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M246.067 742.327L241.76 744.82L240.76 744.242L245.07 741.749L246.067 742.327Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M151.555 775.912C150.643 776.441 150.643 777.299 151.555 777.827C152.467 778.353 153.953 778.353 154.865 777.827C155.776 777.299 155.776 776.441 154.865 775.912C153.953 775.387 152.467 775.387 151.555 775.912ZM155.865 778.406C154.4 779.25 152.019 779.25 150.555 778.406C149.094 777.559 149.094 776.181 150.555 775.337C152.019 774.49 154.4 774.49 155.865 775.337C157.326 776.181 157.326 777.559 155.865 778.406Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M151.522 766.41C150.61 766.939 150.61 767.796 151.522 768.322C152.434 768.851 153.917 768.851 154.829 768.322C155.743 767.796 155.743 766.939 154.831 766.41C153.917 765.882 152.434 765.882 151.522 766.41ZM155.829 768.9C154.367 769.747 151.986 769.747 150.522 768.9C149.058 768.054 149.058 766.678 150.522 765.832C151.986 764.985 154.367 764.985 155.829 765.832C157.293 766.678 157.293 768.054 155.829 768.9Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M176.28 761.614C175.368 762.143 175.368 763.001 176.28 763.529C177.191 764.055 178.675 764.055 179.587 763.529C180.501 763.001 180.501 762.143 179.587 761.614C178.675 761.089 177.191 761.089 176.28 761.614ZM180.587 764.105C179.125 764.952 176.744 764.952 175.28 764.108C173.816 763.261 173.816 761.883 175.28 761.036C176.744 760.192 179.125 760.192 180.587 761.036C182.051 761.883 182.051 763.261 180.587 764.105Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M166.453 767.298L169.708 769.21L173.014 767.329C173.031 767.32 173.031 767.307 173.017 767.298L169.76 765.417L166.453 767.298ZM174.015 767.907L170.76 769.789C170.194 770.118 169.274 770.118 168.708 769.789L165.453 767.907C164.887 767.581 164.887 767.049 165.453 766.72L168.708 764.838C169.274 764.512 170.194 764.512 170.76 764.838L174.015 766.72C174.581 767.046 174.581 767.581 174.015 767.907Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M174.653 762.555L177.907 764.47L181.214 762.588C181.23 762.58 181.23 762.564 181.216 762.555L177.959 760.674L174.653 762.555ZM182.214 763.164L178.959 765.048C178.393 765.375 177.473 765.375 176.907 765.048L173.653 763.164C173.086 762.838 173.086 762.306 173.653 761.98L176.907 760.095C177.473 759.769 178.393 759.769 178.959 760.095L182.214 761.98C182.78 762.306 182.78 762.838 182.214 763.164Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M193.068 742.383C192.156 742.911 192.156 743.769 193.068 744.295C193.979 744.823 195.466 744.823 196.377 744.295C197.289 743.769 197.289 742.911 196.377 742.383C195.466 741.854 193.979 741.854 193.068 742.383ZM197.377 744.873C195.913 745.72 193.532 745.72 192.068 744.873C190.606 744.026 190.606 742.651 192.068 741.804C193.532 740.958 195.913 740.958 197.377 741.804C198.839 742.651 198.839 744.026 197.377 744.873Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M191.441 743.323L194.695 745.236L198.004 743.354C198.018 743.346 198.018 743.332 198.004 743.323L194.75 741.442L191.441 743.323ZM199.004 743.932L195.75 745.814C195.184 746.143 194.261 746.143 193.695 745.814L190.441 743.932C189.874 743.606 189.874 743.072 190.441 742.745L193.698 740.863C194.261 740.534 195.184 740.534 195.75 740.863L199.004 742.745C199.571 743.072 199.571 743.606 199.004 743.932Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M141.696 772.094L144.95 774.006L148.257 772.124C148.273 772.116 148.273 772.102 148.257 772.094L145.002 770.212L141.696 772.094ZM149.257 772.703L146.002 774.584C145.436 774.911 144.516 774.911 143.95 774.584L140.695 772.703C140.129 772.373 140.129 771.842 140.695 771.515L143.95 769.634C144.516 769.305 145.436 769.305 146.002 769.634L149.257 771.515C149.823 771.842 149.823 772.376 149.257 772.703Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M168.045 756.855C167.133 757.381 167.133 758.239 168.045 758.767C168.959 759.296 170.443 759.296 171.354 758.767C172.266 758.239 172.266 757.381 171.354 756.855C170.443 756.326 168.959 756.326 168.045 756.855ZM172.354 759.345C170.89 760.192 168.509 760.192 167.047 759.345C165.583 758.499 165.583 757.121 167.047 756.274C168.509 755.43 170.89 755.43 172.354 756.277C173.816 757.121 173.816 758.499 172.354 759.345Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M164.155 763.51L163.155 764.088L158.845 761.595L159.845 761.017L164.155 763.51Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M164.155 761.595L159.845 764.088L158.845 763.51L163.155 761.017L164.155 761.595Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M164.064 773.085L163.064 773.663L158.757 771.172L159.757 770.594L164.064 773.085Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M164.064 771.172L159.757 773.663L158.757 773.085L163.064 770.594L164.064 771.172Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M183.164 757.632L186.391 759.547L189.7 757.68C189.722 757.668 189.722 757.646 189.7 757.632L186.474 755.767L183.164 757.632ZM190.7 758.258L187.474 760.126C186.899 760.455 185.965 760.455 185.391 760.126L182.164 758.258C181.592 757.926 181.592 757.386 182.167 757.054L185.391 755.189C185.965 754.857 186.899 754.857 187.474 755.189L190.7 757.054C191.272 757.386 191.272 757.926 190.7 758.258Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M184.744 747.197C183.833 747.723 183.833 748.581 184.744 749.11C185.656 749.638 187.139 749.638 188.054 749.11C188.965 748.581 188.965 747.723 188.054 747.197C187.139 746.669 185.656 746.669 184.744 747.197ZM189.051 749.688C187.59 750.535 185.208 750.535 183.744 749.688C182.28 748.841 182.28 747.466 183.744 746.619C185.208 745.772 187.59 745.772 189.051 746.619C190.515 747.463 190.515 748.841 189.051 749.688Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M199.676 748.086L202.93 749.998L206.237 748.116C206.253 748.108 206.253 748.094 206.237 748.086L202.983 746.204L199.676 748.086ZM207.237 748.694L203.983 750.576C203.416 750.903 202.496 750.903 201.93 750.576L198.676 748.694C198.109 748.368 198.109 747.834 198.676 747.507L201.93 745.626C202.496 745.296 203.416 745.296 203.983 745.626L207.237 747.507C207.803 747.834 207.803 748.368 207.237 748.694Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M201.267 737.64C200.355 738.168 200.355 739.026 201.27 739.555C202.181 740.08 203.665 740.08 204.577 739.555C205.488 739.026 205.488 738.168 204.577 737.64C203.665 737.114 202.179 737.114 201.267 737.64ZM205.577 740.133C204.112 740.977 201.731 740.977 200.27 740.133C198.806 739.286 198.806 737.908 200.27 737.061C201.731 736.217 204.112 736.217 205.577 737.061C207.038 737.908 207.038 739.286 205.577 740.133Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M180.852 753.852L179.852 754.431L175.545 751.938L176.545 751.359L180.852 753.852Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M180.852 751.938L176.545 754.431L175.545 753.852L179.852 751.359L180.852 751.938Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M197.286 753.872L196.286 754.45L191.979 751.96L192.979 751.381L197.286 753.872Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M197.286 751.96L192.977 754.45L191.979 753.872L196.286 751.381L197.286 751.96Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M213.809 744.314L212.809 744.892L208.502 742.402L209.502 741.824L213.809 744.314Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M213.809 742.402L209.502 744.892L208.502 744.314L212.809 741.824L213.809 742.402Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M217.494 737.781C216.583 738.307 216.583 739.164 217.494 739.693C218.409 740.219 219.892 740.219 220.804 739.693C221.715 739.164 221.715 738.307 220.804 737.778C219.892 737.252 218.409 737.252 217.494 737.781ZM221.804 740.271C220.34 741.118 217.958 741.118 216.497 740.271C215.033 739.424 215.033 738.049 216.497 737.202C217.958 736.356 220.34 736.356 221.804 737.202C223.265 738.046 223.265 739.424 221.804 740.271Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M217.461 728.276C216.549 728.804 216.549 729.662 217.461 730.188C218.373 730.716 219.856 730.716 220.771 730.188C221.682 729.662 221.682 728.804 220.771 728.276C219.856 727.747 218.373 727.747 217.461 728.276ZM221.771 730.766C220.306 731.613 217.925 731.613 216.461 730.766C214.997 729.922 214.997 728.544 216.461 727.697C217.925 726.85 220.306 726.85 221.771 727.697C223.232 728.544 223.232 729.922 221.771 730.766Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M232.393 729.164L235.647 731.079L238.956 729.194C238.97 729.186 238.97 729.172 238.956 729.164L235.699 727.282L232.393 729.164ZM239.954 729.773L236.699 731.654C236.133 731.984 235.213 731.984 234.647 731.657L231.392 729.773C230.826 729.446 230.826 728.915 231.392 728.585L234.647 726.704C235.213 726.377 236.133 726.377 236.699 726.704L239.954 728.585C240.52 728.915 240.52 729.446 239.954 729.773Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M207.635 733.959L210.889 735.871L214.196 733.99C214.212 733.981 214.212 733.968 214.196 733.959L210.941 732.078L207.635 733.959ZM215.196 734.568L211.941 736.45C211.375 736.776 210.455 736.776 209.889 736.45L206.635 734.568C206.068 734.242 206.068 733.707 206.635 733.381L209.889 731.499C210.455 731.17 211.375 731.17 211.941 731.499L215.196 733.381C215.762 733.707 215.762 734.242 215.196 734.568Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M230.094 725.376L229.094 725.954L224.787 723.461L225.785 722.882L230.094 725.376Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M230.094 723.461L225.785 725.954L224.787 725.376L229.094 722.882L230.094 723.461Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M230.003 734.95L229.003 735.528L224.696 733.038L225.696 732.46L230.003 734.95Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M230.003 733.038L225.696 735.528L224.696 734.95L229.003 732.46L230.003 733.038Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M181.899 812.777H184.258V809.7H181.899V812.777Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111.686 774.543H114.045V771.466H111.686V774.543Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M318.523 735.6H320.882V732.523H318.523V735.6Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.917 625.896C209.969 625.818 209.955 625.713 209.872 625.666C208.671 624.971 200.455 620.234 200.455 620.234C195.178 617.287 187.186 619.097 178.796 627.074C172.329 633.223 167.777 641.212 165.76 649.912C163.053 661.6 165.923 669.49 171.63 672.188L180.689 677.456C180.774 677.506 180.885 677.47 180.921 677.376L183.217 671.626C184.045 671.263 184.882 670.857 185.736 670.361C197.339 663.651 206.742 647.336 206.742 633.918C206.742 632.778 206.651 631.715 206.519 630.694L209.917 625.896Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M195.071 620.417C190.579 620.417 185.195 623.106 179.852 628.187C173.575 634.156 169.219 641.79 167.255 650.261C164.915 660.355 166.843 668.225 172.285 670.799L180.087 675.331L182.026 670.475L182.601 670.223C183.377 669.88 184.167 669.495 184.971 669.03C196.129 662.577 205.209 646.824 205.209 633.918C205.209 632.974 205.143 632.008 204.999 630.888L204.922 630.293L207.792 626.239L199.69 621.565C198.308 620.793 196.75 620.417 195.071 620.417ZM180.769 679.017C180.479 679.017 180.192 678.94 179.932 678.796L170.86 673.516C164.152 670.353 161.583 661.149 164.268 649.563C166.368 640.506 171.025 632.343 177.741 625.959C186.109 618.004 194.877 615.358 201.201 618.892L211.226 624.673L211.588 626.098L211.165 626.784L208.113 631.098C208.223 632.105 208.276 633.016 208.276 633.918C208.276 647.803 198.507 664.747 186.504 671.69C185.849 672.069 185.161 672.426 184.413 672.772L182.087 678.596L181.332 678.917C181.15 678.984 180.96 679.017 180.769 679.017Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M216.917 639.762C216.917 653.18 207.513 669.495 195.913 676.203C184.31 682.913 174.907 677.476 174.907 664.058C174.907 650.64 184.31 634.322 195.913 627.614C207.513 620.904 216.917 626.344 216.917 639.762Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M205.287 626.269C202.681 626.269 199.75 627.168 196.678 628.942C185.521 635.398 176.44 651.149 176.44 664.055C176.44 669.974 178.354 674.341 181.827 676.347C185.299 678.356 190.026 677.835 195.145 674.875C206.306 668.419 215.384 652.668 215.384 639.762C215.384 633.846 213.472 629.479 209.997 627.47C208.61 626.668 207.021 626.269 205.287 626.269ZM186.529 680.625C184.264 680.625 182.161 680.088 180.294 679.009C175.832 676.43 173.374 671.117 173.374 664.055C173.374 650.172 183.142 633.226 195.145 626.283C201.25 622.749 207.071 622.232 211.533 624.811C215.994 627.39 218.45 632.7 218.45 639.762C218.45 653.648 208.684 670.591 196.681 677.534C193.129 679.587 189.675 680.625 186.529 680.625Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M263.496 682.313C263.515 682.288 266.568 682.094 266.543 682.08C266.159 681.859 201.314 643.935 201.314 643.935C199.632 642.994 197.084 643.572 194.413 646.113C192.35 648.072 190.902 650.618 190.258 653.39C189.396 657.115 190.311 659.628 192.129 660.488L257.316 698.556C257.344 698.573 255.847 698.872 255.858 698.841L256.59 697.009C256.855 696.893 257.12 696.763 257.394 696.605C261.09 694.469 264.087 689.269 264.087 684.994C264.087 684.629 264.057 684.291 264.015 683.965L263.496 682.313Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M273.013 662.763C273.068 662.685 273.052 662.58 272.969 662.533C271.767 661.841 263.554 657.101 263.554 657.101C258.278 654.154 250.283 655.964 241.893 663.941C235.426 670.093 230.876 678.079 228.859 686.782C226.149 698.468 229.019 706.357 234.727 709.058L243.785 714.326C243.871 714.376 243.981 714.337 244.017 714.246L246.313 708.496C247.142 708.131 247.979 707.724 248.835 707.228C260.435 700.521 269.839 684.206 269.839 670.787C269.839 669.645 269.748 668.585 269.615 667.561L273.013 662.763Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M258.167 657.284C253.675 657.284 248.294 659.976 242.951 665.057C236.674 671.023 232.315 678.655 230.351 687.13C228.011 697.222 229.942 705.095 235.382 707.668L243.183 712.201L245.122 707.342L245.697 707.09C246.473 706.747 247.263 706.365 248.064 705.9C259.225 699.447 268.306 683.696 268.306 670.785C268.306 669.838 268.237 668.878 268.096 667.757L268.018 667.163L270.889 663.106L262.789 658.432C261.405 657.66 259.849 657.284 258.167 657.284ZM243.868 715.884C243.564 715.884 243.269 715.801 243.003 715.646L233.956 710.386C227.249 707.217 224.68 698.016 227.365 686.433C229.467 677.376 234.125 669.213 240.84 662.829C249.203 654.873 257.974 652.223 264.3 655.762L274.32 661.542L274.684 662.959L274.262 663.651L271.206 667.968C271.32 668.975 271.375 669.883 271.375 670.785C271.375 684.673 261.606 701.617 249.6 708.559C248.951 708.936 248.274 709.287 247.506 709.641L245.18 715.472L244.412 715.793C244.233 715.854 244.05 715.884 243.868 715.884Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M280.016 676.629C280.016 690.047 270.61 706.362 259.01 713.073C247.41 719.78 238.003 714.343 238.003 700.925C238.003 687.507 247.41 671.191 259.01 664.481C270.61 657.773 280.016 663.211 280.016 676.629Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M268.386 663.136C265.781 663.136 262.85 664.038 259.778 665.812C248.617 672.268 239.539 688.016 239.539 700.925C239.539 706.844 241.454 711.208 244.923 713.217C248.396 715.228 253.128 714.7 258.242 711.742C269.402 705.289 278.48 689.538 278.48 676.629C278.48 670.713 276.566 666.349 273.096 664.34C271.706 663.537 270.121 663.136 268.386 663.136ZM249.625 717.492C247.363 717.492 245.255 716.958 243.39 715.876C238.929 713.297 236.47 707.987 236.47 700.925C236.47 687.042 246.238 670.096 258.242 663.153C264.35 659.622 270.173 659.102 274.629 661.678C279.091 664.26 281.549 669.567 281.549 676.629C281.549 690.515 271.781 707.458 259.778 714.401C256.225 716.457 252.772 717.492 249.625 717.492Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M268.435 683.312C268.452 683.287 268.449 683.254 268.422 683.24C268.038 683.018 263.54 680.293 263.54 680.293C261.858 679.355 259.311 679.93 256.639 682.473C254.579 684.432 253.128 686.978 252.485 689.751C251.623 693.473 252.537 695.988 254.355 696.846L259.123 699.74C259.151 699.757 259.184 699.746 259.197 699.716L259.93 697.884C260.192 697.767 260.46 697.637 260.731 697.48C264.427 695.343 267.424 690.144 267.424 685.869C267.424 685.506 267.394 685.166 267.353 684.842L268.435 683.312Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M261.836 681.04C260.598 681.04 259.015 681.842 257.452 683.328C255.548 685.138 254.228 687.451 253.634 690.017C252.965 692.897 253.435 695.105 254.86 695.778L258.595 698.041L259.015 696.995L259.454 696.802C259.678 696.705 259.905 696.597 260.137 696.459C263.449 694.544 266.245 689.696 266.245 685.869C266.245 685.559 266.217 685.268 266.181 684.991L266.126 684.535L266.767 683.624C265.305 682.744 262.93 681.303 262.93 681.303C262.612 681.126 262.242 681.04 261.836 681.04ZM259.043 701.041L258.529 700.762L253.744 697.859C251.904 696.995 250.222 694.297 251.335 689.483C252.034 686.464 253.587 683.743 255.827 681.618C258.722 678.862 261.822 677.979 264.112 679.261C264.151 679.283 268.632 681.995 269.013 682.216L269.458 682.476L269.712 683.533L268.574 685.16C268.593 685.387 268.604 685.622 268.604 685.869C268.604 690.592 265.402 696.143 261.322 698.501C261.164 698.595 261.007 698.681 260.849 698.755L260.093 700.654L259.043 701.041Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M270.668 687.731C270.668 692.006 267.67 697.206 263.974 699.342C260.278 701.481 257.28 699.746 257.28 695.471C257.28 691.195 260.278 685.999 263.974 683.86C267.67 681.723 270.668 683.456 270.668 687.731Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M266.957 684.122C266.25 684.122 265.435 684.38 264.562 684.883C261.256 686.796 258.46 691.646 258.46 695.471C258.46 697.098 258.946 698.279 259.83 698.788C260.72 699.3 261.977 699.132 263.383 698.321C266.692 696.406 269.485 691.558 269.485 687.731C269.485 686.104 268.999 684.925 268.118 684.413C267.781 684.219 267.388 684.122 266.957 684.122ZM260.982 701.445C260.142 701.445 259.355 701.243 258.651 700.836C257.007 699.884 256.101 697.98 256.101 695.471C256.101 690.747 259.3 685.199 263.383 682.838C265.554 681.579 267.654 681.419 269.297 682.368C270.941 683.317 271.844 685.221 271.844 687.731C271.844 692.454 268.645 698.005 264.562 700.366C263.322 701.082 262.104 701.445 260.982 701.445Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M238.807 667.641L222.997 658.393L224.544 655.74L240.354 664.988L238.807 667.641Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M228.497 683.505L209.176 672.221L210.718 669.567L230.039 680.852L228.497 683.505Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M107.81 780.088L104.476 782.014C104.136 782.213 103.539 782.188 103.144 781.959L100.003 780.143C99.6082 779.914 99.564 779.571 99.9066 779.371L103.241 777.445C103.581 777.246 104.177 777.271 104.573 777.501L107.714 779.316C108.109 779.546 108.153 779.889 107.81 780.088Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M107.396 789.297L104.061 791.226C103.719 791.422 103.122 791.397 102.727 791.171L99.5861 789.353C99.1911 789.126 99.1496 788.78 99.4922 788.583L102.824 786.655C103.166 786.458 103.763 786.483 104.158 786.71L107.299 788.528C107.694 788.755 107.736 789.101 107.396 789.297Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.565 784.574L112.233 786.5C111.89 786.699 111.294 786.674 110.899 786.444L107.758 784.629C107.363 784.399 107.321 784.053 107.664 783.857L110.995 781.928C111.338 781.732 111.935 781.757 112.33 781.984L115.471 783.802C115.866 784.031 115.907 784.374 115.565 784.574Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.603 794.046L112.269 795.972C111.926 796.171 111.33 796.146 110.935 795.916L107.794 794.101C107.399 793.871 107.357 793.528 107.7 793.329L111.031 791.4C111.374 791.204 111.971 791.229 112.366 791.456L115.507 793.274C115.902 793.503 115.943 793.846 115.603 794.046Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M123.772 789.32L120.441 791.248C120.098 791.445 119.501 791.42 119.106 791.193L115.965 789.375C115.57 789.148 115.529 788.802 115.871 788.603L119.203 786.677C119.545 786.478 120.142 786.503 120.537 786.732L123.678 788.548C124.073 788.777 124.115 789.123 123.772 789.32Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M123.358 798.531L120.023 800.457C119.684 800.656 119.087 800.632 118.692 800.402L115.551 798.587C115.156 798.357 115.112 798.011 115.454 797.815L118.789 795.886C119.128 795.689 119.725 795.714 120.12 795.941L123.261 797.759C123.656 797.986 123.7 798.332 123.358 798.531Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M131.529 793.805L128.195 795.731C127.855 795.93 127.258 795.905 126.863 795.676L123.722 793.86C123.327 793.631 123.283 793.288 123.626 793.088L126.96 791.162C127.3 790.963 127.897 790.988 128.292 791.218L131.433 793.033C131.828 793.263 131.872 793.606 131.529 793.805Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M131.112 803.014L127.781 804.943C127.438 805.142 126.841 805.117 126.446 804.888L123.305 803.07C122.91 802.843 122.869 802.497 123.211 802.3L126.543 800.371C126.886 800.175 127.482 800.2 127.877 800.427L131.018 802.245C131.413 802.472 131.455 802.818 131.112 803.014Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M139.284 798.291L135.952 800.217C135.61 800.416 135.013 800.391 134.618 800.161L131.477 798.346C131.082 798.116 131.04 797.773 131.38 797.574L134.715 795.648C135.057 795.449 135.654 795.474 136.049 795.703L139.19 797.519C139.585 797.748 139.626 798.091 139.284 798.291Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M139.32 807.763L135.988 809.689C135.646 809.888 135.049 809.863 134.654 809.633L131.513 807.818C131.118 807.588 131.076 807.245 131.416 807.046L134.751 805.12C135.093 804.921 135.69 804.946 136.085 805.175L139.226 806.991C139.621 807.22 139.662 807.564 139.32 807.763Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M147.491 803.036L144.16 804.965C143.817 805.162 143.22 805.137 142.825 804.91L139.684 803.092C139.289 802.865 139.248 802.519 139.588 802.32L142.922 800.394C143.265 800.194 143.861 800.219 144.254 800.449L147.397 802.264C147.792 802.494 147.834 802.84 147.491 803.036Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M147.077 812.248L143.743 814.174C143.403 814.374 142.806 814.349 142.411 814.119L139.27 812.304C138.875 812.074 138.831 811.728 139.173 811.532L142.508 809.606C142.848 809.406 143.444 809.431 143.839 809.661L146.98 811.476C147.375 811.706 147.42 812.049 147.077 812.248Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M155.249 807.522L151.914 809.451C151.574 809.647 150.978 809.622 150.583 809.395L147.442 807.577C147.047 807.348 147.002 807.004 147.345 806.805L150.677 804.879C151.019 804.68 151.616 804.705 152.011 804.935L155.152 806.75C155.547 806.98 155.591 807.325 155.249 807.522Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M154.831 816.734L151.5 818.66C151.157 818.859 150.561 818.834 150.165 818.604L147.024 816.789C146.629 816.559 146.588 816.214 146.931 816.017L150.262 814.088C150.605 813.892 151.201 813.917 151.596 814.144L154.737 815.962C155.133 816.189 155.174 816.535 154.831 816.734Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M163.003 812.008L159.671 813.934C159.329 814.133 158.732 814.108 158.337 813.878L155.196 812.063C154.801 811.833 154.76 811.49 155.099 811.291L158.434 809.365C158.776 809.166 159.373 809.191 159.768 809.42L162.909 811.235C163.304 811.465 163.346 811.808 163.003 812.008Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M163.039 821.479L159.707 823.406C159.365 823.605 158.768 823.58 158.373 823.35L155.232 821.535C154.837 821.305 154.795 820.962 155.135 820.763L158.47 818.837C158.812 818.638 159.409 818.662 159.804 818.892L162.945 820.707C163.34 820.937 163.381 821.283 163.039 821.479Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M171.211 816.753L167.879 818.682C167.536 818.878 166.94 818.853 166.545 818.627L163.404 816.809C163.009 816.582 162.967 816.236 163.307 816.039L166.641 814.111C166.984 813.914 167.581 813.939 167.973 814.166L171.117 815.984C171.512 816.211 171.553 816.557 171.211 816.753Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M170.796 825.965L167.462 827.891C167.122 828.09 166.525 828.065 166.13 827.836L162.989 826.02C162.594 825.791 162.55 825.448 162.892 825.248L166.224 823.322C166.567 823.123 167.163 823.148 167.558 823.378L170.699 825.193C171.094 825.423 171.139 825.766 170.796 825.965Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M178.968 821.239L175.633 823.167C175.294 823.364 174.697 823.339 174.302 823.112L171.161 821.294C170.766 821.067 170.722 820.721 171.064 820.522L174.396 818.596C174.738 818.397 175.335 818.422 175.73 818.651L178.871 820.47C179.266 820.696 179.308 821.042 178.968 821.239Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M178.551 830.451L175.219 832.377C174.876 832.576 174.28 832.551 173.885 832.321L170.744 830.506C170.349 830.276 170.307 829.933 170.647 829.734L173.981 827.808C174.324 827.609 174.921 827.634 175.313 827.863L178.457 829.679C178.852 829.908 178.893 830.251 178.551 830.451Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M186.722 825.724L183.391 827.653C183.048 827.85 182.451 827.825 182.056 827.598L178.915 825.78C178.52 825.553 178.479 825.207 178.819 825.008L182.153 823.082C182.496 822.882 183.092 822.907 183.485 823.137L186.628 824.952C187.021 825.182 187.065 825.528 186.722 825.724Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M194.894 820.998L191.562 822.927C191.22 823.123 190.623 823.098 190.228 822.871L187.087 821.053C186.692 820.826 186.65 820.481 186.99 820.284L190.325 818.355C190.664 818.159 191.264 818.184 191.656 818.411L194.8 820.229C195.192 820.456 195.236 820.802 194.894 820.998Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M203.065 816.272L199.734 818.2C199.391 818.397 198.795 818.372 198.399 818.145L195.258 816.327C194.863 816.1 194.822 815.754 195.162 815.558L198.496 813.629C198.836 813.433 199.433 813.458 199.828 813.684L202.971 815.502C203.364 815.729 203.408 816.075 203.065 816.272Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M211.237 811.548L207.905 813.474C207.563 813.673 206.966 813.648 206.571 813.419L203.43 811.604C203.035 811.374 202.994 811.028 203.333 810.831L206.668 808.903C207.008 808.706 207.604 808.731 207.999 808.958L211.14 810.776C211.535 811.006 211.58 811.349 211.237 811.548Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M219.409 806.822L216.074 808.748C215.734 808.947 215.138 808.922 214.743 808.692L211.602 806.877C211.207 806.647 211.162 806.304 211.505 806.105L214.839 804.179C215.179 803.98 215.776 804.005 216.171 804.234L219.312 806.05C219.707 806.279 219.751 806.625 219.409 806.822Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M227.58 802.095L224.246 804.024C223.906 804.221 223.309 804.196 222.914 803.969L219.773 802.151C219.378 801.924 219.334 801.578 219.677 801.382L223.011 799.453C223.351 799.256 223.947 799.281 224.342 799.508L227.483 801.326C227.879 801.553 227.923 801.899 227.58 802.095Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M235.752 797.369L232.417 799.298C232.078 799.494 231.481 799.469 231.086 799.243L227.945 797.424C227.55 797.198 227.506 796.852 227.848 796.655L231.183 794.726C231.522 794.53 232.119 794.555 232.514 794.782L235.655 796.6C236.05 796.827 236.094 797.173 235.752 797.369Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M243.923 792.646L240.589 794.572C240.249 794.771 239.653 794.746 239.257 794.516L236.116 792.701C235.721 792.471 235.677 792.125 236.02 791.929L239.351 790C239.694 789.804 240.291 789.829 240.686 790.056L243.827 791.874C244.222 792.101 244.263 792.446 243.923 792.646Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M252.095 787.919L248.761 789.845C248.421 790.044 247.821 790.019 247.429 789.79L244.285 787.975C243.893 787.745 243.849 787.402 244.191 787.202L247.523 785.277C247.866 785.077 248.462 785.102 248.857 785.332L251.998 787.147C252.393 787.377 252.435 787.72 252.095 787.919Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M260.267 783.193L256.932 785.122C256.59 785.318 255.993 785.293 255.601 785.066L252.457 783.248C252.065 783.021 252.02 782.675 252.363 782.476L255.695 780.55C256.037 780.354 256.634 780.376 257.029 780.606L260.17 782.421C260.565 782.651 260.606 782.996 260.267 783.193Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M194.93 830.47L191.598 832.399C191.256 832.595 190.659 832.57 190.264 832.344L187.123 830.526C186.728 830.299 186.686 829.953 187.026 829.756L190.36 827.828C190.703 827.631 191.3 827.656 191.692 827.883L194.836 829.701C195.228 829.928 195.272 830.274 194.93 830.47Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M203.101 825.744L199.77 827.672C199.427 827.869 198.83 827.847 198.435 827.617L195.294 825.799C194.899 825.572 194.858 825.226 195.198 825.03L198.532 823.101C198.872 822.905 199.471 822.93 199.864 823.157L203.007 824.974C203.4 825.201 203.444 825.547 203.101 825.744Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M211.273 821.02L207.941 822.946C207.599 823.145 207.002 823.12 206.607 822.891L203.466 821.075C203.071 820.846 203.029 820.503 203.369 820.303L206.704 818.375C207.043 818.178 207.64 818.203 208.035 818.433L211.176 820.248C211.571 820.478 211.615 820.821 211.273 821.02Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M219.445 816.294L216.113 818.223C215.77 818.419 215.174 818.394 214.779 818.167L211.638 816.349C211.243 816.122 211.198 815.776 211.541 815.577L214.875 813.651C215.215 813.452 215.812 813.477 216.207 813.707L219.348 815.522C219.743 815.752 219.787 816.097 219.445 816.294Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M227.616 811.568L224.282 813.496C223.942 813.693 223.345 813.668 222.95 813.441L219.809 811.623C219.414 811.396 219.37 811.05 219.712 810.854L223.047 808.925C223.387 808.728 223.983 808.753 224.378 808.98L227.519 810.798C227.914 811.025 227.959 811.371 227.616 811.568Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M235.788 806.841L232.453 808.77C232.114 808.966 231.517 808.942 231.122 808.715L227.981 806.897C227.586 806.67 227.542 806.324 227.884 806.127L231.216 804.198C231.558 804.002 232.155 804.027 232.55 804.254L235.691 806.072C236.086 806.299 236.13 806.645 235.788 806.841Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M243.959 802.118L240.625 804.044C240.285 804.243 239.688 804.218 239.293 803.988L236.152 802.173C235.757 801.943 235.713 801.6 236.056 801.401L239.387 799.472C239.73 799.276 240.327 799.301 240.722 799.528L243.863 801.346C244.258 801.575 244.302 801.918 243.959 802.118Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M252.131 797.391L248.796 799.317C248.457 799.516 247.86 799.492 247.465 799.262L244.324 797.447C243.929 797.217 243.885 796.874 244.227 796.675L247.559 794.749C247.901 794.549 248.498 794.574 248.893 794.804L252.034 796.619C252.429 796.849 252.471 797.195 252.131 797.391Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M260.302 792.665L256.968 794.594C256.626 794.79 256.029 794.765 255.637 794.538L252.493 792.72C252.1 792.493 252.056 792.147 252.399 791.951L255.73 790.022C256.073 789.826 256.67 789.851 257.065 790.078L260.206 791.896C260.601 792.123 260.642 792.468 260.302 792.665Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M268.474 787.939L265.14 789.867C264.797 790.064 264.2 790.039 263.808 789.812L260.664 787.994C260.269 787.767 260.228 787.421 260.57 787.225L263.902 785.296C264.245 785.099 264.841 785.124 265.236 785.351L268.377 787.169C268.772 787.396 268.814 787.742 268.474 787.939Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M276.574 773.762L273.239 775.691C272.897 775.887 272.3 775.863 271.908 775.636L268.764 773.818C268.372 773.591 268.328 773.245 268.67 773.046L272.002 771.12C272.344 770.921 272.941 770.946 273.336 771.175L276.477 772.99C276.872 773.22 276.914 773.566 276.574 773.762Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M268.402 778.489L265.068 780.415C264.728 780.614 264.129 780.589 263.736 780.359L260.593 778.544C260.2 778.314 260.156 777.971 260.499 777.772L263.83 775.846C264.173 775.647 264.77 775.672 265.165 775.901L268.306 777.717C268.701 777.946 268.742 778.29 268.402 778.489Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M284.745 769.036L281.411 770.965C281.069 771.161 280.472 771.136 280.08 770.909L276.936 769.091C276.543 768.865 276.499 768.519 276.842 768.322L280.173 766.393C280.516 766.197 281.113 766.222 281.508 766.449L284.649 768.267C285.044 768.494 285.085 768.84 284.745 769.036Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M292.917 764.31L289.583 766.238C289.24 766.435 288.643 766.413 288.248 766.183L285.107 764.368C284.712 764.138 284.671 763.792 285.013 763.596L288.345 761.667C288.688 761.471 289.284 761.495 289.679 761.722L292.82 763.54C293.215 763.767 293.257 764.113 292.917 764.31Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M301.086 759.586L297.754 761.512C297.412 761.711 296.815 761.686 296.42 761.457L293.279 759.642C292.884 759.412 292.842 759.069 293.185 758.869L296.517 756.944C296.859 756.744 297.456 756.769 297.851 756.999L300.992 758.814C301.387 759.044 301.428 759.387 301.086 759.586Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M309.258 754.86L305.926 756.789C305.583 756.985 304.987 756.96 304.592 756.733L301.451 754.915C301.056 754.688 301.014 754.342 301.354 754.143L304.688 752.217C305.031 752.018 305.628 752.043 306.023 752.272L309.164 754.088C309.559 754.317 309.6 754.663 309.258 754.86Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M317.429 750.133L314.097 752.062C313.755 752.259 313.158 752.234 312.763 752.007L309.622 750.189C309.227 749.962 309.186 749.616 309.525 749.42L312.86 747.491C313.202 747.294 313.799 747.319 314.191 747.546L317.335 749.364C317.727 749.591 317.772 749.937 317.429 750.133Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M325.601 745.407L322.269 747.336C321.927 747.532 321.33 747.507 320.935 747.281L317.794 745.463C317.399 745.236 317.357 744.89 317.697 744.693L321.031 742.765C321.374 742.568 321.971 742.593 322.363 742.82L325.507 744.638C325.899 744.865 325.943 745.211 325.601 745.407Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M333.772 740.684L330.441 742.609C330.098 742.809 329.501 742.784 329.106 742.554L325.965 740.739C325.57 740.509 325.526 740.163 325.869 739.967L329.203 738.041C329.543 737.842 330.14 737.867 330.535 738.096L333.678 739.912C334.071 740.141 334.115 740.484 333.772 740.684Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M284.781 778.508L281.447 780.437C281.104 780.633 280.508 780.608 280.115 780.382L276.972 778.563C276.579 778.337 276.535 777.991 276.878 777.794L280.209 775.865C280.552 775.669 281.149 775.694 281.544 775.921L284.685 777.739C285.08 777.966 285.121 778.312 284.781 778.508Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M276.61 783.234L273.275 785.163C272.936 785.36 272.336 785.335 271.944 785.108L268.8 783.29C268.408 783.063 268.364 782.717 268.706 782.518L272.038 780.592C272.38 780.393 272.977 780.417 273.372 780.647L276.513 782.462C276.908 782.692 276.95 783.038 276.61 783.234Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M292.953 773.785L289.619 775.71C289.276 775.91 288.679 775.885 288.287 775.655L285.143 773.84C284.748 773.61 284.707 773.264 285.049 773.068L288.381 771.139C288.724 770.943 289.32 770.967 289.715 771.194L292.856 773.012C293.251 773.239 293.293 773.585 292.953 773.785Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M301.125 769.058L297.79 770.984C297.448 771.183 296.851 771.158 296.456 770.929L293.315 769.114C292.92 768.884 292.878 768.541 293.221 768.342L296.553 766.416C296.895 766.216 297.492 766.241 297.887 766.471L301.028 768.286C301.423 768.516 301.464 768.859 301.125 769.058Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M309.293 764.332L305.962 766.261C305.619 766.457 305.023 766.432 304.627 766.205L301.486 764.387C301.091 764.16 301.05 763.814 301.39 763.615L304.724 761.689C305.067 761.49 305.663 761.515 306.058 761.744L309.199 763.56C309.595 763.79 309.636 764.135 309.293 764.332Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M317.465 759.606L314.133 761.534C313.791 761.731 313.194 761.706 312.799 761.479L309.658 759.661C309.263 759.434 309.222 759.088 309.561 758.892L312.896 756.963C313.238 756.766 313.835 756.791 314.227 757.018L317.371 758.836C317.766 759.063 317.808 759.409 317.465 759.606Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M325.637 754.882L322.305 756.808C321.962 757.004 321.366 756.979 320.971 756.753L317.83 754.935C317.435 754.708 317.393 754.362 317.733 754.165L321.067 752.237C321.41 752.04 322.007 752.065 322.399 752.292L325.543 754.11C325.935 754.337 325.979 754.683 325.637 754.882Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M333.808 750.156L330.477 752.082C330.134 752.281 329.537 752.256 329.142 752.026L326.001 750.211C325.606 749.981 325.565 749.638 325.905 749.439L329.239 747.513C329.579 747.314 330.178 747.339 330.571 747.568L333.714 749.384C334.107 749.613 334.151 749.956 333.808 750.156Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M164.807 676.004L161.473 677.93C161.133 678.129 160.536 678.104 160.141 677.874L157 676.059C156.605 675.829 156.561 675.486 156.903 675.287L160.238 673.361C160.577 673.162 161.174 673.187 161.569 673.416L164.71 675.231C165.105 675.461 165.149 675.807 164.807 676.004Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M164.393 685.215L161.058 687.141C160.716 687.341 160.119 687.316 159.724 687.086L156.583 685.271C156.188 685.041 156.146 684.695 156.489 684.499L159.821 682.57C160.163 682.374 160.76 682.399 161.155 682.626L164.296 684.443C164.691 684.67 164.732 685.016 164.393 685.215Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M172.561 680.489L169.23 682.415C168.887 682.614 168.291 682.589 167.895 682.36L164.754 680.545C164.359 680.315 164.318 679.972 164.658 679.772L167.992 677.847C168.335 677.647 168.931 677.672 169.326 677.902L172.467 679.717C172.863 679.947 172.904 680.29 172.561 680.489Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M172.597 689.961L169.266 691.887C168.923 692.086 168.326 692.061 167.931 691.832L164.79 690.017C164.395 689.787 164.354 689.444 164.696 689.245L168.028 687.319C168.371 687.119 168.967 687.144 169.362 687.374L172.503 689.189C172.898 689.419 172.94 689.762 172.597 689.961Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M180.769 685.235L177.437 687.164C177.095 687.36 176.498 687.335 176.103 687.108L172.962 685.29C172.567 685.063 172.525 684.717 172.865 684.521L176.2 682.592C176.542 682.393 177.139 682.418 177.534 682.648L180.675 684.466C181.07 684.692 181.111 685.038 180.769 685.235Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M180.355 694.447L177.02 696.373C176.68 696.572 176.084 696.547 175.689 696.317L172.548 694.502C172.153 694.272 172.108 693.929 172.451 693.73L175.785 691.804C176.125 691.605 176.722 691.63 177.117 691.859L180.258 693.675C180.653 693.904 180.697 694.248 180.355 694.447Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M188.526 689.72L185.192 691.649C184.852 691.846 184.255 691.821 183.86 691.594L180.719 689.776C180.324 689.549 180.28 689.203 180.622 689.004L183.954 687.078C184.297 686.879 184.893 686.903 185.288 687.133L188.429 688.948C188.824 689.178 188.869 689.524 188.526 689.72Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M196.698 684.994L193.363 686.923C193.024 687.119 192.427 687.094 192.032 686.868L188.891 685.05C188.496 684.823 188.452 684.477 188.794 684.28L192.126 682.352C192.468 682.155 193.065 682.18 193.46 682.407L196.601 684.225C196.996 684.452 197.04 684.798 196.698 684.994Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M196.281 694.206L192.949 696.135C192.606 696.331 192.01 696.306 191.615 696.08L188.474 694.262C188.079 694.032 188.037 693.689 188.377 693.489L191.711 691.564C192.054 691.364 192.651 691.389 193.046 691.619L196.187 693.434C196.582 693.664 196.623 694.007 196.281 694.206Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M204.452 689.48L201.121 691.408C200.778 691.605 200.181 691.58 199.786 691.353L196.645 689.535C196.25 689.308 196.209 688.962 196.549 688.766L199.883 686.837C200.225 686.641 200.822 686.666 201.214 686.893L204.358 688.711C204.753 688.937 204.795 689.283 204.452 689.48Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M213.005 675.564L209.671 677.492C209.331 677.689 208.734 677.664 208.339 677.437L205.198 675.619C204.803 675.392 204.759 675.046 205.101 674.85L208.433 672.921C208.776 672.725 209.372 672.749 209.767 672.976L212.908 674.794C213.303 675.021 213.348 675.367 213.005 675.564Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M204.833 680.29L201.499 682.216C201.159 682.415 200.563 682.39 200.167 682.161L197.026 680.345C196.631 680.116 196.587 679.772 196.93 679.573L200.261 677.647C200.604 677.448 201.201 677.473 201.596 677.703L204.737 679.518C205.132 679.748 205.176 680.093 204.833 680.29Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M220.759 680.049L217.428 681.978C217.085 682.174 216.489 682.15 216.094 681.923L212.953 680.105C212.557 679.878 212.516 679.532 212.856 679.333L216.19 677.407C216.533 677.207 217.129 677.232 217.522 677.462L220.666 679.277C221.061 679.507 221.102 679.853 220.759 680.049Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M212.588 684.776L209.256 686.702C208.914 686.901 208.317 686.876 207.922 686.646L204.781 684.831C204.386 684.601 204.344 684.258 204.684 684.059L208.019 682.133C208.361 681.934 208.958 681.959 209.353 682.188L212.494 684.004C212.889 684.233 212.93 684.576 212.588 684.776Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M653.838 460.504H656.197V458.816H653.838V460.504Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M656.412 461.077L621.662 481.208C619.941 482.204 616.933 482.08 614.944 480.932L508.321 419.655C506.334 418.504 506.116 416.764 507.84 415.767L542.587 395.636C544.308 394.64 547.317 394.764 549.306 395.916L655.929 457.189C657.918 458.34 658.133 460.081 656.412 461.077Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M545.488 396.497C544.667 396.497 543.902 396.649 543.355 396.967L508.608 417.098C508.285 417.284 508.21 417.439 508.21 417.455C508.21 417.527 508.365 417.906 509.089 418.324L615.706 479.601C617.22 480.469 619.646 480.602 620.894 479.88L655.644 459.746C655.968 459.561 656.039 459.406 656.039 459.389C656.039 459.317 655.885 458.938 655.164 458.52L548.543 397.247C547.69 396.754 546.546 396.497 545.488 396.497ZM618.737 483.411C617.148 483.411 615.494 483.024 614.176 482.263L507.558 420.986C506.022 420.098 505.144 418.811 505.144 417.455C505.144 416.249 505.845 415.148 507.072 414.436L541.822 394.305C543.999 393.043 547.629 393.168 550.074 394.585L656.691 455.858C658.23 456.746 659.109 458.03 659.109 459.389C659.109 460.598 658.404 461.697 657.178 462.405L622.43 482.537C621.422 483.12 620.104 483.411 618.737 483.411Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M656.412 458.161L621.662 478.294C619.941 479.291 616.933 479.166 614.944 478.015L508.321 416.741C506.334 415.59 506.116 413.85 507.84 412.854L542.587 392.722C544.308 391.726 547.317 391.851 549.306 392.999L655.929 454.275C657.918 455.427 658.133 457.167 656.412 458.161Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M545.488 393.583C544.667 393.583 543.902 393.735 543.355 394.051L508.608 414.185C508.285 414.37 508.21 414.525 508.21 414.542C508.21 414.613 508.365 414.993 509.089 415.41L615.706 476.684C617.217 477.553 619.64 477.688 620.894 476.963L655.644 456.832C655.968 456.647 656.039 456.492 656.039 456.475C656.039 456.403 655.885 456.024 655.164 455.606L548.543 394.333C547.69 393.84 546.546 393.583 545.488 393.583ZM618.74 480.494C617.148 480.494 615.494 480.107 614.176 479.349L507.558 418.072C506.022 417.184 505.144 415.897 505.144 414.542C505.144 413.332 505.845 412.234 507.072 411.523L541.822 391.391C543.999 390.127 547.629 390.251 550.074 391.671L656.691 452.942C658.23 453.833 659.109 455.119 659.109 456.475C659.106 457.685 658.404 458.783 657.178 459.492L622.43 479.626C621.422 480.209 620.104 480.494 618.74 480.494Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M617.507 481.883H619.869V478.698H617.507V481.883Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M507.545 419.973H509.904V416.791H507.545V419.973Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M654.164 461.7H656.523V458.517H654.164V461.7Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M616.322 469.619C615.411 470.148 615.411 471.006 616.322 471.534C617.237 472.06 618.72 472.06 619.632 471.534C620.543 471.006 620.543 470.148 619.632 469.619C618.72 469.094 617.237 469.094 616.322 469.619ZM620.632 472.113C619.168 472.957 616.786 472.957 615.325 472.113C613.861 471.266 613.861 469.888 615.325 469.041C616.786 468.197 619.168 468.197 620.632 469.041C622.093 469.888 622.093 471.266 620.632 472.113Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M616.289 460.117C615.377 460.643 615.377 461.503 616.289 462.029C617.201 462.558 618.684 462.558 619.599 462.029C620.51 461.503 620.51 460.643 619.599 460.117C618.684 459.588 617.201 459.588 616.289 460.117ZM620.596 462.607C619.135 463.454 616.753 463.454 615.289 462.607C613.828 461.761 613.828 460.385 615.289 459.539C616.753 458.692 619.135 458.692 620.599 459.539C622.06 460.385 622.06 461.761 620.596 462.607Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M631.221 461.005L634.475 462.917L637.782 461.036C637.798 461.027 637.798 461.013 637.785 461.005L634.528 459.123L631.221 461.005ZM638.782 461.614L635.528 463.496C634.961 463.825 634.041 463.825 633.475 463.496L630.221 461.614C629.654 461.287 629.654 460.753 630.221 460.427L633.475 458.545C634.041 458.216 634.961 458.216 635.528 458.545L638.785 460.427C639.348 460.753 639.348 461.287 638.782 461.614Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M606.463 465.801L609.717 467.713L613.027 465.831C613.04 465.823 613.04 465.809 613.024 465.798L609.769 463.916L606.463 465.801ZM614.024 466.409L610.77 468.291C610.203 468.618 609.283 468.618 608.717 468.291L605.463 466.409C604.896 466.08 604.896 465.549 605.463 465.222L608.717 463.338C609.283 463.011 610.203 463.011 610.77 463.338L614.024 465.222C614.59 465.549 614.59 466.08 614.024 466.409Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M628.922 457.214L627.922 457.793L623.613 455.302L624.613 454.724L628.922 457.214Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M628.922 455.302L624.613 457.793L623.615 457.214L627.922 454.724L628.922 455.302Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M628.831 466.791L627.831 467.37L623.524 464.879L624.524 464.301L628.831 466.791Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M628.831 464.879L624.524 467.37L623.524 466.791L627.831 464.301L628.831 464.879Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M600.294 460.349C599.382 460.878 599.382 461.736 600.294 462.264C601.206 462.79 602.689 462.79 603.601 462.264C604.515 461.736 604.515 460.878 603.601 460.349C602.689 459.823 601.206 459.823 600.294 460.349ZM604.601 462.84C603.139 463.687 600.758 463.687 599.294 462.84C597.833 461.996 597.833 460.618 599.294 459.771C600.758 458.924 603.139 458.924 604.601 459.771C606.065 460.618 606.065 461.996 604.601 462.84Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M615.192 451.735L618.447 453.647L621.753 451.766C621.767 451.757 621.77 451.743 621.753 451.735L618.499 449.853L615.192 451.735ZM622.753 452.344L619.499 454.226C618.933 454.552 618.013 454.552 617.447 454.226L614.192 452.344C613.626 452.017 613.626 451.483 614.192 451.157L617.447 449.275C618.013 448.946 618.933 448.946 619.499 449.275L622.753 451.157C623.32 451.483 623.32 452.017 622.753 452.344Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M612.8 457.521L611.803 458.1L607.493 455.609L608.493 455.031L612.8 457.521Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M612.803 455.609L608.493 458.1L607.493 457.521L611.803 455.031L612.803 455.609Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M561.668 420.344H561.665C559.472 420.344 557.693 418.562 557.693 416.365V115.02C557.693 112.82 559.472 111.038 561.665 111.038C563.862 111.038 565.641 112.82 565.641 115.02V416.365C565.641 418.562 563.862 420.344 561.668 420.344Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M701.254 230.494L563.116 150.442C561.218 149.341 560.569 146.906 561.665 145.002C562.765 143.101 565.196 142.451 567.097 143.552L705.235 223.604C707.136 224.705 707.782 227.137 706.685 229.041C705.586 230.945 703.155 231.595 701.254 230.494Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M701.254 265.197L563.116 185.145C561.218 184.044 560.569 181.609 561.665 179.708C562.765 177.804 565.196 177.154 567.097 178.255L705.235 258.307C707.136 259.408 707.782 261.843 706.685 263.747C705.586 265.648 703.155 266.298 701.254 265.197Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M701.254 299.939L563.116 219.89C561.218 218.789 560.569 216.354 561.665 214.45C562.765 212.549 565.196 211.898 567.097 212.997L705.235 293.049C707.136 294.15 707.782 296.585 706.685 298.489C705.586 300.39 703.155 301.04 701.254 299.939Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M701.254 334.645L563.116 254.593C561.218 253.492 560.569 251.06 561.665 249.156C562.765 247.252 565.196 246.602 567.097 247.703L705.235 327.755C707.136 328.856 707.782 331.291 706.685 333.192C705.586 335.096 703.155 335.746 701.254 334.645Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M701.254 369.389L563.116 289.335C561.218 288.237 560.569 285.801 561.665 283.898C562.765 281.994 565.196 281.344 567.097 282.445L705.235 362.496C707.136 363.598 707.782 366.033 706.685 367.937C705.586 369.838 703.155 370.488 701.254 369.389Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M701.254 404.093L563.116 324.041C561.218 322.94 560.569 320.505 561.665 318.604C562.765 316.7 565.196 316.049 567.097 317.151L705.235 397.2C707.136 398.301 707.782 400.736 706.685 402.64C705.586 404.544 703.155 405.194 701.254 404.093Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M701.254 438.834L563.116 358.783C561.218 357.684 560.569 355.249 561.665 353.345C562.765 351.442 565.196 350.791 567.097 351.893L705.235 431.944C707.136 433.046 707.782 435.481 706.685 437.382C705.586 439.286 703.155 439.936 701.254 438.834Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M706.685 504.89C704.489 504.89 702.71 503.108 702.71 500.911V199.565C702.71 197.368 704.489 195.586 706.685 195.586C708.878 195.586 710.658 197.368 710.658 199.565V500.911C710.658 503.108 708.878 504.89 706.685 504.89Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M701.826 231.031H703.594V221.727H701.826V231.031Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M701.826 265.773H703.594V256.469H701.826V265.773Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M701.826 300.479H703.594V291.175H701.826V300.479Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M701.826 335.22H703.594V325.917H701.826V335.22Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M701.826 369.924H703.594V360.62H701.826V369.924Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M701.826 404.668H703.594V395.365H701.826V404.668Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M701.826 439.371H703.594V430.068H701.826V439.371Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M565.389 326.59C563.326 325.978 559.707 323.532 560.804 318.645C561.541 317.115 563.467 314.893 566.005 316.044L565.276 317.66C563.638 316.921 562.505 319.143 562.456 319.24C561.527 323.507 565.715 324.835 565.895 324.891L565.389 326.59Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M565.389 291.886C563.326 291.275 559.707 288.829 560.804 283.939C561.541 282.406 563.472 280.19 566.005 281.338L565.276 282.954C563.66 282.226 562.505 284.437 562.456 284.534C561.519 288.837 565.713 290.135 565.892 290.185L565.389 291.886Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M565.389 257.319C563.326 256.707 559.707 254.261 560.804 249.374C561.541 247.841 563.467 245.622 566.005 246.773L565.276 248.389C563.638 247.65 562.505 249.875 562.456 249.969C561.527 254.239 565.715 255.567 565.895 255.62L565.389 257.319Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M638.072 230.046L566.191 188.394L566.544 214.082L565.276 213.509C563.657 212.77 562.505 214.992 562.456 215.086C561.527 219.356 565.715 220.684 565.895 220.74L565.389 222.439C563.326 221.827 559.707 219.381 560.804 214.494C561.414 213.227 562.848 211.461 564.74 211.577L564.381 185.298L638.956 228.513L638.072 230.046Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M565.036 187.697C562.969 187.085 559.356 184.636 560.45 179.749C561.19 178.216 563.113 176 565.649 177.148L564.923 178.764C563.282 178.023 562.152 180.245 562.105 180.342C561.176 184.614 565.362 185.942 565.539 185.998L565.036 187.697Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M565.389 152.991C563.326 152.379 559.707 149.933 560.804 145.046C561.541 143.513 563.472 141.3 566.005 142.445L565.276 144.061C563.657 143.32 562.505 145.544 562.456 145.638C561.527 149.908 565.715 151.236 565.895 151.292L565.389 152.991Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M690.215 433.837L616.988 391.402L617.872 389.869L691.099 432.301L690.215 433.837Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M638.072 368.667L564.845 326.233L565.729 324.7L638.956 367.134L638.072 368.667Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M682.949 359.815L609.725 317.38L610.609 315.847L683.836 358.282L682.949 359.815Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M661.755 313.022L588.531 270.587L589.415 269.052L662.639 311.486L661.755 313.022Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M698.37 299.671L625.143 257.236L626.027 255.703L699.254 298.137L698.37 299.671Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M676.33 217.571L603.106 175.137L603.99 173.601L677.214 216.035L676.33 217.571Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M711.544 274.323H709.773V199.565C709.773 197.861 708.387 196.471 706.685 196.471C704.98 196.471 703.594 197.861 703.594 199.565V201.513H701.826V199.565C701.826 196.881 704.005 194.698 706.685 194.698C709.365 194.698 711.544 196.881 711.544 199.565V274.323Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M566.345 116.968H564.574V115.02C564.574 113.312 563.188 111.926 561.483 111.926C559.781 111.926 558.397 113.312 558.397 115.02V116.968H556.626V115.02C556.626 112.335 558.806 110.152 561.483 110.152C564.166 110.152 566.345 112.335 566.345 115.02V116.968Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M561.665 420.906C558.986 420.906 556.806 418.723 556.806 416.039V414.09H558.577V416.039C558.577 417.746 559.964 419.135 561.665 419.135C563.37 419.135 564.757 417.746 564.757 416.039V360.869C562.674 359.992 559.815 357.568 560.804 353.152C561.541 351.619 563.467 349.405 566.005 350.551L565.276 352.164C563.638 351.428 562.505 353.653 562.456 353.747C561.527 358.014 565.715 359.342 565.895 359.397L566.522 359.588L566.525 360.247V416.039C566.525 418.723 564.345 420.906 561.665 420.906Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M706.685 505.778C704.005 505.778 701.826 503.592 701.826 500.911V498.963H703.594V500.911C703.594 502.615 704.98 504.004 706.685 504.004C708.387 504.004 709.773 502.615 709.773 500.911V478.715H711.544V500.911C711.544 503.592 709.365 505.778 706.685 505.778Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M511.547 429.736L508.216 431.665C507.873 431.861 507.276 431.836 506.881 431.609L503.74 429.791C503.345 429.562 503.304 429.219 503.646 429.019L506.978 427.093C507.321 426.894 507.917 426.919 508.312 427.149L511.453 428.964C511.848 429.194 511.89 429.54 511.547 429.736Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M503.682 438.491L507.014 436.565C507.357 436.366 507.953 436.391 508.348 436.621L511.489 438.436C511.884 438.666 511.926 439.012 511.586 439.208L508.252 441.137C507.909 441.333 507.312 441.308 506.917 441.081L503.776 439.263C503.381 439.037 503.34 438.691 503.682 438.491C503.682 438.491 503.34 438.691 503.682 438.491Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M519.755 434.482L516.423 436.41C516.081 436.607 515.484 436.582 515.089 436.355L511.948 434.537C511.553 434.31 511.511 433.964 511.851 433.768L515.186 431.839C515.528 431.643 516.125 431.668 516.52 431.895L519.661 433.712C520.056 433.939 520.097 434.285 519.755 434.482Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M519.341 443.694L516.009 445.62C515.666 445.819 515.07 445.794 514.675 445.564L511.534 443.749C511.139 443.519 511.094 443.176 511.437 442.977L514.771 441.051C515.111 440.852 515.708 440.877 516.103 441.106L519.244 442.922C519.639 443.151 519.683 443.497 519.341 443.694Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M527.512 438.967L524.178 440.896C523.838 441.093 523.241 441.068 522.846 440.841L519.705 439.023C519.31 438.796 519.266 438.45 519.609 438.253L522.943 436.325C523.283 436.128 523.879 436.153 524.274 436.38L527.415 438.198C527.81 438.425 527.855 438.771 527.512 438.967Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M527.098 448.179L523.763 450.105C523.421 450.304 522.824 450.28 522.429 450.05L519.288 448.235C518.893 448.005 518.851 447.662 519.194 447.463L522.526 445.537C522.868 445.337 523.465 445.362 523.86 445.592L527.001 447.407C527.396 447.637 527.438 447.98 527.098 448.179Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M535.267 443.453L531.935 445.382C531.592 445.578 530.996 445.553 530.601 445.326L527.46 443.508C527.065 443.281 527.023 442.936 527.366 442.736L530.697 440.81C531.04 440.611 531.637 440.636 532.032 440.866L535.173 442.684C535.568 442.911 535.609 443.257 535.267 443.453Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M535.305 452.925L531.971 454.854C531.628 455.05 531.032 455.025 530.637 454.798L527.496 452.98C527.101 452.753 527.059 452.407 527.402 452.211L530.733 450.282C531.076 450.083 531.672 450.108 532.068 450.338L535.209 452.156C535.604 452.383 535.645 452.728 535.305 452.925Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M543.474 448.199L540.142 450.127C539.8 450.324 539.203 450.299 538.808 450.072L535.667 448.254C535.272 448.027 535.231 447.681 535.571 447.485L538.905 445.556C539.247 445.36 539.844 445.384 540.239 445.611L543.38 447.429C543.775 447.656 543.817 448.002 543.474 448.199Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M543.06 457.411L539.725 459.339C539.386 459.536 538.789 459.511 538.394 459.284L535.253 457.466C534.858 457.239 534.814 456.893 535.156 456.694L538.49 454.768C538.83 454.569 539.427 454.594 539.822 454.823L542.963 456.639C543.358 456.868 543.402 457.214 543.06 457.411Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M551.231 452.684L547.897 454.613C547.557 454.809 546.96 454.785 546.565 454.558L543.424 452.74C543.029 452.513 542.985 452.167 543.328 451.97L546.662 450.042C547.002 449.845 547.599 449.87 547.994 450.097L551.135 451.915C551.53 452.142 551.574 452.488 551.231 452.684Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M550.814 461.896L547.483 463.825C547.14 464.021 546.543 463.996 546.148 463.77L543.007 461.952C542.612 461.722 542.571 461.379 542.911 461.179L546.245 459.254C546.587 459.054 547.184 459.079 547.579 459.309L550.72 461.124C551.115 461.354 551.157 461.697 550.814 461.896Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M558.986 457.17L555.654 459.099C555.312 459.295 554.715 459.27 554.32 459.043L551.179 457.225C550.784 456.998 550.742 456.652 551.082 456.456L554.416 454.527C554.759 454.331 555.356 454.356 555.748 454.582L558.892 456.4C559.287 456.627 559.328 456.973 558.986 457.17Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M559.022 466.642L555.69 468.571C555.347 468.767 554.751 468.742 554.356 468.515L551.215 466.697C550.82 466.47 550.778 466.124 551.118 465.928L554.452 463.999C554.795 463.803 555.392 463.828 555.787 464.055L558.928 465.873C559.323 466.1 559.364 466.445 559.022 466.642Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M567.193 461.916L563.862 463.844C563.519 464.041 562.922 464.016 562.527 463.789L559.386 461.971C558.991 461.744 558.95 461.398 559.29 461.202L562.624 459.273C562.967 459.077 563.563 459.101 563.956 459.328L567.099 461.146C567.494 461.373 567.536 461.719 567.193 461.916Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M566.779 471.127L563.444 473.056C563.105 473.253 562.508 473.228 562.113 473.001L558.972 471.183C558.577 470.956 558.533 470.61 558.875 470.414L562.21 468.485C562.549 468.288 563.146 468.313 563.541 468.54L566.682 470.358C567.077 470.585 567.121 470.931 566.779 471.127Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M574.95 466.401L571.616 468.33C571.276 468.526 570.68 468.501 570.285 468.275L567.144 466.456C566.749 466.23 566.704 465.884 567.047 465.687L570.378 463.758C570.721 463.562 571.318 463.587 571.713 463.814L574.854 465.632C575.249 465.859 575.293 466.205 574.95 466.401Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M574.533 475.613L571.202 477.542C570.859 477.738 570.262 477.713 569.867 477.486L566.726 475.668C566.331 475.441 566.29 475.096 566.63 474.896L569.964 472.97C570.307 472.771 570.903 472.796 571.298 473.026L574.439 474.841C574.834 475.071 574.876 475.417 574.533 475.613Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M582.705 470.887L579.373 472.815C579.031 473.012 578.434 472.987 578.039 472.76L574.898 470.942C574.503 470.715 574.462 470.369 574.801 470.173L578.136 468.244C578.478 468.048 579.075 468.073 579.467 468.299L582.611 470.117C583.006 470.344 583.047 470.69 582.705 470.887Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M582.741 480.359L579.409 482.287C579.067 482.484 578.47 482.459 578.075 482.232L574.934 480.414C574.539 480.187 574.497 479.841 574.837 479.645L578.172 477.716C578.514 477.52 579.111 477.545 579.506 477.771L582.647 479.59C583.042 479.816 583.083 480.162 582.741 480.359Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M590.912 475.635L587.581 477.561C587.238 477.758 586.642 477.736 586.247 477.506L583.106 475.691C582.711 475.461 582.669 475.115 583.009 474.919L586.343 472.99C586.686 472.793 587.282 472.818 587.675 473.045L590.819 474.863C591.214 475.09 591.255 475.436 590.912 475.635Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M590.498 484.844L587.164 486.773C586.824 486.97 586.227 486.945 585.832 486.718L582.691 484.9C582.296 484.673 582.252 484.327 582.594 484.13L585.926 482.202C586.269 482.005 586.865 482.03 587.26 482.257L590.401 484.075C590.796 484.302 590.841 484.648 590.498 484.844Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M598.67 480.118L595.335 482.047C594.995 482.243 594.399 482.218 594.004 481.991L590.863 480.173C590.468 479.947 590.423 479.601 590.766 479.404L594.098 477.475C594.44 477.279 595.037 477.304 595.432 477.531L598.573 479.349C598.968 479.576 599.009 479.922 598.67 480.118Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M598.253 489.33L594.921 491.259C594.578 491.455 593.982 491.43 593.587 491.203L590.446 489.385C590.051 489.158 590.009 488.812 590.349 488.616L593.683 486.687C594.026 486.491 594.623 486.513 595.015 486.743L598.159 488.561C598.554 488.788 598.595 489.133 598.253 489.33Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M606.424 484.604L603.093 486.532C602.75 486.729 602.153 486.704 601.758 486.477L598.617 484.659C598.222 484.432 598.181 484.086 598.521 483.89L601.855 481.961C602.197 481.765 602.794 481.789 603.186 482.016L606.33 483.834C606.722 484.061 606.767 484.407 606.424 484.604Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M606.46 494.076L603.128 496.004C602.786 496.201 602.189 496.176 601.794 495.949L598.653 494.131C598.258 493.904 598.217 493.558 598.556 493.362L601.891 491.433C602.233 491.237 602.83 491.261 603.222 491.488L606.366 493.306C606.758 493.533 606.803 493.879 606.46 494.076Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M614.632 489.352L611.3 491.278C610.957 491.477 610.361 491.452 609.966 491.223L606.825 489.407C606.43 489.178 606.388 488.832 606.728 488.635L610.062 486.707C610.405 486.51 611.002 486.535 611.394 486.762L614.538 488.58C614.93 488.807 614.974 489.153 614.632 489.352Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M614.217 498.561L610.883 500.49C610.543 500.686 609.946 500.661 609.551 500.435L606.41 498.617C606.015 498.39 605.971 498.044 606.314 497.847L609.645 495.919C609.988 495.722 610.584 495.747 610.979 495.974L614.12 497.792C614.516 498.019 614.56 498.365 614.217 498.561Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M622.389 493.838L619.054 495.764C618.715 495.963 618.115 495.938 617.723 495.708L614.579 493.893C614.187 493.663 614.143 493.317 614.485 493.121L617.817 491.192C618.159 490.996 618.756 491.021 619.151 491.248L622.292 493.066C622.687 493.293 622.729 493.638 622.389 493.838Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M630.56 489.111L627.226 491.037C626.884 491.237 626.287 491.212 625.895 490.982L622.751 489.167C622.359 488.937 622.314 488.594 622.657 488.395L625.988 486.469C626.331 486.269 626.928 486.294 627.323 486.524L630.464 488.339C630.859 488.569 630.9 488.912 630.56 489.111Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M638.732 484.385L635.398 486.314C635.055 486.51 634.458 486.485 634.066 486.258L630.922 484.44C630.53 484.213 630.486 483.868 630.828 483.668L634.16 481.742C634.503 481.543 635.099 481.568 635.494 481.798L638.635 483.613C639.03 483.843 639.072 484.189 638.732 484.385Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M646.904 479.659L643.569 481.587C643.227 481.784 642.63 481.759 642.235 481.532L639.094 479.714C638.699 479.487 638.657 479.141 639 478.945L642.332 477.016C642.674 476.82 643.271 476.844 643.666 477.071L646.807 478.889C647.202 479.116 647.243 479.462 646.904 479.659Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M655.072 474.932L651.741 476.861C651.398 477.06 650.802 477.035 650.407 476.806L647.266 474.99C646.871 474.761 646.829 474.415 647.172 474.218L650.503 472.29C650.846 472.093 651.442 472.118 651.837 472.345L654.978 474.163C655.373 474.39 655.415 474.736 655.072 474.932Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M663.244 470.209L659.912 472.135C659.57 472.334 658.973 472.309 658.578 472.079L655.437 470.264C655.042 470.034 655.001 469.691 655.34 469.492L658.675 467.566C659.017 467.367 659.614 467.392 660.009 467.622L663.15 469.437C663.545 469.666 663.587 470.01 663.244 470.209Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M630.143 498.321L626.812 500.249C626.469 500.446 625.872 500.421 625.477 500.194L622.336 498.376C621.941 498.149 621.9 497.803 622.24 497.607L625.574 495.678C625.914 495.481 626.513 495.506 626.906 495.733L630.05 497.551C630.442 497.778 630.486 498.124 630.143 498.321Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M638.315 493.597L634.983 495.523C634.641 495.722 634.044 495.697 633.649 495.468L630.508 493.652C630.113 493.423 630.072 493.077 630.411 492.88L633.746 490.954C634.085 490.755 634.682 490.78 635.077 491.01L638.221 492.825C638.613 493.055 638.657 493.398 638.315 493.597Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M646.487 488.871L643.155 490.799C642.812 490.996 642.216 490.971 641.821 490.744L638.68 488.926C638.285 488.696 638.24 488.353 638.583 488.154L641.917 486.228C642.257 486.029 642.854 486.054 643.249 486.283L646.39 488.099C646.785 488.328 646.829 488.671 646.487 488.871Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M654.658 484.144L651.324 486.073C650.984 486.27 650.387 486.245 649.992 486.018L646.851 484.2C646.456 483.973 646.412 483.627 646.754 483.43L650.089 481.502C650.429 481.305 651.025 481.33 651.42 481.557L654.561 483.375C654.956 483.602 655.001 483.948 654.658 484.144Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M662.83 479.418L659.495 481.347C659.156 481.543 658.559 481.521 658.164 481.291L655.023 479.473C654.628 479.246 654.583 478.9 654.926 478.704L658.26 476.775C658.6 476.579 659.197 476.604 659.592 476.831L662.733 478.649C663.128 478.876 663.172 479.221 662.83 479.418Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M702.447 508.277L699.113 510.203C698.773 510.402 698.176 510.377 697.781 510.147L694.638 508.332C694.245 508.102 694.201 507.759 694.544 507.56L697.875 505.631C698.218 505.435 698.815 505.46 699.21 505.687L702.35 507.505C702.746 507.734 702.787 508.078 702.447 508.277Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M702.483 517.749L699.149 519.675C698.809 519.874 698.212 519.849 697.817 519.619L694.676 517.804C694.281 517.575 694.237 517.231 694.579 517.032L697.911 515.106C698.254 514.907 698.85 514.932 699.246 515.162L702.387 516.977C702.782 517.207 702.823 517.55 702.483 517.749Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M710.655 513.023L707.32 514.951C706.981 515.148 706.384 515.123 705.989 514.896L702.845 513.078C702.453 512.848 702.408 512.505 702.751 512.306L706.083 510.38C706.425 510.181 707.022 510.206 707.417 510.435L710.558 512.251C710.953 512.48 710.997 512.826 710.655 513.023Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M718.826 508.296L715.492 510.225C715.149 510.421 714.553 510.396 714.16 510.17L711.017 508.352C710.624 508.125 710.58 507.779 710.923 507.582L714.254 505.654C714.597 505.457 715.194 505.482 715.589 505.709L718.73 507.527C719.125 507.754 719.166 508.1 718.826 508.296Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M718.409 517.508L715.078 519.434C714.735 519.633 714.138 519.608 713.743 519.379L710.602 517.563C710.207 517.334 710.166 516.991 710.506 516.791L713.84 514.865C714.18 514.666 714.779 514.691 715.172 514.921L718.315 516.736C718.708 516.966 718.752 517.309 718.409 517.508Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M815.399 485.495C815.366 485.445 815.374 485.378 815.427 485.348C816.195 484.905 827.333 478.14 827.333 478.14C830.698 476.261 835.795 477.415 841.146 482.501C845.271 486.422 848.171 491.516 849.459 497.064C851.185 504.516 849.357 509.547 845.716 511.268L834.038 518.374C833.983 518.405 833.914 518.383 833.889 518.322L832.425 514.655C831.897 514.425 831.364 514.165 830.817 513.85C823.422 509.572 817.424 499.167 817.424 490.611C817.424 489.883 817.482 489.205 817.565 488.552L815.399 485.495Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M817.546 485.868L819.165 488.157L819.087 488.752C819.01 489.344 818.957 489.953 818.957 490.608C818.957 498.664 824.621 508.49 831.585 512.519C832.079 512.807 832.566 513.039 833.046 513.252L833.618 513.504L834.687 516.183L844.92 509.957C848.282 508.357 849.392 503.578 847.964 497.413C846.738 492.117 844.014 487.346 840.088 483.613C835.66 479.399 831.063 477.824 828.079 479.482C828.082 479.482 820.692 483.97 817.546 485.868ZM834.212 520.065L832.784 519.6L832.464 518.894L831.229 515.798C830.839 515.613 830.45 515.408 830.049 515.178C822.242 510.662 815.888 499.64 815.888 490.608C815.888 490.036 815.921 489.49 815.974 488.962L813.756 485.829L814.032 484.407L814.673 484.011C815.427 483.574 826.538 476.828 826.538 476.828C830.839 474.418 836.679 476.136 842.199 481.385C846.561 485.533 849.589 490.833 850.95 496.718C852.727 504.375 850.973 510.482 846.37 512.657L834.212 520.065Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M810.935 494.336C810.935 502.892 816.933 513.296 824.331 517.575C831.726 521.853 837.723 518.385 837.723 509.829C837.723 501.273 831.726 490.869 824.331 486.59C816.933 482.312 810.935 485.78 810.935 494.336Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M818.355 486.289C817.344 486.289 816.424 486.518 815.623 486.981C813.59 488.159 812.471 490.772 812.471 494.339C812.471 502.391 818.134 512.217 825.096 516.244C828.179 518.026 830.997 518.358 833.033 517.184C835.069 516.008 836.187 513.393 836.187 509.829C836.187 501.777 830.524 491.945 823.563 487.919C821.692 486.837 819.913 486.289 818.355 486.289ZM830.314 520.95C828.212 520.95 825.914 520.264 823.563 518.903C815.753 514.39 809.402 503.368 809.402 494.339C809.402 489.626 811.068 486.07 814.093 484.321C817.115 482.572 821.024 482.905 825.096 485.259C832.906 489.775 839.257 500.797 839.257 509.829C839.257 514.539 837.591 518.095 834.566 519.846C833.29 520.582 831.853 520.95 830.314 520.95Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M781.232 521.471C781.221 521.454 779.274 521.332 779.29 521.321C779.536 521.18 820.886 496.998 820.886 496.998C821.958 496.397 823.582 496.765 825.287 498.387C826.602 499.638 827.524 501.259 827.936 503.027C828.486 505.402 827.903 507.004 826.742 507.555L785.175 531.828C785.155 531.839 786.111 532.028 786.103 532.011L785.636 530.843C785.467 530.768 785.299 530.685 785.125 530.586C782.768 529.222 780.857 525.906 780.857 523.181C780.857 522.948 780.876 522.733 780.901 522.525L781.232 521.471Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M770.362 512.004C770.326 511.954 770.337 511.888 770.389 511.86C771.155 511.418 781.196 505.393 781.196 505.393C784.561 503.515 789.658 504.668 795.009 509.755C799.134 513.678 802.034 518.77 803.322 524.321C805.048 531.773 803.217 536.804 799.578 538.525L788.998 544.884C788.943 544.917 788.873 544.892 788.851 544.834L787.387 541.167C786.86 540.935 786.324 540.675 785.779 540.359C778.381 536.081 772.384 525.677 772.384 517.121C772.384 516.393 772.445 515.718 772.528 515.065L770.362 512.004Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M772.511 512.386L774.127 514.669L774.049 515.264C773.972 515.853 773.92 516.465 773.92 517.121C773.92 525.173 779.583 535.002 786.547 539.031C787.039 539.313 787.522 539.551 788.006 539.762L788.578 540.014L789.65 542.7L798.788 537.205C802.145 535.614 803.255 530.835 801.827 524.67C800.6 519.37 797.877 514.603 793.951 510.872C789.523 506.658 784.928 505.072 781.942 506.736C781.945 506.736 775.439 510.637 772.511 512.386ZM789.169 546.575L787.735 546.107L786.191 542.308C785.804 542.125 785.412 541.92 785.017 541.693C777.204 537.174 770.85 526.153 770.85 517.121C770.85 516.548 770.884 516.003 770.936 515.474L768.721 512.381L768.98 510.931L769.635 510.524C770.386 510.087 780.409 504.076 780.409 504.076C784.699 501.674 790.542 503.39 796.062 508.642C800.424 512.787 803.451 518.089 804.813 523.975C806.59 531.629 804.833 537.739 800.233 539.914L789.169 546.575Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M765.897 520.848C765.897 529.404 771.892 539.809 779.29 544.087C786.688 548.365 792.686 544.895 792.686 536.339C792.686 527.783 786.688 517.378 779.29 513.1C771.892 508.822 765.897 512.292 765.897 520.848Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M773.315 512.798C772.304 512.798 771.384 513.028 770.585 513.49C768.552 514.669 767.431 517.281 767.431 520.848C767.431 528.901 773.094 538.732 780.058 542.756C783.141 544.538 785.959 544.873 787.995 543.697C790.031 542.518 791.15 539.906 791.15 536.339C791.15 528.286 785.487 518.457 778.522 514.431C776.649 513.349 774.876 512.798 773.315 512.798ZM785.277 547.463C783.174 547.463 780.876 546.777 778.522 545.415C770.715 540.902 764.361 529.88 764.361 520.848C764.361 516.136 766.027 512.58 769.052 510.831C772.077 509.082 775.983 509.411 780.058 511.772C787.868 516.285 794.219 527.309 794.219 536.339C794.219 541.051 792.553 544.607 789.528 546.356C788.252 547.095 786.815 547.463 785.277 547.463Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M773.279 525.11C773.268 525.093 773.273 525.071 773.29 525.063C773.533 524.921 776.403 523.184 776.403 523.184C777.475 522.583 779.1 522.951 780.804 524.573C782.116 525.824 783.042 527.445 783.451 529.213C784.003 531.588 783.42 533.193 782.26 533.74L779.218 535.586C779.202 535.594 779.18 535.589 779.171 535.57L778.705 534.402C778.536 534.327 778.368 534.244 778.193 534.144C775.837 532.78 773.925 529.465 773.925 526.739C773.925 526.507 773.945 526.291 773.97 526.084L773.279 525.11Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M774.533 525.347L774.895 525.854L774.848 526.2C774.826 526.371 774.809 526.548 774.809 526.739C774.809 529.139 776.561 532.177 778.635 533.375C778.779 533.461 778.923 533.53 779.061 533.588L779.392 533.735L779.619 534.308L781.799 532.982C782.716 532.545 782.995 531.161 782.591 529.412C782.219 527.799 781.387 526.352 780.194 525.217C778.912 523.997 777.619 523.516 776.832 523.958C776.829 523.958 775.456 524.791 774.533 525.347ZM779.304 536.571L778.497 536.27L778.011 535.055C777.926 535.008 777.837 534.961 777.749 534.908C775.108 533.386 773.039 529.794 773.039 526.739C773.039 526.596 773.047 526.457 773.055 526.324L772.044 524.905L772.776 524.34C773.091 524.152 775.942 522.423 775.942 522.423C777.486 521.57 779.522 522.135 781.412 523.933C782.857 525.303 783.862 527.06 784.313 529.011C785.045 532.163 783.926 533.934 782.633 534.543L779.304 536.571Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M771.856 527.927C771.856 530.652 773.768 533.967 776.124 535.332C778.484 536.693 780.393 535.589 780.393 532.863C780.393 530.138 778.484 526.82 776.124 525.458C773.768 524.094 771.856 525.201 771.856 527.927Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M774.218 525.76C773.967 525.76 773.74 525.815 773.55 525.926C773.039 526.222 772.743 526.953 772.743 527.924C772.743 530.323 774.494 533.364 776.566 534.562C777.412 535.049 778.185 535.157 778.702 534.864C779.213 534.565 779.508 533.834 779.508 532.863C779.508 530.464 777.757 527.426 775.682 526.228C775.152 525.92 774.649 525.76 774.218 525.76ZM778.033 536.804C777.293 536.804 776.497 536.568 775.682 536.101C773.041 534.571 770.972 530.982 770.972 527.924C770.972 526.277 771.574 525.021 772.666 524.39C773.754 523.759 775.146 523.867 776.566 524.689C779.207 526.219 781.276 529.808 781.276 532.863C781.276 534.51 780.674 535.766 779.586 536.397C779.116 536.671 778.591 536.804 778.033 536.804Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M797.255 512.596L795.708 509.943L805.791 504.046L807.338 506.7L797.255 512.596Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M803.833 522.71L802.288 520.057L814.609 512.862L816.151 515.516L803.833 522.71Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M773.085 545.36L769.754 547.288C769.411 547.485 768.815 547.46 768.42 547.233L765.278 545.415C764.883 545.185 764.842 544.842 765.182 544.643L768.516 542.717C768.856 542.518 769.455 542.543 769.848 542.772L772.992 544.588C773.384 544.817 773.428 545.16 773.085 545.36Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M813.944 521.731L810.609 523.66C810.269 523.856 809.673 523.831 809.278 523.604L806.134 521.786C805.742 521.559 805.697 521.213 806.04 521.017L809.372 519.088C809.714 518.889 810.311 518.914 810.706 519.143L813.847 520.961C814.242 521.188 814.286 521.534 813.944 521.731Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M772.671 554.572L769.337 556.498C768.997 556.697 768.4 556.672 768.005 556.442L764.861 554.627C764.469 554.397 764.425 554.051 764.768 553.855L768.099 551.926C768.442 551.73 769.038 551.755 769.433 551.982L772.574 553.8C772.969 554.027 773.014 554.372 772.671 554.572Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M780.843 549.845L777.508 551.771C777.166 551.971 776.569 551.946 776.177 551.716L773.033 549.901C772.641 549.671 772.597 549.328 772.939 549.129L776.271 547.203C776.613 547.003 777.21 547.028 777.605 547.258L780.746 549.073C781.141 549.303 781.182 549.646 780.843 549.845Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M813.526 530.943L810.195 532.869C809.852 533.068 809.256 533.043 808.86 532.813L805.719 530.998C805.324 530.768 805.283 530.425 805.623 530.226L808.957 528.3C809.3 528.101 809.896 528.126 810.291 528.355L813.432 530.171C813.828 530.4 813.869 530.744 813.526 530.943Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M821.698 526.216L818.366 528.145C818.024 528.342 817.427 528.317 817.032 528.09L813.891 526.272C813.496 526.045 813.454 525.699 813.794 525.5L817.129 523.574C817.471 523.374 818.068 523.399 818.46 523.629L821.604 525.444C821.996 525.674 822.041 526.02 821.698 526.216Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M780.879 559.317L777.544 561.243C777.204 561.443 776.605 561.418 776.213 561.188L773.069 559.373C772.677 559.143 772.632 558.8 772.975 558.601L776.307 556.675C776.649 556.475 777.246 556.5 777.641 556.73L780.782 558.545C781.177 558.775 781.218 559.118 780.879 559.317Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M789.05 554.591L785.716 556.52C785.373 556.716 784.777 556.691 784.384 556.464L781.24 554.646C780.848 554.419 780.804 554.074 781.146 553.877L784.478 551.948C784.821 551.752 785.417 551.777 785.813 552.004L788.954 553.819C789.349 554.049 789.39 554.395 789.05 554.591Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M797.222 549.865L793.887 551.793C793.545 551.99 792.948 551.965 792.556 551.738L789.412 549.92C789.02 549.693 788.976 549.347 789.318 549.151L792.65 547.222C792.992 547.026 793.589 547.05 793.984 547.277L797.125 549.095C797.52 549.322 797.562 549.668 797.222 549.865Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M805.393 545.141L802.059 547.067C801.716 547.266 801.12 547.241 800.725 547.012L797.584 545.196C797.189 544.967 797.147 544.621 797.49 544.424L800.821 542.496C801.164 542.299 801.761 542.324 802.156 542.551L805.297 544.369C805.692 544.596 805.733 544.942 805.393 545.141Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M813.562 540.415L810.231 542.341C809.888 542.54 809.291 542.515 808.896 542.285L805.755 540.47C805.36 540.24 805.319 539.897 805.661 539.698L808.993 537.772C809.336 537.573 809.932 537.598 810.327 537.827L813.468 539.643C813.863 539.872 813.905 540.216 813.562 540.415Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M829.905 530.962L826.574 532.891C826.231 533.087 825.635 533.062 825.24 532.835L822.099 531.017C821.704 530.791 821.662 530.445 822.002 530.248L825.336 528.319C825.679 528.123 826.275 528.148 826.668 528.375L829.812 530.193C830.204 530.42 830.248 530.766 829.905 530.962Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M838.077 526.239L834.745 528.165C834.403 528.364 833.806 528.339 833.411 528.109L830.27 526.294C829.875 526.064 829.834 525.718 830.173 525.522L833.508 523.593C833.85 523.397 834.447 523.422 834.839 523.648L837.983 525.467C838.375 525.693 838.42 526.039 838.077 526.239Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M796.805 559.077L793.473 561.005C793.13 561.202 792.534 561.177 792.139 560.95L788.998 559.132C788.603 558.905 788.561 558.559 788.901 558.36L792.235 556.434C792.575 556.235 793.172 556.26 793.567 556.489L796.708 558.305C797.103 558.534 797.147 558.88 796.805 559.077Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M804.976 554.35L801.645 556.279C801.302 556.475 800.705 556.451 800.31 556.224L797.169 554.406C796.774 554.179 796.73 553.833 797.073 553.636L800.407 551.708C800.747 551.511 801.344 551.536 801.739 551.763L804.88 553.581C805.275 553.808 805.319 554.154 804.976 554.35Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M813.148 549.624L809.814 551.553C809.474 551.749 808.877 551.727 808.482 551.497L805.341 549.679C804.946 549.452 804.902 549.106 805.244 548.91L808.579 546.981C808.918 546.785 809.515 546.81 809.91 547.037L813.051 548.855C813.446 549.082 813.491 549.427 813.148 549.624Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M837.663 535.448L834.328 537.376C833.989 537.573 833.392 537.548 832.997 537.321L829.853 535.503C829.461 535.276 829.417 534.93 829.759 534.734L833.091 532.805C833.433 532.609 834.03 532.633 834.425 532.86L837.566 534.678C837.961 534.905 838.003 535.251 837.663 535.448Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M129.574 660.814C129.634 660.809 129.687 660.853 129.687 660.914C129.681 661.8 129.888 674.847 129.888 674.847C129.811 678.704 126.242 682.529 119.159 684.587C113.697 686.176 107.841 686.112 102.409 684.424C95.1163 682.155 91.7046 678.032 92.0582 674.017L91.8317 660.33C91.8317 660.269 91.8869 660.22 91.9505 660.231L95.8484 660.814C96.3153 660.474 96.8098 660.145 97.3567 659.832C104.777 655.596 116.772 655.662 124.145 659.982C124.772 660.35 125.325 660.743 125.844 661.144L129.574 660.814Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.3925 661.999L93.5941 673.992C93.2737 677.705 96.8291 681.079 102.865 682.955C108.051 684.568 113.534 684.621 118.728 683.112C124.584 681.413 128.267 678.245 128.352 674.839L128.322 672.816C128.278 670.007 128.203 665.04 128.167 662.48L125.377 662.729L124.902 662.356C124.432 661.993 123.935 661.642 123.366 661.307C116.429 657.242 105.1 657.181 98.1137 661.166C97.6219 661.448 97.1744 661.75 96.7517 662.057L96.2434 662.428L93.3925 661.999ZM111.062 687.277C107.995 687.277 104.932 686.818 101.956 685.891C94.4588 683.561 90.083 678.959 90.5305 673.881L90.2846 659.641L91.3979 658.631L92.177 658.709L95.4699 659.204C95.8235 658.963 96.1965 658.725 96.5943 658.501C104.432 654.024 117.139 654.098 124.919 658.653C125.416 658.947 125.869 659.251 126.294 659.561L130.126 659.234L131.19 660.17L131.223 660.914C131.214 661.589 131.333 669.066 131.388 672.769C131.405 673.817 131.416 674.459 131.394 674.877L131.419 674.88C131.319 679.759 126.897 683.94 119.584 686.062C116.794 686.873 113.929 687.277 111.062 687.277Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.209 652.491C116.835 648.171 104.84 648.105 97.4203 652.341C90.0001 656.578 89.9614 663.515 97.3346 667.835C104.708 672.152 116.7 672.221 124.123 667.984C131.543 663.745 131.582 656.81 124.209 652.491Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M110.65 650.725C106.136 650.725 101.628 651.708 98.18 653.675C95.0859 655.441 93.3759 657.71 93.362 660.065C93.3483 662.42 95.0362 664.705 98.1082 666.507C105.05 670.572 116.38 670.638 123.363 666.651C126.457 664.882 128.167 662.613 128.181 660.258C128.195 657.903 126.507 655.618 123.432 653.816C119.921 651.758 115.283 650.725 110.65 650.725ZM110.899 672.625C105.705 672.625 100.503 671.468 96.5611 669.16C92.5002 666.781 90.2763 663.546 90.2956 660.045C90.315 656.55 92.5748 653.34 96.6633 651.008C104.492 646.536 117.197 646.602 124.982 651.163C129.043 653.542 131.267 656.777 131.248 660.278C131.228 663.773 128.969 666.985 124.883 669.318C121.018 671.526 115.962 672.625 110.899 672.625Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.819 613.109C115.838 613.109 116.929 611.487 116.929 611.506C116.927 611.789 116.893 659.752 116.893 659.752C116.869 660.981 115.73 662.198 113.473 662.854C111.733 663.36 109.868 663.341 108.136 662.801C105.813 662.079 104.727 660.768 104.838 659.486L104.904 611.288C104.904 611.268 104.258 611.999 104.28 612.002L105.52 612.187C105.669 612.079 105.827 611.974 106.001 611.874C108.365 610.524 112.186 610.546 114.537 611.921C114.736 612.038 114.913 612.165 115.078 612.292L115.819 613.109Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M129.466 608.487C129.527 608.482 129.576 608.526 129.576 608.587C129.571 609.472 129.692 621.194 129.692 621.194C129.612 625.052 126.046 628.876 118.963 630.935C113.498 632.523 107.644 632.459 102.213 630.771C94.9202 628.502 91.5084 624.379 91.862 620.364L91.7211 608.003C91.7211 607.942 91.7764 607.892 91.8399 607.903L95.7379 608.487C96.2048 608.147 96.6992 607.818 97.2462 607.505C104.666 603.268 116.661 603.335 124.035 607.654C124.662 608.022 125.214 608.415 125.736 608.817L129.466 608.487Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.2765 609.672L93.398 620.345C93.0776 624.05 96.6329 627.426 102.666 629.302C107.854 630.918 113.341 630.968 118.534 629.46C124.402 627.755 128.087 624.576 128.159 621.161C128.159 621.161 128.082 613.571 128.054 610.156L125.275 610.399L124.8 610.031C124.327 609.669 123.827 609.315 123.261 608.983C116.322 604.915 104.99 604.851 98.0059 608.839C97.5087 609.124 97.0639 609.425 96.6357 609.732L96.1302 610.101L93.2765 609.672ZM110.868 633.624C107.799 633.624 104.735 633.162 101.757 632.238C94.2627 629.905 89.8868 625.303 90.3344 620.226L90.1796 607.306L91.2874 606.304L92.0665 606.384L95.3594 606.877C95.7158 606.636 96.0887 606.398 96.4865 606.171C104.318 601.697 117.023 601.769 124.808 606.329C125.305 606.619 125.756 606.924 126.184 607.237L130.015 606.904L131.082 607.845L131.112 608.587C131.107 609.478 131.225 621.178 131.225 621.178C131.126 626.106 126.7 630.287 119.388 632.409C116.601 633.22 113.733 633.624 110.868 633.624Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.098 600.164C116.725 595.844 104.733 595.778 97.3098 600.014C89.8896 604.251 89.8509 611.188 97.2241 615.505C104.597 619.824 116.592 619.894 124.013 615.657C131.433 611.42 131.471 604.483 124.098 600.164Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M110.54 598.398C106.023 598.398 101.517 599.378 98.0695 601.348C94.9782 603.113 93.2654 605.385 93.2543 607.737C93.2405 610.092 94.9257 612.381 97.9976 614.179C104.94 618.25 116.272 618.305 123.253 614.32C126.347 612.555 128.057 610.286 128.071 607.934C128.084 605.579 126.399 603.288 123.325 601.492C119.811 599.43 115.172 598.398 110.54 598.398ZM110.783 620.298C105.592 620.298 100.39 619.141 96.4506 616.833C92.3897 614.453 90.1658 611.218 90.185 607.721C90.2073 604.223 92.467 601.013 96.5501 598.68C104.387 594.206 117.09 594.278 124.874 598.835C128.933 601.215 131.156 604.45 131.137 607.948C131.118 611.448 128.858 614.658 124.769 616.988C120.905 619.196 115.849 620.298 110.783 620.298Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.7 604.397C116.719 604.395 116.736 604.408 116.736 604.428C116.733 604.71 116.783 608.069 116.783 608.069C116.758 609.298 115.62 610.516 113.363 611.171C111.623 611.678 109.758 611.658 108.026 611.119C105.702 610.397 104.617 609.082 104.727 607.804L104.672 604.242C104.672 604.223 104.691 604.207 104.711 604.209L105.954 604.397C106.1 604.29 106.258 604.184 106.432 604.085C108.799 602.734 112.62 602.757 114.968 604.132C115.17 604.248 115.346 604.373 115.512 604.503L116.7 604.397Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.575 605.236L105.614 607.79C105.528 608.803 106.578 609.741 108.288 610.272C109.868 610.765 111.54 610.779 113.117 610.322C114.81 609.829 115.88 608.96 115.896 608.05C115.896 608.05 115.874 606.442 115.86 605.36L115.244 605.416L114.965 605.203C114.83 605.095 114.686 604.992 114.52 604.896C112.454 603.689 108.951 603.667 106.871 604.857C106.725 604.94 106.595 605.026 106.47 605.114L106.178 605.327L105.575 605.236ZM110.783 612.425C109.769 612.425 108.752 612.273 107.763 611.966C104.686 611.008 103.722 609.149 103.846 607.724L103.782 603.852L104.39 603.293L105.741 603.468C105.824 603.415 105.91 603.365 105.998 603.315C108.647 601.802 112.783 601.827 115.415 603.365C115.54 603.44 115.656 603.512 115.766 603.592L117.584 603.509L117.62 604.428C117.617 604.716 117.667 608.056 117.667 608.056C117.631 609.824 116.114 611.296 113.609 612.024C112.686 612.292 111.736 612.425 110.783 612.425Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M114.99 601.744C112.639 600.368 108.819 600.346 106.454 601.697C104.089 603.047 104.075 605.258 106.426 606.633C108.774 608.009 112.598 608.031 114.962 606.68C117.327 605.33 117.338 603.122 114.99 601.744Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M110.667 601.589C109.291 601.589 107.918 601.882 106.893 602.466C106.048 602.95 105.561 603.565 105.556 604.159C105.553 604.754 106.034 605.374 106.874 605.867C108.937 607.076 112.443 607.095 114.523 605.911C115.369 605.427 115.855 604.813 115.858 604.218C115.863 603.623 115.382 603 114.543 602.51C113.493 601.896 112.078 601.589 110.667 601.589ZM110.747 608.57C109.034 608.57 107.313 608.18 105.981 607.397C104.559 606.567 103.78 605.413 103.788 604.151C103.793 602.887 104.586 601.741 106.017 600.925C108.664 599.417 112.802 599.439 115.438 600.977C116.855 601.813 117.634 602.964 117.628 604.226C117.62 605.488 116.83 606.636 115.399 607.452C114.092 608.197 112.424 608.57 110.747 608.57Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118.438 643.143L115.368 643.138L115.38 631.444L118.449 631.449L118.438 643.143Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.39 646.315L103.321 646.309L103.343 632.022L106.41 632.028L106.39 646.315Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.9507 687.698L95.6191 689.626C95.2765 689.823 94.6798 689.798 94.2848 689.571L91.1438 687.753C90.7487 687.526 90.7073 687.18 91.0499 686.984L94.3815 685.055C94.724 684.859 95.3207 684.883 95.7158 685.11L98.8568 686.928C99.2518 687.155 99.2933 687.501 98.9507 687.698Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.5363 696.91L95.2019 698.838C94.8622 699.035 94.2654 699.01 93.8704 698.783L90.7294 696.965C90.3343 696.738 90.2901 696.392 90.6327 696.193L93.9671 694.267C94.3069 694.068 94.9036 694.093 95.2986 694.322L98.4396 696.137C98.8347 696.367 98.8789 696.713 98.5363 696.91Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.708 692.183L103.374 694.112C103.034 694.308 102.437 694.284 102.042 694.057L98.901 692.239C98.506 692.012 98.4618 691.666 98.8043 691.469L102.139 689.541C102.478 689.344 103.075 689.369 103.47 689.596L106.611 691.414C107.006 691.641 107.05 691.987 106.708 692.183Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.744 701.655L103.409 703.584C103.07 703.781 102.473 703.756 102.078 703.529L98.9369 701.711C98.5419 701.484 98.4977 701.138 98.8402 700.941L102.172 699.013C102.514 698.816 103.111 698.841 103.506 699.068L106.647 700.886C107.042 701.113 107.086 701.459 106.744 701.655Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M114.915 696.932L111.581 698.858C111.241 699.057 110.645 699.032 110.25 698.802L107.109 696.987C106.713 696.757 106.669 696.412 107.012 696.215L110.346 694.286C110.686 694.09 111.283 694.115 111.678 694.342L114.819 696.16C115.214 696.387 115.258 696.733 114.915 696.932Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M123.087 692.205L119.753 694.131C119.413 694.331 118.816 694.306 118.421 694.076L115.28 692.261C114.885 692.031 114.841 691.688 115.183 691.489L118.515 689.563C118.858 689.363 119.454 689.388 119.849 689.618L122.99 691.433C123.385 691.663 123.427 692.006 123.087 692.205Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M131.259 687.479L127.924 689.408C127.582 689.604 126.988 689.579 126.593 689.353L123.449 687.534C123.057 687.308 123.012 686.962 123.355 686.762L126.687 684.836C127.029 684.637 127.626 684.662 128.021 684.892L131.162 686.707C131.557 686.937 131.598 687.283 131.259 687.479Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.67 701.415L119.338 703.343C118.996 703.54 118.399 703.518 118.004 703.288L114.863 701.47C114.468 701.243 114.426 700.897 114.766 700.701L118.101 698.772C118.443 698.575 119.04 698.6 119.432 698.827L122.576 700.645C122.971 700.872 123.012 701.218 122.67 701.415Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M130.842 696.691L127.51 698.617C127.167 698.816 126.571 698.791 126.176 698.562L123.035 696.746C122.64 696.517 122.598 696.174 122.938 695.974L126.272 694.046C126.615 693.849 127.212 693.874 127.604 694.101L130.748 695.919C131.14 696.149 131.184 696.492 130.842 696.691Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M789.257 608.778C789.318 608.772 789.371 608.814 789.368 608.875C789.362 609.763 789.484 621.485 789.484 621.485C789.404 625.342 785.837 629.166 778.754 631.225C773.29 632.811 767.436 632.75 762.005 631.059C754.712 628.79 751.3 624.67 751.654 620.652L751.513 608.294C751.513 608.23 751.571 608.183 751.631 608.191L755.529 608.778C755.996 608.437 756.491 608.108 757.038 607.795C764.458 603.559 776.453 603.625 783.826 607.945C784.453 608.313 785.006 608.703 785.528 609.107L789.257 608.778Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M753.068 609.962L753.19 620.635C752.869 624.34 756.425 627.714 762.458 629.593C767.649 631.206 773.132 631.258 778.326 629.75C784.194 628.046 787.879 624.863 787.951 621.452C787.951 621.452 787.873 613.861 787.846 610.444L785.067 610.69L784.592 610.322C784.119 609.959 783.619 609.605 783.053 609.273C776.113 605.205 764.781 605.142 757.798 609.129C757.3 609.412 756.856 609.716 756.43 610.023L755.922 610.391L753.068 609.962ZM770.66 633.915C767.594 633.915 764.527 633.453 761.552 632.526C754.057 630.193 749.678 625.594 750.126 620.516L749.974 607.596L751.079 606.592L751.858 606.672L755.151 607.167C755.507 606.924 755.88 606.686 756.278 606.462C764.11 601.984 776.815 602.056 784.603 606.617C785.097 606.91 785.547 607.214 785.978 607.524L789.807 607.195L790.874 608.133L790.904 608.875C790.898 609.768 791.017 621.468 791.017 621.468C790.918 626.394 786.492 630.578 779.18 632.7C776.392 633.511 773.525 633.915 770.66 633.915Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M783.89 600.454C776.517 596.135 764.524 596.065 757.101 600.305C749.681 604.541 749.643 611.476 757.016 615.795C764.389 620.115 776.384 620.181 783.804 615.945C791.227 611.708 791.263 604.774 783.89 600.454Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M770.331 598.689C765.814 598.689 761.309 599.668 757.861 601.636C754.77 603.404 753.057 605.673 753.046 608.028C753.032 610.383 754.717 612.668 757.789 614.47C764.731 618.538 776.063 618.596 783.044 614.611C786.138 612.846 787.851 610.577 787.862 608.222C787.876 605.867 786.191 603.578 783.116 601.78C779.602 599.718 774.964 598.689 770.331 598.689ZM770.577 620.588C765.386 620.588 760.182 619.431 756.242 617.124C752.181 614.744 749.96 611.509 749.977 608.009C749.999 604.514 752.259 601.304 756.342 598.968C764.179 594.494 776.881 594.568 784.666 599.126C788.724 601.503 790.948 604.741 790.929 608.238C790.909 611.736 788.65 614.946 784.561 617.279C780.696 619.487 775.641 620.588 770.577 620.588Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M776.492 604.688C776.511 604.685 776.528 604.699 776.528 604.718C776.525 605.001 776.575 608.357 776.575 608.357C776.55 609.589 775.412 610.806 773.155 611.462C771.414 611.968 769.55 611.946 767.82 611.409C765.494 610.687 764.408 609.373 764.519 608.094L764.464 604.533C764.464 604.511 764.483 604.497 764.502 604.5L765.746 604.685C765.895 604.577 766.052 604.472 766.226 604.373C768.591 603.025 772.411 603.044 774.76 604.422C774.961 604.539 775.138 604.663 775.304 604.79L776.492 604.688Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M765.367 605.526L765.406 608.078C765.32 609.093 766.37 610.031 768.08 610.563C769.663 611.053 771.331 611.066 772.909 610.61C774.605 610.12 775.671 609.248 775.688 608.341C775.688 608.341 775.666 606.73 775.652 605.651L775.036 605.706L774.757 605.49C774.621 605.385 774.478 605.283 774.312 605.183C772.246 603.977 768.746 603.957 766.663 605.145C766.516 605.228 766.386 605.313 766.262 605.405L765.969 605.615L765.367 605.526ZM770.577 612.716C769.561 612.716 768.544 612.563 767.555 612.256C764.477 611.299 763.513 609.439 763.638 608.014L763.574 604.14L764.182 603.584L765.533 603.758C765.616 603.706 765.701 603.656 765.79 603.603C768.439 602.092 772.574 602.115 775.207 603.656C775.331 603.728 775.447 603.803 775.558 603.88L777.376 603.8L777.412 604.718C777.409 605.003 777.459 608.343 777.459 608.343C777.423 610.114 775.906 611.587 773.4 612.314C772.478 612.583 771.527 612.716 770.577 612.716Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M774.782 602.034C772.431 600.659 768.61 600.637 766.245 601.987C763.881 603.338 763.87 605.546 766.218 606.924C768.569 608.299 772.389 608.321 774.754 606.971C777.119 605.621 777.13 603.41 774.782 602.034Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M770.458 601.877C769.083 601.877 767.71 602.17 766.685 602.756C765.839 603.238 765.353 603.855 765.348 604.447C765.345 605.045 765.826 605.665 766.665 606.157C768.729 607.367 772.235 607.386 774.315 606.199C775.16 605.717 775.646 605.1 775.649 604.505C775.655 603.913 775.174 603.291 774.334 602.801C773.287 602.186 771.87 601.877 770.458 601.877ZM770.538 608.861C768.826 608.861 767.107 608.468 765.773 607.688C764.35 606.855 763.571 605.701 763.579 604.439C763.585 603.177 764.378 602.031 765.809 601.215C768.455 599.704 772.594 599.729 775.229 601.268C776.646 602.101 777.425 603.252 777.42 604.516C777.412 605.778 776.621 606.924 775.19 607.743C773.884 608.487 772.215 608.861 770.538 608.861Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M758.483 638.558L755.151 640.487C754.809 640.683 754.212 640.659 753.817 640.432L750.676 638.614C750.281 638.387 750.239 638.041 750.579 637.844L753.914 635.916C754.256 635.719 754.853 635.744 755.245 635.971L758.389 637.789C758.784 638.016 758.825 638.362 758.483 638.558Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M758.068 647.77L754.734 649.696C754.394 649.895 753.797 649.87 753.402 649.641L750.261 647.826C749.866 647.596 749.822 647.253 750.165 647.053L753.499 645.127C753.839 644.928 754.436 644.953 754.831 645.183L757.972 646.998C758.367 647.228 758.411 647.571 758.068 647.77Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M766.24 643.044L762.906 644.973C762.566 645.169 761.969 645.144 761.574 644.917L758.433 643.099C758.038 642.872 757.994 642.526 758.336 642.327L761.668 640.401C762.011 640.202 762.607 640.227 763.002 640.456L766.143 642.272C766.538 642.501 766.583 642.847 766.24 643.044Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M766.276 652.516L762.942 654.445C762.602 654.641 762.005 654.616 761.61 654.389L758.469 652.571C758.074 652.344 758.03 651.998 758.372 651.802L761.704 649.873C762.046 649.674 762.643 649.699 763.038 649.928L766.179 651.746C766.574 651.973 766.618 652.319 766.276 652.516Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M774.447 647.789L771.113 649.718C770.773 649.915 770.177 649.89 769.781 649.663L766.641 647.845C766.246 647.618 766.201 647.272 766.544 647.076L769.875 645.147C770.218 644.95 770.815 644.975 771.21 645.202L774.351 647.02C774.746 647.247 774.79 647.593 774.447 647.789Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M782.619 643.066L779.285 644.992C778.945 645.191 778.348 645.166 777.953 644.936L774.809 643.121C774.417 642.892 774.373 642.546 774.715 642.349L778.047 640.421C778.39 640.224 778.986 640.249 779.381 640.476L782.522 642.294C782.917 642.521 782.959 642.867 782.619 643.066Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M790.791 638.34L787.456 640.266C787.114 640.465 786.517 640.44 786.125 640.21L782.981 638.395C782.589 638.165 782.544 637.822 782.887 637.623L786.219 635.697C786.561 635.498 787.158 635.523 787.553 635.752L790.694 637.568C791.089 637.797 791.13 638.14 790.791 638.34Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M782.202 652.275L778.87 654.204C778.528 654.4 777.931 654.375 777.536 654.148L774.395 652.33C774 652.103 773.958 651.758 774.298 651.561L777.633 649.632C777.975 649.436 778.572 649.461 778.964 649.688L782.108 651.506C782.503 651.733 782.544 652.079 782.202 652.275Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M790.373 647.549L787.042 649.477C786.699 649.674 786.103 649.652 785.708 649.422L782.567 647.604C782.171 647.377 782.13 647.031 782.47 646.835L785.804 644.906C786.147 644.71 786.743 644.734 787.136 644.961L790.28 646.779C790.672 647.006 790.716 647.352 790.373 647.549Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M360.848 753.236C360.726 753.227 360.624 753.31 360.624 753.432C360.646 755.198 360.566 762.215 360.566 762.215C360.765 769.897 367.909 777.471 382.034 781.494C392.927 784.593 404.582 784.405 415.375 780.985C429.873 776.389 436.622 768.148 435.873 760.156L435.986 751.869C435.984 751.741 435.873 751.647 435.749 751.667L427.992 752.873C427.061 752.203 426.074 751.55 424.98 750.933C410.162 742.579 386.285 742.842 371.65 751.52C370.406 752.259 369.31 753.045 368.276 753.85L360.848 753.236Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M362.163 754.887C362.152 757.577 362.099 762.234 362.099 762.234C362.296 769.775 369.904 776.444 382.454 780.016C393.101 783.043 404.325 782.872 414.911 779.518C427.632 775.489 435.078 768.123 434.345 760.297L434.434 753.426L427.608 754.489L427.099 754.121C426.08 753.388 425.171 752.801 424.229 752.272C409.858 744.176 386.62 744.425 372.431 752.843C371.39 753.46 370.37 754.165 369.218 755.062L368.746 755.43L362.163 754.887ZM397.852 785.241C392.393 785.241 386.937 784.485 381.617 782.969C367.707 779.012 359.265 771.269 359.033 762.256C359.033 762.198 359.113 755.206 359.091 753.451L359.083 752.771L359.638 752.167C359.992 751.838 360.483 751.68 360.964 751.702L360.972 751.705L367.812 752.272C368.879 751.462 369.859 750.795 370.87 750.197C385.901 741.287 410.513 741.016 425.732 749.597C426.597 750.081 427.464 750.629 428.37 751.26L436.213 750.039L436.917 750.551C437.299 750.881 437.52 751.362 437.52 751.869L437.406 760.176C438.277 769.36 430.014 777.957 415.839 782.451C409.969 784.311 403.911 785.241 397.852 785.241Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M371.442 736.605C386.076 727.927 409.952 727.664 424.773 736.021C439.592 744.375 439.743 758.183 425.11 766.861C410.477 775.542 386.6 775.802 371.779 767.448C356.958 759.094 356.809 745.283 371.442 736.605Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M372.224 737.928C365.638 741.832 362.041 746.877 362.099 752.131C362.157 757.383 365.862 762.348 372.533 766.108C386.901 774.208 410.134 773.959 424.328 765.538C430.914 761.634 434.511 756.589 434.454 751.337C434.395 746.082 430.691 741.118 424.022 737.36C409.648 729.261 386.413 729.51 372.224 737.928ZM397.786 775.052C388.1 775.052 378.448 772.971 371.028 768.787C363.362 764.465 359.102 758.56 359.033 752.165C358.964 745.767 363.094 739.773 370.663 735.282C385.691 726.369 410.306 726.103 425.525 734.682C433.191 739.004 437.451 744.906 437.52 751.301C437.591 757.699 433.461 763.695 425.892 768.184C418.187 772.752 407.969 775.052 397.786 775.052Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M378.76 741.002C389.404 735.121 406.767 734.944 417.546 740.603C428.323 746.265 428.434 755.623 417.792 761.504C407.148 767.384 389.786 767.561 379.006 761.899C368.23 756.238 368.119 746.882 378.76 741.002Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M379.329 742.034C374.586 744.657 371.995 748.022 372.03 751.512C372.069 755.006 374.741 758.324 379.556 760.853C390.004 766.344 406.902 766.172 417.22 760.469C421.966 757.848 424.558 754.483 424.519 750.991C424.483 747.499 421.812 744.179 416.997 741.649C406.549 736.165 389.653 736.334 379.329 742.034ZM397.921 767.193C390.877 767.193 383.857 765.782 378.459 762.946C372.84 759.993 369.718 755.942 369.671 751.536C369.625 747.139 372.65 743.027 378.191 739.967C389.123 733.926 407.021 733.744 418.093 739.56C423.715 742.51 426.834 746.564 426.881 750.969C426.925 755.369 423.9 759.475 418.359 762.536C412.759 765.635 405.325 767.193 397.921 767.193Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M417.546 746.998C406.767 741.337 389.404 741.514 378.76 747.394C374.989 749.478 372.597 752.001 371.522 754.638C372.625 757.317 375.106 759.852 379.006 761.9C389.786 767.561 407.148 767.384 417.792 761.504C421.563 759.417 423.956 756.896 425.03 754.257C423.928 751.581 421.447 749.046 417.546 746.998Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M372.813 754.636C373.94 756.982 376.26 759.124 379.556 760.853C390.004 766.341 406.902 766.169 417.22 760.469C420.397 758.712 422.641 756.573 423.74 754.259C422.613 751.916 420.292 749.774 416.997 748.044V748.042C406.549 742.557 389.653 742.729 379.329 748.429C376.155 750.183 373.912 752.322 372.813 754.636ZM397.921 767.193C390.877 767.193 383.857 765.782 378.459 762.946C374.459 760.845 371.683 758.128 370.431 755.089L370.246 754.641L370.428 754.19C371.65 751.199 374.335 748.49 378.191 746.359C389.123 740.321 407.021 740.136 418.093 745.952C422.094 748.05 424.867 750.767 426.119 753.808L426.304 754.257L426.121 754.705C424.906 757.693 422.221 760.405 418.359 762.536C412.759 765.635 405.325 767.193 397.921 767.193Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M393.283 752.956C396.021 751.351 400.477 751.323 403.234 752.895C405.993 754.467 406.007 757.043 403.27 758.651C400.532 760.256 396.076 760.283 393.319 758.712C390.559 757.14 390.543 754.564 393.283 752.956Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M393.877 753.977C392.946 754.525 392.415 755.203 392.418 755.839C392.421 756.479 392.962 757.151 393.902 757.685C396.289 759.044 400.308 759.022 402.673 757.63C403.607 757.082 404.137 756.404 404.134 755.765C404.131 755.128 403.59 754.456 402.654 753.922C400.267 752.563 396.25 752.585 393.877 753.977ZM398.225 761.061C396.25 761.061 394.277 760.618 392.736 759.738C391.018 758.761 390.07 757.383 390.059 755.856C390.048 754.329 390.982 752.937 392.683 751.938C395.797 750.114 400.687 750.084 403.819 751.869C405.535 752.845 406.485 754.223 406.494 755.751C406.502 757.278 405.568 758.67 403.866 759.669C402.292 760.596 400.256 761.061 398.225 761.061Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M362.185 778.359L358.851 780.287C358.511 780.484 357.914 780.459 357.519 780.232L354.378 778.414C353.983 778.187 353.939 777.841 354.281 777.642L357.613 775.716C357.956 775.519 358.552 775.542 358.947 775.771L362.088 777.587C362.483 777.816 362.528 778.162 362.185 778.359Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M362.221 787.831L358.887 789.759C358.547 789.956 357.95 789.931 357.555 789.704L354.414 787.886C354.019 787.659 353.975 787.313 354.317 787.117L357.652 785.188C357.991 784.992 358.588 785.016 358.983 785.243L362.124 787.061C362.519 787.288 362.563 787.634 362.221 787.831Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M370.393 783.104L367.058 785.033C366.718 785.23 366.122 785.205 365.727 784.978L362.586 783.16C362.191 782.933 362.146 782.587 362.489 782.39L365.821 780.462C366.163 780.265 366.76 780.29 367.155 780.517L370.296 782.335C370.691 782.562 370.735 782.908 370.393 783.104Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M369.975 792.316L366.644 794.245C366.301 794.441 365.705 794.417 365.31 794.19L362.168 792.372C361.773 792.145 361.732 791.799 362.075 791.6L365.406 789.674C365.749 789.474 366.345 789.499 366.74 789.729L369.882 791.547C370.277 791.774 370.318 792.12 369.975 792.316Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M378.147 787.59L374.815 789.519C374.473 789.715 373.876 789.69 373.481 789.463L370.34 787.645C369.945 787.418 369.904 787.073 370.243 786.876L373.578 784.947C373.92 784.751 374.517 784.776 374.912 785.003L378.053 786.821C378.448 787.048 378.49 787.393 378.147 787.59Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M377.733 796.802L374.398 798.731C374.059 798.927 373.462 798.902 373.067 798.675L369.926 796.857C369.531 796.63 369.486 796.284 369.829 796.085L373.161 794.159C373.503 793.96 374.1 793.985 374.495 794.214L377.636 796.03C378.031 796.259 378.075 796.605 377.733 796.802Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M385.904 792.075L382.57 794.004C382.23 794.201 381.633 794.176 381.238 793.949L378.094 792.131C377.702 791.904 377.658 791.558 378.001 791.362L381.332 789.433C381.675 789.236 382.271 789.261 382.666 789.488L385.807 791.306C386.203 791.533 386.247 791.879 385.904 792.075Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M385.94 801.548L382.606 803.476C382.266 803.673 381.669 803.648 381.274 803.421L378.133 801.603C377.738 801.376 377.694 801.03 378.037 800.834L381.368 798.905C381.711 798.708 382.307 798.733 382.702 798.96L385.843 800.778C386.238 801.005 386.283 801.351 385.94 801.548Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M394.112 796.821L390.777 798.75C390.438 798.947 389.841 798.924 389.446 798.695L386.305 796.877C385.91 796.65 385.866 796.304 386.208 796.107L389.54 794.179C389.882 793.982 390.479 794.007 390.874 794.234L394.015 796.052C394.41 796.279 394.454 796.625 394.112 796.821Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M402.283 792.098L398.949 794.024C398.606 794.223 398.012 794.198 397.617 793.968L394.474 792.153C394.081 791.923 394.037 791.58 394.38 791.381L397.711 789.452C398.054 789.256 398.651 789.281 399.046 789.51L402.187 791.326C402.582 791.555 402.623 791.898 402.283 792.098Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M401.866 801.307L398.535 803.236C398.192 803.432 397.595 803.407 397.2 803.18L394.059 801.362C393.664 801.135 393.623 800.789 393.963 800.593L397.297 798.664C397.639 798.468 398.236 798.493 398.628 798.72L401.772 800.538C402.165 800.764 402.209 801.11 401.866 801.307Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M410.038 796.583L406.706 798.509C406.364 798.708 405.767 798.684 405.372 798.454L402.231 796.639C401.836 796.409 401.794 796.063 402.134 795.866L405.469 793.938C405.811 793.741 406.408 793.766 406.8 793.993L409.944 795.811C410.336 796.038 410.38 796.384 410.038 796.583Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M418.209 791.857L414.878 793.783C414.535 793.982 413.938 793.957 413.543 793.728L410.402 791.912C410.007 791.683 409.966 791.339 410.306 791.14L413.64 789.214C413.98 789.015 414.577 789.04 414.972 789.27L418.115 791.085C418.508 791.315 418.552 791.658 418.209 791.857Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M426.381 787.131L423.049 789.059C422.707 789.256 422.11 789.231 421.715 789.004L418.574 787.186C418.179 786.959 418.135 786.613 418.477 786.417L421.812 784.488C422.152 784.289 422.748 784.314 423.143 784.543L426.284 786.361C426.679 786.588 426.724 786.934 426.381 787.131Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M434.553 782.404L431.218 784.333C430.878 784.529 430.282 784.505 429.887 784.278L426.746 782.46C426.351 782.233 426.306 781.887 426.649 781.69L429.983 779.762C430.323 779.565 430.92 779.59 431.315 779.817L434.456 781.635C434.851 781.862 434.895 782.208 434.553 782.404Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M442.724 777.681L439.39 779.607C439.05 779.806 438.453 779.781 438.058 779.551L434.917 777.736C434.522 777.506 434.478 777.16 434.821 776.964L438.152 775.035C438.495 774.839 439.091 774.864 439.486 775.091L442.627 776.909C443.023 777.136 443.067 777.481 442.724 777.681Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M417.795 801.066L414.461 802.995C414.121 803.194 413.521 803.169 413.129 802.939L409.985 801.124C409.593 800.894 409.549 800.549 409.891 800.352L413.223 798.423C413.566 798.227 414.162 798.252 414.557 798.479L417.698 800.297C418.093 800.524 418.135 800.87 417.795 801.066Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M425.967 796.343L422.632 798.269C422.29 798.468 421.693 798.443 421.301 798.213L418.157 796.398C417.765 796.168 417.72 795.825 418.063 795.626L421.395 793.7C421.737 793.501 422.334 793.526 422.729 793.755L425.87 795.57C426.265 795.8 426.306 796.143 425.967 796.343Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M434.138 791.616L430.804 793.545C430.461 793.741 429.865 793.716 429.472 793.49L426.329 791.672C425.934 791.445 425.892 791.099 426.235 790.9L429.566 788.974C429.909 788.774 430.506 788.799 430.901 789.029L434.042 790.844C434.437 791.074 434.478 791.42 434.138 791.616Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M442.31 786.89L438.975 788.819C438.633 789.015 438.036 788.99 437.641 788.763L434.5 786.945C434.105 786.718 434.064 786.372 434.406 786.176L437.738 784.247C438.08 784.051 438.677 784.076 439.072 784.303L442.213 786.121C442.608 786.348 442.65 786.693 442.31 786.89Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M362.892 325.834C362.771 325.826 362.668 325.909 362.671 326.031C362.691 327.793 362.61 334.814 362.61 334.814C362.809 342.495 369.953 350.069 384.078 354.09C394.971 357.192 406.626 357.004 417.419 353.583C431.917 348.987 438.666 340.746 437.917 332.755L438.031 324.467C438.028 324.34 437.917 324.246 437.793 324.265L430.039 325.472C429.105 324.802 428.119 324.149 427.027 323.532C412.206 315.178 388.33 315.441 373.697 324.118C372.451 324.857 371.354 325.643 370.323 326.448L362.892 325.834Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M364.205 327.483C364.199 330.187 364.146 334.83 364.146 334.83C364.34 342.371 371.951 349.04 384.498 352.612C395.148 355.645 406.369 355.473 416.955 352.12C429.677 348.088 437.125 340.722 436.39 332.896L436.478 326.025L429.655 327.085L429.144 326.72C428.144 326 427.207 325.397 426.273 324.871C411.903 316.766 388.664 317.026 374.478 325.438C373.431 326.058 372.409 326.764 371.268 327.658L370.796 328.029L364.205 327.483ZM399.899 357.839C394.441 357.839 388.982 357.084 383.658 355.568C369.752 351.61 361.312 343.865 361.077 334.855C361.077 334.794 361.157 327.804 361.135 326.05L361.13 325.375L361.677 324.772C362.033 324.437 362.522 324.271 363.008 324.304H363.019L369.857 324.868C370.923 324.06 371.904 323.394 372.918 322.796C387.943 313.883 412.555 313.609 427.779 322.193C428.646 322.682 429.514 323.23 430.417 323.858L438.246 322.641L438.942 323.131C439.329 323.457 439.553 323.933 439.564 324.44L439.451 332.774C440.318 341.959 432.058 350.553 417.881 355.047C412.01 356.91 405.955 357.839 399.899 357.839Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M373.487 309.203C388.12 300.526 411.996 300.263 426.817 308.62C441.638 316.974 441.788 330.782 427.154 339.46C412.521 348.138 388.645 348.4 373.824 340.046C359.005 331.69 358.853 317.881 373.487 309.203Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M400.803 304.043C391.186 304.043 381.539 306.212 374.268 310.526C367.683 314.431 364.086 319.475 364.143 324.727C364.202 329.982 367.906 334.946 374.575 338.707C388.946 346.809 412.179 346.555 426.376 338.137C432.961 334.233 436.556 329.188 436.498 323.936C436.442 318.684 432.735 313.717 426.066 309.956C419.06 306.007 409.944 304.043 400.803 304.043ZM399.833 347.648C390.148 347.648 380.492 345.57 373.075 341.383C365.409 337.063 361.149 331.161 361.077 324.763C361.008 318.366 365.135 312.372 372.705 307.883C387.733 298.965 412.345 298.694 427.569 307.28C435.235 311.6 439.495 317.505 439.567 323.903C439.636 330.3 435.506 336.294 427.936 340.782C420.234 345.351 410.016 347.648 399.833 347.648Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M380.807 313.6C391.449 307.72 408.811 307.54 419.591 313.202C430.367 318.864 430.478 328.219 419.837 334.102C409.192 339.983 391.83 340.16 381.05 334.498C370.274 328.837 370.163 319.481 380.807 313.6Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M400.67 310.244C393.678 310.244 386.667 311.711 381.376 314.635C376.63 317.256 374.039 320.621 374.075 324.11C374.114 327.605 376.782 330.923 381.6 333.452C392.048 338.942 408.944 338.768 419.267 333.068C424.011 330.444 426.602 327.079 426.566 323.593C426.53 320.098 423.856 316.78 419.041 314.248C413.947 311.572 407.319 310.244 400.67 310.244ZM399.966 339.789C392.918 339.789 385.901 338.381 380.503 335.544C374.882 332.592 371.763 328.54 371.715 324.135C371.672 319.735 374.697 315.626 380.238 312.566C391.167 306.525 409.065 306.337 420.138 312.156C425.757 315.109 428.878 319.16 428.926 323.568C428.972 327.965 425.947 332.074 420.406 335.137C414.803 338.231 407.369 339.789 399.966 339.789Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M419.591 319.594C408.811 313.933 391.449 314.112 380.807 319.993C377.034 322.076 374.641 324.6 373.567 327.237C374.669 329.913 377.152 332.45 381.05 334.498C391.83 340.16 409.192 339.983 419.837 334.102C423.607 332.016 426 329.495 427.074 326.855C425.972 324.179 423.491 321.645 419.591 319.594Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M374.857 327.234C375.984 329.578 378.302 331.72 381.6 333.452C392.048 338.942 408.944 338.768 419.267 333.068C422.444 331.31 424.685 329.171 425.784 326.858C424.657 324.514 422.337 322.372 419.041 320.643C408.593 315.15 391.694 315.324 381.376 321.028C378.197 322.782 375.956 324.921 374.857 327.234ZM399.966 339.789C392.918 339.789 385.901 338.381 380.503 335.544C376.501 333.441 373.727 330.724 372.478 327.688L372.293 327.24L372.475 326.792C373.694 323.798 376.376 321.091 380.238 318.955C391.17 312.917 409.068 312.734 420.138 318.548C424.141 320.651 426.917 323.366 428.166 326.404L428.351 326.852L428.168 327.301C426.947 330.295 424.268 333.004 420.406 335.137C414.803 338.231 407.369 339.789 399.966 339.789Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M395.327 325.555C398.065 323.95 402.521 323.922 405.278 325.494C408.038 327.066 408.052 329.642 405.314 331.25C402.576 332.854 398.12 332.882 395.363 331.31C392.603 329.739 392.59 327.162 395.327 325.555Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M400.372 325.516C398.75 325.516 397.123 325.87 395.924 326.576C394.99 327.121 394.46 327.802 394.463 328.438C394.468 329.075 395.01 329.75 395.946 330.284C398.33 331.642 402.352 331.62 404.717 330.228C405.648 329.683 406.181 329.003 406.176 328.366C406.176 327.727 405.634 327.054 404.695 326.52C403.518 325.848 401.946 325.516 400.372 325.516ZM400.269 333.657C398.294 333.657 396.325 333.217 394.78 332.337C393.065 331.36 392.112 329.979 392.103 328.452C392.095 326.924 393.029 325.535 394.731 324.536C397.838 322.716 402.728 322.68 405.861 324.464C407.579 325.444 408.527 326.825 408.535 328.352C408.546 329.877 407.615 331.269 405.911 332.268C404.336 333.192 402.303 333.657 400.269 333.657Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M318.501 315.836C318.382 315.859 318.305 315.966 318.338 316.083C318.824 317.779 320.603 324.57 320.603 324.57C322.822 331.922 331.714 337.335 346.397 337.467C357.721 337.57 368.909 334.299 378.415 328.139C391.183 319.863 395.512 310.125 392.678 302.618L390.598 294.596C390.562 294.474 390.429 294.413 390.316 294.463L383.155 297.684C382.078 297.285 380.954 296.917 379.738 296.613C363.24 292.484 340.284 299.067 328.465 311.315C327.46 312.358 326.609 313.407 325.827 314.458L318.501 315.836Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M320.203 317.079C320.904 319.672 322.087 324.179 322.087 324.179C324.269 331.399 333.366 335.813 346.414 335.932C357.566 336.001 368.263 332.89 377.581 326.847C388.783 319.589 394.018 310.512 391.242 303.16L389.512 296.51L383.211 299.341L382.622 299.123C381.47 298.696 380.404 298.362 379.365 298.102C363.37 294.1 341.03 300.506 329.568 312.383C328.722 313.26 327.924 314.212 327.059 315.374L326.7 315.856L320.203 317.079ZM346.922 339.006C346.742 339.006 346.566 339.006 346.386 339.003C331.93 338.873 321.741 333.643 319.134 325.015C319.12 324.96 317.346 318.197 316.863 316.509L316.675 315.85L317.059 315.114C317.316 314.702 317.741 314.414 318.222 314.325L324.963 313.058C325.772 311.998 326.546 311.096 327.36 310.249C339.502 297.667 363.166 290.888 380.111 295.121C381.075 295.362 382.059 295.661 383.092 296.029L390.327 292.775L391.131 293.071C391.59 293.284 391.932 293.685 392.07 294.172L394.162 302.23C397.427 310.855 391.73 321.337 379.249 329.429C369.575 335.699 358.409 339.006 346.922 339.006Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M324.322 296.989C336.14 284.742 359.097 278.158 375.597 282.284C392.095 286.413 395.891 299.69 384.073 311.937C372.255 324.188 349.298 330.771 332.797 326.642C316.299 322.514 312.503 309.237 324.322 296.989Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M364.898 282.583C350.646 282.583 334.573 288.577 325.427 298.057C320.106 303.569 317.971 309.386 319.415 314.436C320.857 319.489 325.744 323.294 333.17 325.151C349.179 329.16 371.509 322.746 382.968 310.869C388.288 305.357 390.424 299.54 388.979 294.49C387.537 289.437 382.653 285.633 375.227 283.776C371.995 282.968 368.506 282.583 364.898 282.583ZM343.364 329.396C339.543 329.396 335.85 328.989 332.427 328.134C323.893 325.997 318.225 321.434 316.468 315.283C314.708 309.131 317.106 302.255 323.219 295.921C335.361 283.341 359.025 276.551 375.967 280.793C384.501 282.929 390.17 287.495 391.929 293.644C393.686 299.795 391.288 306.671 385.175 313.006C375.489 323.045 358.461 329.396 343.364 329.396Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M332.543 299.289C341.251 290.796 357.95 286.02 369.837 288.621C381.727 291.225 384.305 300.218 375.597 308.711C366.89 317.203 350.19 321.977 338.3 319.376C326.413 316.774 323.833 307.781 332.543 299.289Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M362.804 289.089C352.331 289.089 340.25 293.419 333.366 300.135C329.482 303.921 327.872 307.853 328.827 311.207C329.786 314.569 333.239 317.059 338.551 318.222C350.082 320.737 366.332 316.096 374.774 307.864C378.655 304.079 380.266 300.146 379.31 296.793C378.351 293.431 374.898 290.94 369.589 289.775C367.461 289.31 365.174 289.089 362.804 289.089ZM345.237 321.257C342.723 321.257 340.303 321.022 338.049 320.53C331.85 319.174 327.769 316.094 326.559 311.857C325.352 307.626 327.186 302.861 331.72 298.442C340.665 289.717 357.875 284.789 370.089 287.467C376.291 288.823 380.371 291.906 381.578 296.142C382.785 300.371 380.954 305.136 376.42 309.558C369.127 316.672 356.331 321.257 345.237 321.257Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M371.528 294.786C359.638 292.185 342.941 296.959 334.231 305.451C331.145 308.462 329.504 311.531 329.164 314.359C330.935 316.647 333.999 318.435 338.303 319.376C350.19 321.977 366.89 317.203 375.597 308.711C378.683 305.7 380.324 302.634 380.664 299.803C378.893 297.515 375.829 295.727 371.528 294.786Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M330.408 314.013C332.115 315.975 334.919 317.425 338.554 318.222C350.08 320.743 366.332 316.099 374.774 307.864C377.373 305.329 378.973 302.673 379.421 300.149C377.713 298.187 374.912 296.737 371.277 295.94C359.746 293.417 343.499 298.063 335.054 306.298C332.455 308.835 330.855 311.492 330.408 314.013ZM345.237 321.257C342.726 321.257 340.306 321.022 338.051 320.53C333.637 319.564 330.242 317.679 328.231 315.084L327.935 314.699L327.993 314.218C328.377 311.01 330.25 307.687 333.408 304.604C342.356 295.879 359.566 290.954 371.779 293.633C376.194 294.598 379.586 296.483 381.597 299.081L381.893 299.463L381.835 299.944C381.451 303.154 379.578 306.478 376.42 309.558C369.127 316.669 356.331 321.257 345.237 321.257Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M349.704 306.968C351.92 304.693 356.21 303.484 359.284 304.27C362.359 305.053 363.055 307.535 360.84 309.809C358.624 312.084 354.334 313.293 351.259 312.507C348.185 311.724 347.488 309.242 349.704 306.968Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M357.259 305.21C354.848 305.21 352.08 306.223 350.552 307.792H350.549C349.795 308.567 349.461 309.364 349.635 309.978C349.806 310.593 350.505 311.096 351.552 311.362C354.218 312.04 358.08 310.952 359.997 308.985C360.748 308.21 361.083 307.413 360.911 306.799C360.737 306.184 360.039 305.681 358.997 305.412C358.456 305.277 357.87 305.21 357.259 305.21ZM353.303 313.93C352.494 313.93 351.707 313.841 350.972 313.653C349.055 313.163 347.776 312.087 347.361 310.617C346.95 309.148 347.483 307.56 348.861 306.143C351.375 303.558 356.083 302.225 359.577 303.124C361.489 303.611 362.771 304.69 363.182 306.16C363.594 307.629 363.063 309.22 361.688 310.634C359.699 312.673 356.345 313.93 353.303 313.93Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M364.003 351.563L360.671 353.492C360.329 353.689 359.732 353.664 359.337 353.437L356.196 351.619C355.801 351.392 355.759 351.046 356.099 350.847L359.434 348.921C359.773 348.721 360.37 348.746 360.765 348.976L363.909 350.791C364.301 351.021 364.345 351.367 364.003 351.563Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M364.039 361.035L360.707 362.964C360.365 363.161 359.768 363.136 359.373 362.909L356.232 361.091C355.837 360.864 355.795 360.518 356.135 360.321L359.469 358.393C359.809 358.196 360.406 358.218 360.801 358.448L363.942 360.263C364.337 360.493 364.381 360.839 364.039 361.035Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M372.21 356.309L368.879 358.238C368.536 358.434 367.939 358.409 367.544 358.182L364.403 356.364C364.008 356.137 363.964 355.792 364.307 355.595L367.641 353.666C367.981 353.47 368.578 353.495 368.973 353.722L372.114 355.54C372.509 355.767 372.553 356.113 372.21 356.309Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M371.796 365.521L368.462 367.45C368.119 367.646 367.522 367.621 367.13 367.394L363.986 365.576C363.594 365.349 363.55 365.004 363.892 364.804L367.224 362.878C367.567 362.679 368.163 362.704 368.558 362.934L371.699 364.749C372.094 364.979 372.136 365.325 371.796 365.521Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M379.967 360.795L376.633 362.723C376.291 362.92 375.694 362.895 375.302 362.668L372.158 360.85C371.763 360.623 371.721 360.277 372.064 360.081L375.396 358.152C375.738 357.956 376.335 357.98 376.73 358.207L379.871 360.025C380.266 360.252 380.307 360.598 379.967 360.795Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M379.55 370.007L376.219 371.935C375.876 372.132 375.28 372.107 374.884 371.88L371.743 370.062C371.348 369.832 371.304 369.489 371.647 369.29L374.981 367.364C375.321 367.165 375.918 367.19 376.313 367.419L379.456 369.235C379.849 369.464 379.893 369.81 379.55 370.007Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M387.722 365.28L384.39 367.209C384.048 367.405 383.451 367.381 383.056 367.154L379.915 365.336C379.52 365.109 379.476 364.763 379.818 364.566L383.153 362.638C383.493 362.441 384.089 362.466 384.484 362.693L387.625 364.511C388.02 364.738 388.065 365.084 387.722 365.28Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M387.758 374.752L384.426 376.681C384.084 376.877 383.487 376.853 383.092 376.626L379.951 374.808C379.556 374.581 379.512 374.235 379.854 374.038L383.189 372.11C383.528 371.913 384.125 371.938 384.52 372.165L387.664 373.983C388.056 374.21 388.1 374.556 387.758 374.752Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M395.929 370.026L392.598 371.955C392.255 372.151 391.659 372.126 391.263 371.899L388.122 370.081C387.727 369.854 387.683 369.508 388.026 369.312L391.36 367.383C391.7 367.187 392.297 367.212 392.692 367.439L395.833 369.257C396.228 369.484 396.272 369.829 395.929 370.026Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M404.101 365.302L400.767 367.228C400.427 367.428 399.83 367.403 399.435 367.173L396.294 365.358C395.899 365.128 395.855 364.782 396.197 364.586L399.532 362.66C399.872 362.46 400.468 362.485 400.863 362.715L404.004 364.53C404.399 364.76 404.444 365.103 404.101 365.302Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M403.687 374.512L400.352 376.44C400.01 376.637 399.413 376.612 399.018 376.385L395.877 374.567C395.482 374.34 395.441 373.994 395.783 373.798L399.115 371.869C399.457 371.672 400.054 371.697 400.449 371.924L403.59 373.742C403.985 373.969 404.026 374.315 403.687 374.512Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M411.856 369.788L408.524 371.714C408.181 371.913 407.585 371.888 407.19 371.659L404.049 369.843C403.654 369.614 403.612 369.268 403.955 369.071L407.286 367.143C407.629 366.946 408.226 366.971 408.621 367.198L411.762 369.016C412.157 369.243 412.198 369.589 411.856 369.788Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M420.027 365.062L416.696 366.988C416.353 367.187 415.756 367.162 415.361 366.932L412.22 365.117C411.825 364.887 411.784 364.544 412.124 364.345L415.458 362.419C415.8 362.22 416.397 362.245 416.792 362.474L419.933 364.29C420.328 364.519 420.37 364.862 420.027 365.062Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M428.199 360.335L424.867 362.264C424.525 362.461 423.928 362.436 423.533 362.209L420.392 360.391C419.997 360.164 419.955 359.818 420.295 359.619L423.63 357.693C423.972 357.496 424.569 357.518 424.961 357.748L428.105 359.563C428.497 359.793 428.541 360.139 428.199 360.335Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M436.37 355.609L433.039 357.538C432.696 357.734 432.099 357.709 431.704 357.482L428.563 355.664C428.168 355.437 428.127 355.092 428.467 354.895L431.801 352.966C432.144 352.77 432.74 352.795 433.133 353.022L436.276 354.84C436.669 355.067 436.713 355.413 436.37 355.609Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M444.542 350.885L441.21 352.811C440.868 353.011 440.271 352.986 439.876 352.756L436.735 350.941C436.34 350.711 436.298 350.365 436.638 350.169L439.973 348.24C440.312 348.044 440.912 348.068 441.304 348.295L444.448 350.113C444.84 350.34 444.884 350.686 444.542 350.885Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M419.613 374.274L416.278 376.2C415.939 376.396 415.342 376.374 414.947 376.144L411.806 374.329C411.411 374.099 411.367 373.753 411.709 373.557L415.044 371.628C415.383 371.432 415.98 371.457 416.375 371.683L419.516 373.502C419.911 373.728 419.955 374.074 419.613 374.274Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M427.784 369.547L424.45 371.473C424.11 371.672 423.513 371.648 423.118 371.418L419.977 369.603C419.582 369.373 419.538 369.03 419.881 368.831L423.215 366.905C423.555 366.705 424.152 366.73 424.547 366.96L427.688 368.775C428.083 369.005 428.127 369.348 427.784 369.547Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M435.956 364.821L432.622 366.75C432.282 366.946 431.685 366.921 431.29 366.694L428.149 364.876C427.754 364.649 427.71 364.303 428.052 364.104L431.384 362.178C431.727 361.979 432.323 362.004 432.718 362.234L435.859 364.049C436.254 364.279 436.296 364.624 435.956 364.821Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M444.128 360.095L440.793 362.023C440.453 362.22 439.854 362.195 439.462 361.968L436.318 360.15C435.926 359.923 435.881 359.577 436.224 359.381L439.556 357.452C439.898 357.255 440.495 357.28 440.89 357.507L444.031 359.325C444.426 359.552 444.467 359.898 444.128 360.095Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M316.255 333.004L312.923 334.933C312.581 335.132 311.984 335.107 311.589 334.877L308.448 333.062C308.053 332.832 308.012 332.486 308.351 332.29L311.686 330.361C312.026 330.165 312.622 330.19 313.017 330.417L316.158 332.235C316.553 332.462 316.598 332.807 316.255 333.004Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M316.291 342.479L312.959 344.405C312.617 344.604 312.02 344.579 311.625 344.349L308.484 342.534C308.089 342.304 308.048 341.959 308.387 341.762L311.722 339.833C312.062 339.637 312.658 339.662 313.053 339.889L316.197 341.707C316.589 341.934 316.634 342.28 316.291 342.479Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M324.463 337.752L321.131 339.678C320.788 339.878 320.192 339.853 319.797 339.623L316.656 337.808C316.261 337.578 316.219 337.235 316.559 337.036L319.893 335.11C320.233 334.91 320.83 334.935 321.225 335.165L324.366 336.98C324.761 337.21 324.805 337.553 324.463 337.752Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M324.048 346.962L320.714 348.89C320.371 349.087 319.775 349.062 319.382 348.835L316.238 347.017C315.846 346.79 315.802 346.444 316.145 346.248L319.476 344.319C319.819 344.122 320.415 344.147 320.81 344.374L323.951 346.192C324.347 346.419 324.388 346.765 324.048 346.962Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M332.22 342.238L328.885 344.164C328.543 344.363 327.946 344.338 327.554 344.109L324.41 342.293C324.015 342.064 323.974 341.718 324.316 341.521L327.648 339.595C327.99 339.396 328.587 339.421 328.982 339.651L332.123 341.466C332.518 341.696 332.56 342.039 332.22 342.238Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M331.803 351.447L328.471 353.376C328.128 353.572 327.532 353.547 327.137 353.321L323.996 351.502C323.601 351.276 323.559 350.93 323.899 350.733L327.233 348.804C327.573 348.608 328.17 348.633 328.565 348.86L331.709 350.678C332.101 350.905 332.145 351.251 331.803 351.447Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M339.974 346.724L336.643 348.65C336.3 348.849 335.703 348.824 335.308 348.594L332.167 346.779C331.772 346.549 331.728 346.203 332.071 346.007L335.405 344.078C335.745 343.882 336.341 343.907 336.737 344.133L339.878 345.952C340.273 346.178 340.317 346.524 339.974 346.724Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M340.01 356.196L336.678 358.122C336.336 358.321 335.739 358.296 335.344 358.066L332.203 356.251C331.808 356.021 331.767 355.678 332.106 355.479L335.441 353.55C335.781 353.354 336.377 353.379 336.772 353.606L339.913 355.424C340.308 355.653 340.353 355.996 340.01 356.196Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M348.182 351.469L344.847 353.395C344.508 353.594 343.911 353.57 343.516 353.34L340.375 351.525C339.98 351.295 339.936 350.952 340.278 350.753L343.612 348.827C343.952 348.627 344.549 348.652 344.944 348.882L348.085 350.697C348.48 350.927 348.524 351.273 348.182 351.469Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M356.353 346.743L353.019 348.672C352.679 348.868 352.082 348.843 351.687 348.616L348.546 346.798C348.151 346.571 348.107 346.225 348.45 346.029L351.784 344.1C352.124 343.904 352.721 343.929 353.116 344.156L356.257 345.974C356.652 346.201 356.696 346.546 356.353 346.743Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M355.939 355.955L352.605 357.881C352.262 358.08 351.665 358.055 351.273 357.825L348.129 356.01C347.734 355.781 347.693 355.437 348.035 355.238L351.367 353.312C351.71 353.113 352.306 353.138 352.701 353.368L355.842 355.183C356.237 355.412 356.279 355.756 355.939 355.955Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M341.831 655.789L338.61 657.654C338.278 657.845 337.701 657.82 337.319 657.599L334.283 655.842C333.902 655.623 333.861 655.289 334.189 655.098L337.413 653.235C337.742 653.044 338.319 653.066 338.701 653.288L341.739 655.045C342.121 655.264 342.162 655.598 341.831 655.789Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M349.732 651.221L346.51 653.083C346.179 653.274 345.601 653.252 345.22 653.03L342.184 651.273C341.803 651.055 341.762 650.72 342.09 650.529L345.314 648.664C345.643 648.473 346.22 648.498 346.602 648.719L349.64 650.476C350.022 650.695 350.063 651.03 349.732 651.221Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M357.632 646.652L354.411 648.514C354.08 648.705 353.502 648.683 353.121 648.462L350.085 646.705C349.704 646.483 349.662 646.151 349.991 645.96L353.215 644.095C353.544 643.904 354.121 643.929 354.502 644.151L357.541 645.905C357.922 646.126 357.964 646.461 357.632 646.652Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M349.331 660.126L346.107 661.991C345.778 662.182 345.201 662.157 344.82 661.935L341.781 660.181C341.4 659.96 341.358 659.625 341.69 659.434L344.911 657.572C345.242 657.381 345.82 657.403 346.201 657.624L349.237 659.381C349.621 659.603 349.662 659.935 349.331 660.126Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M357.232 655.557L354.008 657.422C353.679 657.613 353.102 657.588 352.721 657.367L349.682 655.609C349.301 655.391 349.259 655.056 349.591 654.865L352.812 653.003C353.143 652.812 353.721 652.834 354.102 653.055L357.138 654.812C357.522 655.031 357.561 655.366 357.232 655.557Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M365.133 650.988L361.909 652.851C361.58 653.042 361.003 653.019 360.621 652.798L357.583 651.041C357.201 650.822 357.16 650.487 357.491 650.297L360.713 648.431C361.044 648.24 361.622 648.265 362.003 648.487L365.039 650.244C365.42 650.463 365.461 650.797 365.133 650.988Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M357.268 664.716L354.044 666.579C353.712 666.769 353.135 666.747 352.754 666.526L349.718 664.769C349.336 664.547 349.295 664.215 349.624 664.024L352.848 662.159C353.179 661.968 353.754 661.993 354.135 662.215L357.174 663.969C357.555 664.19 357.596 664.525 357.268 664.716Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M365.166 660.148L361.945 662.01C361.613 662.201 361.036 662.176 360.655 661.957L357.619 660.2C357.237 659.979 357.196 659.647 357.525 659.456L360.749 657.591C361.077 657.4 361.655 657.425 362.036 657.643L365.075 659.4C365.456 659.622 365.497 659.957 365.166 660.148Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M373.067 655.576L369.846 657.441C369.514 657.632 368.937 657.607 368.555 657.389L365.519 655.632C365.138 655.41 365.097 655.075 365.425 654.885L368.649 653.022C368.978 652.831 369.555 652.853 369.937 653.075L372.976 654.832C373.357 655.053 373.398 655.385 373.067 655.576Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M364.765 669.053L361.541 670.915C361.213 671.109 360.635 671.084 360.254 670.862L357.215 669.105C356.834 668.887 356.793 668.552 357.124 668.361L360.345 666.496C360.677 666.305 361.254 666.33 361.635 666.551L364.674 668.308C365.055 668.527 365.097 668.862 364.765 669.053Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M372.666 664.484L369.442 666.346C369.113 666.537 368.536 666.515 368.155 666.294L365.116 664.536C364.735 664.315 364.693 663.983 365.025 663.792L368.246 661.927C368.578 661.736 369.155 661.761 369.536 661.982L372.572 663.737C372.956 663.958 372.998 664.293 372.666 664.484Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M380.567 659.915L377.343 661.777C377.014 661.968 376.437 661.944 376.056 661.725L373.017 659.968C372.636 659.746 372.594 659.414 372.926 659.223L376.147 657.358C376.478 657.167 377.056 657.192 377.437 657.411L380.473 659.168C380.854 659.389 380.896 659.724 380.567 659.915Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M372.263 673.389L369.042 675.254C368.71 675.445 368.133 675.42 367.752 675.198L364.716 673.444C364.334 673.223 364.293 672.888 364.622 672.697L367.845 670.834C368.174 670.644 368.752 670.666 369.133 670.887L372.172 672.644C372.553 672.866 372.594 673.198 372.263 673.389Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M380.164 668.82L376.943 670.682C376.611 670.876 376.034 670.851 375.652 670.63L372.616 668.873C372.235 668.654 372.194 668.319 372.522 668.128L375.746 666.263C376.075 666.072 376.652 666.097 377.034 666.318L380.072 668.076C380.454 668.294 380.495 668.629 380.164 668.82Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M388.065 664.251L384.843 666.114C384.512 666.305 383.935 666.282 383.553 666.061L380.517 664.304C380.136 664.083 380.095 663.751 380.423 663.56L383.647 661.695C383.976 661.504 384.553 661.529 384.935 661.75L387.973 663.504C388.355 663.726 388.396 664.06 388.065 664.251Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M380.2 677.979L376.976 679.842C376.647 680.033 376.07 680.01 375.688 679.789L372.65 678.032C372.268 677.811 372.227 677.479 372.558 677.288L375.78 675.422C376.111 675.232 376.688 675.256 377.07 675.475L380.108 677.232C380.49 677.454 380.531 677.788 380.2 677.979Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M388.1 673.408L384.877 675.273C384.548 675.464 383.97 675.439 383.589 675.221L380.55 673.463C380.169 673.242 380.128 672.907 380.459 672.716L383.68 670.854C384.012 670.663 384.589 670.688 384.97 670.907L388.006 672.664C388.39 672.885 388.432 673.217 388.1 673.408Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M396.001 668.839L392.777 670.705C392.449 670.896 391.871 670.871 391.49 670.649L388.451 668.895C388.07 668.673 388.029 668.339 388.36 668.148L391.581 666.285C391.913 666.094 392.49 666.116 392.871 666.338L395.907 668.095C396.289 668.316 396.33 668.648 396.001 668.839Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M387.7 682.316L384.476 684.178C384.144 684.369 383.567 684.347 383.186 684.125L380.15 682.368C379.769 682.147 379.727 681.815 380.056 681.624L383.28 679.759C383.611 679.568 384.186 679.593 384.567 679.814L387.606 681.568C387.987 681.79 388.029 682.125 387.7 682.316Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M395.598 677.747L392.377 679.609C392.045 679.8 391.468 679.778 391.087 679.557L388.051 677.799C387.669 677.578 387.628 677.246 387.957 677.055L391.181 675.19C391.509 674.999 392.087 675.024 392.468 675.243L395.507 677C395.888 677.221 395.93 677.556 395.598 677.747Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M403.499 673.176L400.278 675.041C399.946 675.232 399.369 675.207 398.988 674.988L395.952 673.231C395.57 673.009 395.529 672.675 395.858 672.484L399.082 670.621C399.41 670.43 399.988 670.455 400.369 670.674L403.408 672.431C403.789 672.653 403.83 672.985 403.499 673.176Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M395.197 686.652L391.974 688.517C391.645 688.708 391.067 688.683 390.686 688.461L387.647 686.704C387.266 686.486 387.225 686.151 387.556 685.96L390.777 684.098C391.109 683.904 391.686 683.929 392.067 684.15L395.106 685.907C395.487 686.126 395.529 686.461 395.197 686.652Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M403.098 682.083L399.874 683.945C399.546 684.136 398.968 684.114 398.587 683.893L395.548 682.136C395.167 681.914 395.126 681.582 395.457 681.391L398.678 679.526C399.01 679.335 399.587 679.36 399.968 679.581L403.004 681.336C403.388 681.557 403.43 681.892 403.098 682.083Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M410.999 677.514L407.775 679.377C407.447 679.568 406.869 679.545 406.488 679.324L403.449 677.567C403.068 677.346 403.026 677.014 403.358 676.823L406.579 674.958C406.911 674.767 407.488 674.792 407.869 675.01L410.905 676.767C411.286 676.989 411.328 677.323 410.999 677.514Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M403.134 691.242L399.91 693.105C399.579 693.296 399.004 693.271 398.62 693.052L395.584 691.295C395.203 691.074 395.161 690.742 395.49 690.548L398.714 688.686C399.046 688.495 399.62 688.52 400.001 688.738L403.04 690.495C403.421 690.717 403.463 691.049 403.134 691.242Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M411.035 686.671L407.811 688.536C407.48 688.727 406.902 688.702 406.521 688.481L403.485 686.726C403.104 686.505 403.062 686.17 403.391 685.979L406.615 684.117C406.944 683.926 407.521 683.948 407.902 684.17L410.941 685.927C411.322 686.148 411.364 686.48 411.035 686.671Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M418.933 682.102L415.712 683.968C415.381 684.159 414.803 684.134 414.422 683.912L411.386 682.155C411.005 681.936 410.963 681.602 411.292 681.411L414.516 679.548C414.845 679.357 415.422 679.38 415.803 679.601L418.842 681.358C419.223 681.579 419.265 681.912 418.933 682.102Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M410.632 695.579L407.408 697.441C407.079 697.632 406.502 697.61 406.12 697.388L403.082 695.631C402.7 695.41 402.659 695.078 402.991 694.887L406.212 693.022C406.543 692.831 407.121 692.856 407.502 693.077L410.541 694.831C410.922 695.053 410.963 695.388 410.632 695.579Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M418.533 691.01L415.309 692.872C414.98 693.063 414.403 693.038 414.021 692.82L410.983 691.063C410.601 690.841 410.56 690.509 410.891 690.318L414.112 688.453C414.444 688.262 415.021 688.287 415.403 688.506L418.439 690.263C418.823 690.484 418.864 690.816 418.533 691.01Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M426.434 686.439L423.21 688.304C422.881 688.495 422.304 688.47 421.922 688.251L418.883 686.494C418.502 686.273 418.461 685.938 418.792 685.747L422.013 683.885C422.345 683.694 422.922 683.716 423.304 683.937L426.34 685.694C426.721 685.916 426.765 686.248 426.434 686.439Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M418.132 699.915L414.908 701.777C414.577 701.968 414.002 701.946 413.618 701.724L410.582 699.967C410.201 699.749 410.159 699.414 410.488 699.223L413.712 697.358C414.041 697.167 414.618 697.192 414.999 697.413L418.038 699.17C418.419 699.389 418.461 699.724 418.132 699.915Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M426.03 695.346L422.809 697.208C422.478 697.399 421.9 697.377 421.519 697.156L418.483 695.399C418.102 695.177 418.06 694.845 418.389 694.654L421.613 692.789C421.942 692.598 422.519 692.623 422.9 692.845L425.939 694.599C426.32 694.82 426.362 695.155 426.03 695.346Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M433.931 690.777L430.71 692.64C430.378 692.831 429.801 692.806 429.42 692.587L426.384 690.83C426.002 690.609 425.961 690.277 426.29 690.086L429.514 688.221C429.842 688.03 430.42 688.055 430.801 688.273L433.84 690.03C434.221 690.252 434.262 690.587 433.931 690.777Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M426.066 704.503L422.842 706.368C422.513 706.559 421.936 706.534 421.555 706.315L418.516 704.558C418.135 704.337 418.093 704.002 418.425 703.811L421.646 701.949C421.978 701.758 422.555 701.78 422.936 702.001L425.975 703.758C426.356 703.98 426.398 704.312 426.066 704.503Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M433.967 699.934L430.743 701.799C430.414 701.99 429.837 701.965 429.456 701.744L426.417 699.989C426.036 699.768 425.994 699.433 426.326 699.242L429.547 697.38C429.878 697.189 430.456 697.211 430.837 697.433L433.876 699.19C434.257 699.411 434.298 699.743 433.967 699.934Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M441.868 695.366L438.644 697.231C438.315 697.422 437.738 697.397 437.357 697.175L434.318 695.418C433.937 695.2 433.895 694.865 434.227 694.674L437.448 692.809C437.779 692.618 438.357 692.643 438.738 692.864L441.774 694.621C442.158 694.84 442.199 695.175 441.868 695.366Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M433.566 708.842L430.342 710.704C430.011 710.895 429.436 710.873 429.052 710.651L426.016 708.894C425.635 708.673 425.594 708.341 425.922 708.15L429.146 706.285C429.478 706.094 430.052 706.119 430.434 706.337L433.472 708.095C433.854 708.316 433.895 708.651 433.566 708.842Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M441.464 704.27L438.243 706.135C437.912 706.326 437.334 706.301 436.953 706.083L433.917 704.326C433.536 704.104 433.495 703.769 433.823 703.578L437.047 701.716C437.376 701.525 437.953 701.547 438.334 701.769L441.373 703.526C441.755 703.747 441.796 704.079 441.464 704.27Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M449.365 699.702L446.144 701.567C445.813 701.758 445.235 701.733 444.854 701.511L441.818 699.757C441.437 699.536 441.395 699.201 441.724 699.01L444.948 697.148C445.277 696.957 445.854 696.979 446.235 697.2L449.274 698.957C449.655 699.179 449.697 699.511 449.365 699.702Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M441.064 713.178L437.84 715.04C437.511 715.231 436.934 715.209 436.553 714.988L433.514 713.23C433.133 713.009 433.091 712.677 433.423 712.486L436.644 710.621C436.975 710.43 437.553 710.455 437.934 710.676L440.973 712.431C441.354 712.652 441.395 712.987 441.064 713.178Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M448.965 708.609L445.741 710.471C445.412 710.662 444.835 710.64 444.454 710.419L441.415 708.662C441.034 708.44 440.992 708.108 441.324 707.917L444.545 706.052C444.876 705.861 445.454 705.886 445.835 706.105L448.871 707.862C449.255 708.083 449.296 708.418 448.965 708.609Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M456.866 704.038L453.642 705.903C453.313 706.094 452.736 706.069 452.354 705.851L449.316 704.093C448.934 703.872 448.893 703.537 449.224 703.346L452.446 701.484C452.777 701.293 453.354 701.318 453.736 701.536L456.772 703.294C457.156 703.515 457.194 703.847 456.866 704.038Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M449.001 717.766L445.777 719.631C445.445 719.822 444.871 719.797 444.487 719.576L441.451 717.821C441.069 717.6 441.028 717.265 441.357 717.074L444.581 715.212C444.912 715.021 445.487 715.043 445.871 715.264L448.907 717.021C449.288 717.243 449.329 717.575 449.001 717.766Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M456.902 713.197L453.678 715.062C453.346 715.253 452.769 715.228 452.388 715.007L449.352 713.25C448.97 713.031 448.929 712.696 449.258 712.505L452.481 710.643C452.81 710.452 453.388 710.474 453.769 710.696L456.808 712.453C457.189 712.671 457.23 713.006 456.902 713.197Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M464.8 708.629L461.578 710.491C461.247 710.682 460.67 710.66 460.288 710.438L457.252 708.681C456.871 708.463 456.83 708.128 457.158 707.937L460.382 706.072C460.711 705.881 461.288 705.906 461.67 706.127L464.708 707.884C465.09 708.103 465.131 708.438 464.8 708.629Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M456.498 722.102L453.274 723.967C452.946 724.158 452.368 724.133 451.987 723.914L448.948 722.157C448.567 721.936 448.526 721.604 448.857 721.41L452.078 719.548C452.41 719.357 452.987 719.382 453.368 719.6L456.407 721.358C456.788 721.579 456.83 721.911 456.498 722.102Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M464.399 717.533L461.175 719.399C460.846 719.59 460.269 719.565 459.888 719.343L456.849 717.589C456.468 717.367 456.426 717.033 456.758 716.842L459.979 714.979C460.311 714.788 460.888 714.811 461.269 715.032L464.305 716.789C464.689 717.01 464.731 717.343 464.399 717.533Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M472.3 712.965L469.076 714.83C468.747 715.021 468.17 714.996 467.789 714.774L464.75 713.017C464.369 712.799 464.327 712.464 464.659 712.273L467.88 710.411C468.211 710.22 468.789 710.242 469.17 710.463L472.206 712.22C472.59 712.439 472.631 712.774 472.3 712.965Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M463.999 726.441L460.775 728.303C460.443 728.494 459.869 728.472 459.485 728.251L456.448 726.493C456.067 726.272 456.026 725.94 456.355 725.749L459.578 723.884C459.91 723.693 460.485 723.718 460.869 723.939L463.905 725.694C464.286 725.915 464.327 726.25 463.999 726.441Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M471.897 721.872L468.676 723.735C468.344 723.926 467.767 723.901 467.385 723.682L464.349 721.925C463.968 721.704 463.927 721.371 464.255 721.178L467.479 719.315C467.808 719.125 468.385 719.149 468.767 719.368L471.806 721.125C472.187 721.347 472.228 721.679 471.897 721.872Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M479.797 717.301L476.576 719.166C476.245 719.357 475.668 719.332 475.286 719.111L472.25 717.356C471.869 717.135 471.828 716.8 472.156 716.609L475.38 714.747C475.709 714.556 476.286 714.578 476.668 714.8L479.706 716.557C480.088 716.778 480.129 717.11 479.797 717.301Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M471.933 731.029L468.711 732.894C468.38 733.085 467.803 733.06 467.421 732.839L464.382 731.084C464.001 730.863 463.96 730.528 464.291 730.337L467.512 728.475C467.844 728.284 468.421 728.306 468.803 728.527L471.841 730.285C472.223 730.506 472.264 730.838 471.933 731.029Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M479.833 726.46L476.61 728.323C476.281 728.516 475.703 728.491 475.322 728.27L472.283 726.513C471.902 726.294 471.861 725.959 472.192 725.769L475.413 723.904C475.745 723.713 476.322 723.738 476.703 723.959L479.742 725.716C480.124 725.935 480.165 726.269 479.833 726.46Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M487.734 721.892L484.51 723.754C484.182 723.945 483.604 723.923 483.223 723.701L480.184 721.944C479.803 721.723 479.762 721.391 480.093 721.2L483.314 719.335C483.646 719.144 484.223 719.169 484.604 719.39L487.64 721.145C488.024 721.366 488.066 721.701 487.734 721.892Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M479.433 735.365L476.209 737.23C475.877 737.421 475.303 737.396 474.919 737.178L471.883 735.42C471.502 735.199 471.46 734.864 471.789 734.673L475.013 732.811C475.344 732.62 475.919 732.642 476.303 732.864L479.339 734.621C479.72 734.842 479.762 735.174 479.433 735.365Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M487.334 730.797L484.11 732.662C483.778 732.853 483.201 732.828 482.82 732.606L479.784 730.852C479.402 730.63 479.361 730.296 479.69 730.105L482.914 728.242C483.245 728.051 483.82 728.074 484.201 728.295L487.24 730.052C487.621 730.274 487.662 730.606 487.334 730.797Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M495.232 726.228L492.011 728.093C491.679 728.284 491.102 728.259 490.721 728.038L487.685 726.28C487.303 726.062 487.262 725.727 487.591 725.536L490.815 723.671C491.143 723.48 491.721 723.505 492.102 723.726L495.141 725.483C495.522 725.702 495.563 726.037 495.232 726.228Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M486.93 739.704L483.709 741.566C483.378 741.757 482.8 741.732 482.419 741.514L479.38 739.757C478.999 739.535 478.958 739.203 479.289 739.012L482.51 737.147C482.842 736.956 483.419 736.981 483.8 737.2L486.839 738.957C487.22 739.178 487.262 739.513 486.93 739.704Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M494.831 735.133L491.607 736.998C491.279 737.189 490.701 737.164 490.32 736.945L487.281 735.188C486.9 734.967 486.859 734.632 487.19 734.441L490.411 732.579C490.743 732.388 491.32 732.41 491.701 732.631L494.74 734.388C495.121 734.61 495.163 734.942 494.831 735.133Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M502.732 730.564L499.508 732.429C499.179 732.62 498.602 732.595 498.221 732.374L495.182 730.619C494.801 730.398 494.759 730.063 495.091 729.872L498.312 728.01C498.644 727.819 499.221 727.841 499.602 728.063L502.638 729.82C503.022 730.041 503.064 730.373 502.732 730.564Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M494.867 744.292L491.643 746.157C491.312 746.348 490.737 746.323 490.353 746.102L487.317 744.345C486.936 744.126 486.894 743.791 487.223 743.6L490.447 741.738C490.779 741.544 491.353 741.569 491.737 741.79L494.773 743.548C495.154 743.766 495.196 744.101 494.867 744.292Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M502.768 739.723L499.544 741.586C499.213 741.777 498.638 741.755 498.254 741.533L495.218 739.776C494.837 739.555 494.795 739.223 495.124 739.032L498.348 737.167C498.679 736.976 499.254 737 499.638 737.222L502.674 738.976C503.055 739.198 503.097 739.533 502.768 739.723Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M510.666 735.155L507.445 737.017C507.114 737.208 506.536 737.186 506.155 736.965L503.119 735.207C502.738 734.986 502.696 734.654 503.025 734.463L506.249 732.598C506.578 732.407 507.155 732.432 507.536 732.651L510.575 734.408C510.956 734.629 510.998 734.964 510.666 735.155Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M518.567 730.583L515.346 732.449C515.014 732.639 514.437 732.615 514.056 732.396L511.02 730.639C510.639 730.417 510.597 730.085 510.926 729.892L514.15 728.029C514.478 727.838 515.056 727.863 515.437 728.082L518.476 729.839C518.857 730.06 518.898 730.392 518.567 730.583Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M502.365 748.628L499.144 750.493C498.812 750.684 498.235 750.659 497.853 750.438L494.815 748.684C494.433 748.462 494.392 748.127 494.723 747.936L497.947 746.074C498.276 745.883 498.854 745.905 499.235 746.127L502.274 747.884C502.655 748.105 502.696 748.437 502.365 748.628Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M510.266 744.06L507.042 745.925C506.713 746.116 506.136 746.091 505.754 745.869L502.716 744.112C502.334 743.894 502.293 743.559 502.624 743.368L505.845 741.506C506.177 741.312 506.754 741.337 507.136 741.558L510.174 743.315C510.556 743.534 510.597 743.869 510.266 744.06Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M518.166 739.491L514.943 741.353C514.614 741.544 514.036 741.522 513.655 741.301L510.616 739.543C510.235 739.322 510.194 738.99 510.525 738.799L513.746 736.934C514.078 736.743 514.655 736.768 515.036 736.989L518.073 738.746C518.457 738.965 518.498 739.3 518.166 739.491Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M526.067 734.922L522.843 736.785C522.515 736.975 521.937 736.953 521.556 736.732L518.517 734.975C518.136 734.753 518.095 734.421 518.426 734.23L521.647 732.365C521.979 732.174 522.556 732.199 522.937 732.418L525.973 734.175C526.355 734.396 526.396 734.731 526.067 734.922Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M533.968 730.351L530.744 732.216C530.416 732.407 529.838 732.382 529.457 732.163L526.418 730.406C526.037 730.185 525.995 729.853 526.327 729.659L529.548 727.797C529.88 727.606 530.457 727.631 530.838 727.849L533.874 729.607C534.255 729.828 534.297 730.16 533.968 730.351Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M517.766 748.396L514.542 750.261C514.21 750.452 513.633 750.427 513.252 750.205L510.216 748.451C509.835 748.23 509.793 747.895 510.122 747.704L513.346 745.842C513.677 745.651 514.252 745.673 514.633 745.894L517.672 747.651C518.053 747.873 518.095 748.205 517.766 748.396Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M525.664 743.827L522.443 745.692C522.111 745.883 521.534 745.858 521.153 745.637L518.117 743.88C517.736 743.661 517.694 743.326 518.023 743.135L521.247 741.273C521.575 741.082 522.153 741.104 522.534 741.326L525.573 743.083C525.954 743.301 525.996 743.636 525.664 743.827Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M533.565 739.258L530.344 741.121C530.012 741.312 529.435 741.29 529.054 741.068L526.018 739.311C525.636 739.092 525.595 738.758 525.924 738.567L529.148 736.702C529.476 736.511 530.054 736.536 530.435 736.757L533.474 738.514C533.855 738.733 533.896 739.068 533.565 739.258Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M541.466 734.69L538.245 736.552C537.913 736.743 537.336 736.721 536.954 736.5L533.918 734.742C533.537 734.521 533.496 734.189 533.825 733.998L537.048 732.133C537.377 731.942 537.955 731.967 538.336 732.188L541.375 733.943C541.756 734.164 541.797 734.499 541.466 734.69Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M549.367 730.121L546.145 731.984C545.814 732.175 545.237 732.15 544.855 731.931L541.819 730.174C541.438 729.953 541.397 729.62 541.725 729.43L544.949 727.564C545.278 727.373 545.855 727.398 546.237 727.617L549.275 729.374C549.657 729.596 549.698 729.928 549.367 730.121Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M557.267 725.55L554.046 727.415C553.715 727.606 553.137 727.581 552.756 727.362L549.72 725.605C549.336 725.384 549.295 725.049 549.626 724.858L552.85 722.996C553.179 722.805 553.756 722.827 554.137 723.048L557.176 724.805C557.558 725.027 557.599 725.359 557.267 725.55Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M565.168 720.981L561.947 722.846C561.616 723.037 561.038 723.012 560.657 722.791L557.618 721.037C557.237 720.815 557.196 720.48 557.527 720.29L560.751 718.427C561.08 718.236 561.657 718.258 562.038 718.48L565.077 720.237C565.458 720.458 565.5 720.79 565.168 720.981Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M573.069 716.413L569.848 718.275C569.517 718.469 568.939 718.444 568.558 718.223L565.519 716.465C565.138 716.247 565.097 715.912 565.428 715.721L568.649 713.856C568.981 713.665 569.558 713.69 569.939 713.911L572.978 715.668C573.359 715.887 573.401 716.222 573.069 716.413Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M580.97 711.844L577.746 713.706C577.417 713.897 576.84 713.875 576.459 713.654L573.42 711.897C573.039 711.675 572.997 711.343 573.329 711.152L576.55 709.287C576.882 709.096 577.459 709.121 577.84 709.343L580.879 711.097C581.26 711.318 581.302 711.653 580.97 711.844Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M588.871 707.275L585.647 709.138C585.318 709.329 584.741 709.304 584.36 709.085L581.321 707.328C580.94 707.107 580.898 706.775 581.23 706.584L584.451 704.718C584.782 704.528 585.36 704.552 585.741 704.771L588.777 706.528C589.161 706.75 589.202 707.084 588.871 707.275Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M596.772 702.704L593.548 704.569C593.219 704.76 592.642 704.735 592.261 704.516L589.222 702.759C588.841 702.538 588.799 702.203 589.131 702.012L592.352 700.15C592.683 699.959 593.261 699.981 593.642 700.202L596.678 701.96C597.059 702.181 597.101 702.513 596.772 702.704Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M604.673 698.135L601.449 700.001C601.12 700.191 600.543 700.167 600.161 699.945L597.123 698.191C596.741 697.969 596.7 697.635 597.032 697.444L600.253 695.581C600.584 695.39 601.161 695.413 601.543 695.634L604.579 697.391C604.96 697.612 605.001 697.945 604.673 698.135Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M612.574 693.567L609.35 695.432C609.021 695.623 608.444 695.598 608.062 695.377L605.023 693.62C604.642 693.401 604.601 693.066 604.932 692.875L608.153 691.01C608.485 690.819 609.062 690.844 609.444 691.065L612.48 692.823C612.861 693.041 612.902 693.376 612.574 693.567Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M620.474 688.998L617.251 690.861C616.922 691.051 616.344 691.029 615.963 690.808L612.924 689.051C612.543 688.829 612.502 688.497 612.833 688.306L616.054 686.441C616.386 686.25 616.963 686.275 617.344 686.497L620.38 688.251C620.762 688.472 620.803 688.807 620.474 688.998Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M628.375 684.43L625.151 686.292C624.823 686.483 624.245 686.461 623.864 686.239L620.825 684.482C620.444 684.261 620.403 683.929 620.731 683.738L623.955 681.873C624.287 681.682 624.864 681.707 625.245 681.925L628.281 683.683C628.663 683.904 628.704 684.239 628.375 684.43Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M533.601 748.418L530.377 750.28C530.048 750.471 529.471 750.446 529.09 750.228L526.051 748.47C525.67 748.249 525.628 747.917 525.96 747.726L529.181 745.861C529.512 745.67 530.09 745.695 530.471 745.914L533.51 747.671C533.891 747.892 533.932 748.227 533.601 748.418Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M541.502 743.846L538.278 745.712C537.949 745.902 537.372 745.878 536.99 745.659L533.952 743.902C533.57 743.68 533.529 743.346 533.86 743.155L537.082 741.292C537.413 741.101 537.99 741.124 538.372 741.345L541.408 743.102C541.792 743.323 541.833 743.656 541.502 743.846Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M549.402 739.278L546.179 741.143C545.85 741.334 545.273 741.309 544.891 741.087L541.852 739.333C541.471 739.112 541.43 738.777 541.761 738.586L544.982 736.724C545.314 736.533 545.891 736.555 546.273 736.776L549.309 738.533C549.69 738.755 549.731 739.087 549.402 739.278Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M557.303 734.709L554.08 736.574C553.751 736.765 553.173 736.74 552.792 736.519L549.753 734.762C549.372 734.543 549.331 734.208 549.662 734.017L552.883 732.152C553.215 731.961 553.792 731.986 554.173 732.208L557.209 733.965C557.591 734.183 557.632 734.518 557.303 734.709Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M565.204 730.141L561.98 732.003C561.652 732.194 561.074 732.172 560.693 731.95L557.654 730.193C557.273 729.972 557.231 729.64 557.563 729.449L560.784 727.584C561.116 727.393 561.693 727.418 562.074 727.639L565.11 729.393C565.492 729.615 565.533 729.95 565.204 730.141Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M573.105 725.572L569.881 727.434C569.552 727.625 568.975 727.603 568.594 727.382L565.555 725.625C565.174 725.403 565.132 725.071 565.464 724.88L568.685 723.015C569.017 722.824 569.594 722.849 569.975 723.068L573.011 724.825C573.392 725.046 573.434 725.381 573.105 725.572Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M581.006 721.001L577.782 722.866C577.451 723.057 576.876 723.032 576.495 722.813L573.456 721.056C573.075 720.835 573.033 720.5 573.362 720.309L576.586 718.447C576.917 718.256 577.492 718.28 577.876 718.499L580.912 720.256C581.293 720.478 581.335 720.81 581.006 721.001Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M588.907 716.432L585.683 718.297C585.351 718.488 584.777 718.463 584.393 718.242L581.357 716.487C580.976 716.266 580.934 715.931 581.263 715.74L584.487 713.878C584.818 713.687 585.393 713.709 585.777 713.93L588.813 715.688C589.194 715.909 589.236 716.241 588.907 716.432Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M596.808 711.863L593.584 713.728C593.252 713.919 592.675 713.894 592.294 713.673L589.258 711.916C588.876 711.697 588.835 711.363 589.164 711.172L592.388 709.309C592.719 709.116 593.294 709.141 593.675 709.362L596.714 711.119C597.095 711.338 597.136 711.672 596.808 711.863Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M604.706 707.295L601.485 709.157C601.153 709.348 600.576 709.326 600.195 709.105L597.159 707.347C596.777 707.126 596.736 706.794 597.065 706.603L600.289 704.738C600.617 704.547 601.195 704.572 601.576 704.793L604.615 706.551C604.996 706.769 605.037 707.104 604.706 707.295Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M612.607 702.726L609.386 704.589C609.054 704.78 608.477 704.757 608.095 704.536L605.059 702.779C604.678 702.557 604.637 702.225 604.966 702.035L608.189 700.169C608.518 699.979 609.096 700.003 609.477 700.222L612.516 701.979C612.897 702.201 612.938 702.535 612.607 702.726Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M620.508 698.158L617.286 700.02C616.955 700.211 616.378 700.186 615.996 699.967L612.96 698.21C612.579 697.989 612.538 697.657 612.866 697.463L616.09 695.601C616.419 695.41 616.996 695.435 617.378 695.653L620.416 697.41C620.798 697.632 620.839 697.964 620.508 698.158Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M628.408 693.586L625.187 695.451C624.856 695.642 624.278 695.617 623.897 695.396L620.861 693.642C620.48 693.42 620.436 693.085 620.767 692.895L623.991 691.032C624.32 690.841 624.897 690.863 625.278 691.085L628.317 692.842C628.698 693.063 628.74 693.395 628.408 693.586Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M636.309 689.018L633.088 690.883C632.757 691.074 632.179 691.049 631.798 690.827L628.762 689.07C628.378 688.852 628.337 688.517 628.668 688.326L631.892 686.464C632.221 686.273 632.798 686.295 633.179 686.516L636.218 688.273C636.599 688.492 636.641 688.827 636.309 689.018Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M548.999 748.185L545.778 750.048C545.447 750.239 544.869 750.214 544.488 749.995L541.452 748.238C541.071 748.017 541.029 747.684 541.358 747.494L544.582 745.628C544.911 745.437 545.488 745.462 545.869 745.681L548.908 747.438C549.289 747.66 549.331 747.994 548.999 748.185Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M556.9 743.614L553.679 745.479C553.347 745.67 552.77 745.645 552.389 745.427L549.353 743.669C548.972 743.448 548.93 743.113 549.259 742.922L552.483 741.06C552.811 740.869 553.389 740.891 553.77 741.112L556.809 742.87C557.19 743.091 557.232 743.423 556.9 743.614Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M564.801 739.045L561.58 740.91C561.248 741.101 560.671 741.076 560.29 740.855L557.254 739.101C556.872 738.879 556.831 738.544 557.16 738.354L560.384 736.491C560.712 736.3 561.29 736.322 561.671 736.544L564.71 738.301C565.091 738.522 565.132 738.854 564.801 739.045Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M572.702 734.477L569.481 736.342C569.149 736.533 568.572 736.508 568.191 736.287L565.155 734.529C564.771 734.311 564.729 733.976 565.061 733.785L568.284 731.92C568.613 731.729 569.191 731.754 569.572 731.975L572.611 733.732C572.992 733.951 573.033 734.286 572.702 734.477Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M580.603 729.908L577.381 731.771C577.05 731.962 576.473 731.939 576.091 731.718L573.053 729.961C572.671 729.739 572.63 729.407 572.961 729.216L576.185 727.351C576.514 727.16 577.091 727.185 577.473 727.407L580.511 729.161C580.893 729.383 580.934 729.717 580.603 729.908Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M588.504 725.34L585.282 727.202C584.951 727.393 584.374 727.371 583.992 727.149L580.954 725.392C580.572 725.171 580.531 724.839 580.862 724.648L584.086 722.783C584.415 722.592 584.992 722.617 585.374 722.835L588.412 724.592C588.794 724.814 588.835 725.149 588.504 725.34Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M596.404 720.768L593.18 722.633C592.852 722.824 592.274 722.799 591.893 722.581L588.854 720.824C588.473 720.602 588.432 720.267 588.763 720.076L591.984 718.214C592.316 718.023 592.893 718.048 593.274 718.267L596.313 720.024C596.694 720.245 596.736 720.577 596.404 720.768Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M604.305 716.2L601.081 718.065C600.753 718.256 600.175 718.231 599.794 718.009L596.755 716.255C596.374 716.034 596.333 715.699 596.664 715.508L599.885 713.645C600.217 713.455 600.794 713.477 601.175 713.698L604.214 715.455C604.595 715.677 604.637 716.009 604.305 716.2Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M612.206 711.631L608.982 713.496C608.653 713.687 608.076 713.662 607.695 713.441L604.656 711.684C604.275 711.465 604.233 711.13 604.565 710.939L607.786 709.077C608.117 708.883 608.695 708.908 609.076 709.13L612.112 710.887C612.496 711.105 612.538 711.44 612.206 711.631Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M620.107 707.062L616.883 708.925C616.554 709.116 615.977 709.093 615.596 708.872L612.557 707.115C612.176 706.896 612.134 706.561 612.466 706.371L615.687 704.506C616.018 704.315 616.596 704.339 616.977 704.561L620.013 706.318C620.394 706.537 620.436 706.871 620.107 707.062Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M628.008 702.494L624.784 704.356C624.455 704.547 623.878 704.525 623.497 704.303L620.458 702.546C620.077 702.325 620.035 701.993 620.367 701.802L623.588 699.937C623.919 699.746 624.497 699.771 624.878 699.992L627.914 701.747C628.295 701.968 628.337 702.303 628.008 702.494Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M635.909 697.925L632.685 699.787C632.356 699.978 631.779 699.953 631.397 699.735L628.359 697.978C627.977 697.756 627.936 697.424 628.267 697.233L631.489 695.368C631.82 695.177 632.398 695.202 632.779 695.421L635.815 697.178C636.196 697.399 636.238 697.731 635.909 697.925Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M643.81 693.354L640.586 695.219C640.257 695.41 639.68 695.385 639.298 695.166L636.26 693.409C635.878 693.188 635.837 692.853 636.166 692.662L639.39 690.8C639.721 690.609 640.298 690.631 640.68 690.852L643.716 692.609C644.097 692.831 644.138 693.163 643.81 693.354Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M644.141 675.309L640.92 677.174C640.589 677.365 640.011 677.34 639.63 677.122L636.591 675.364C636.21 675.143 636.168 674.808 636.5 674.617L639.721 672.755C640.053 672.564 640.63 672.589 641.011 672.807L644.05 674.565C644.431 674.786 644.473 675.118 644.141 675.309Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M636.24 679.88L633.019 681.743C632.688 681.934 632.11 681.912 631.729 681.69L628.69 679.933C628.309 679.712 628.268 679.38 628.599 679.189L631.823 677.323C632.152 677.133 632.729 677.157 633.11 677.376L636.149 679.133C636.53 679.355 636.572 679.689 636.24 679.88Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M652.042 670.74L648.818 672.606C648.489 672.796 647.912 672.772 647.531 672.55L644.492 670.796C644.111 670.574 644.069 670.24 644.401 670.049L647.622 668.186C647.953 667.995 648.531 668.018 648.912 668.239L651.951 669.996C652.332 670.217 652.373 670.55 652.042 670.74Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M659.943 666.172L656.719 668.037C656.39 668.228 655.813 668.203 655.432 667.982L652.393 666.224C652.012 666.006 651.97 665.671 652.302 665.48L655.523 663.618C655.854 663.427 656.432 663.449 656.813 663.67L659.849 665.427C660.233 665.646 660.274 665.981 659.943 666.172Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M667.844 661.603L664.62 663.466C664.291 663.656 663.714 663.634 663.333 663.413L660.294 661.656C659.912 661.437 659.871 661.102 660.203 660.911L663.424 659.046C663.755 658.855 664.333 658.88 664.714 659.102L667.75 660.859C668.134 661.077 668.172 661.412 667.844 661.603Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M675.745 657.035L672.521 658.897C672.192 659.088 671.615 659.066 671.233 658.844L668.195 657.087C667.813 656.866 667.772 656.534 668.103 656.343L671.324 654.478C671.656 654.287 672.233 654.312 672.615 654.533L675.651 656.287C676.032 656.509 676.073 656.844 675.745 657.035Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M683.645 652.466L680.422 654.328C680.093 654.519 679.515 654.494 679.134 654.276L676.096 652.519C675.714 652.297 675.673 651.965 676.004 651.774L679.225 649.909C679.557 649.718 680.134 649.743 680.516 649.962L683.552 651.719C683.933 651.94 683.974 652.272 683.645 652.466Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M691.546 647.895L688.323 649.76C687.994 649.951 687.416 649.926 687.035 649.707L683.996 647.95C683.615 647.729 683.574 647.394 683.905 647.203L687.126 645.341C687.458 645.15 688.035 645.172 688.416 645.393L691.452 647.15C691.834 647.372 691.875 647.704 691.546 647.895Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M699.447 643.326L696.223 645.191C695.895 645.382 695.317 645.357 694.936 645.136L691.897 643.381C691.516 643.16 691.474 642.825 691.803 642.634L695.027 640.772C695.359 640.581 695.936 640.603 696.317 640.825L699.353 642.582C699.735 642.803 699.776 643.135 699.447 643.326Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M707.348 638.757L704.124 640.62C703.793 640.813 703.218 640.789 702.834 640.567L699.798 638.81C699.417 638.591 699.375 638.257 699.704 638.066L702.928 636.201C703.26 636.01 703.834 636.035 704.218 636.256L707.254 638.013C707.635 638.232 707.677 638.566 707.348 638.757Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M715.249 634.189L712.025 636.051C711.694 636.242 711.119 636.22 710.735 635.999L707.699 634.241C707.318 634.02 707.276 633.688 707.605 633.497L710.829 631.632C711.16 631.441 711.735 631.466 712.116 631.687L715.155 633.442C715.536 633.663 715.578 633.998 715.249 634.189Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M652.078 679.9L648.854 681.762C648.522 681.953 647.948 681.931 647.567 681.709L644.528 679.952C644.147 679.734 644.105 679.399 644.434 679.208L647.658 677.343C647.989 677.152 648.567 677.177 648.948 677.398L651.984 679.155C652.365 679.374 652.407 679.709 652.078 679.9Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M644.177 684.468L640.953 686.333C640.624 686.524 640.047 686.5 639.666 686.278L636.627 684.521C636.246 684.302 636.204 683.967 636.536 683.777L639.757 681.914C640.088 681.723 640.666 681.745 641.047 681.967L644.083 683.724C644.464 683.943 644.506 684.277 644.177 684.468Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M659.979 675.331L656.755 677.194C656.423 677.385 655.849 677.362 655.465 677.141L652.429 675.384C652.047 675.162 652.006 674.83 652.335 674.639L655.559 672.774C655.89 672.583 656.465 672.608 656.849 672.83L659.885 674.584C660.266 674.805 660.307 675.14 659.979 675.331Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M667.88 670.763L664.656 672.625C664.324 672.816 663.747 672.791 663.366 672.572L660.33 670.815C659.948 670.594 659.907 670.262 660.236 670.071L663.46 668.206C663.788 668.015 664.366 668.04 664.747 668.258L667.786 670.015C668.167 670.237 668.208 670.572 667.88 670.763Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M675.778 666.191L672.557 668.056C672.225 668.247 671.648 668.222 671.267 668.004L668.231 666.247C667.849 666.025 667.808 665.69 668.137 665.499L671.36 663.637C671.689 663.446 672.267 663.468 672.648 663.69L675.687 665.447C676.068 665.668 676.109 666 675.778 666.191Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M683.679 661.623L680.458 663.488C680.126 663.679 679.549 663.654 679.167 663.432L676.131 661.678C675.75 661.457 675.709 661.122 676.037 660.931L679.261 659.068C679.59 658.878 680.167 658.9 680.549 659.121L683.588 660.878C683.969 661.1 684.01 661.432 683.679 661.623Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M691.579 657.054L688.358 658.919C688.027 659.11 687.45 659.085 687.068 658.864L684.032 657.107C683.651 656.888 683.61 656.553 683.938 656.362L687.162 654.497C687.491 654.306 688.068 654.331 688.45 654.553L691.488 656.31C691.87 656.528 691.911 656.863 691.579 657.054Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M699.48 652.485L696.259 654.348C695.928 654.539 695.35 654.516 694.969 654.295L691.933 652.538C691.552 652.317 691.511 651.985 691.839 651.794L695.063 649.928C695.392 649.738 695.969 649.762 696.35 649.984L699.389 651.738C699.77 651.96 699.812 652.294 699.48 652.485Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M707.381 647.917L704.16 649.779C703.828 649.97 703.251 649.948 702.87 649.726L699.834 647.969C699.45 647.748 699.408 647.416 699.74 647.225L702.964 645.36C703.293 645.169 703.87 645.194 704.251 645.413L707.29 647.17C707.671 647.391 707.713 647.726 707.381 647.917Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M715.282 643.345L712.061 645.21C711.729 645.401 711.152 645.377 710.771 645.158L707.732 643.401C707.351 643.179 707.309 642.845 707.641 642.654L710.865 640.791C711.194 640.6 711.771 640.625 712.152 640.844L715.191 642.601C715.572 642.822 715.614 643.154 715.282 643.345Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M659.575 684.236L656.354 686.101C656.023 686.292 655.445 686.267 655.064 686.046L652.025 684.289C651.644 684.07 651.603 683.735 651.934 683.544L655.158 681.682C655.487 681.491 656.064 681.513 656.445 681.734L659.484 683.492C659.865 683.71 659.907 684.045 659.575 684.236Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M651.675 688.805L648.453 690.67C648.122 690.861 647.545 690.836 647.163 690.617L644.127 688.86C643.743 688.639 643.702 688.304 644.033 688.113L647.257 686.25C647.586 686.06 648.163 686.082 648.545 686.303L651.583 688.06C651.965 688.282 652.006 688.614 651.675 688.805Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M667.476 679.667L664.252 681.53C663.924 681.723 663.346 681.698 662.965 681.477L659.926 679.72C659.545 679.501 659.504 679.166 659.835 678.976L663.056 677.11C663.388 676.919 663.965 676.944 664.346 677.166L667.385 678.923C667.766 679.142 667.808 679.476 667.476 679.667Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M675.377 675.099L672.153 676.961C671.824 677.152 671.247 677.13 670.866 676.908L667.827 675.151C667.446 674.93 667.404 674.598 667.736 674.407L670.957 672.542C671.289 672.351 671.866 672.376 672.247 672.597L675.286 674.352C675.667 674.573 675.709 674.908 675.377 675.099Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M683.278 670.53L680.054 672.393C679.725 672.583 679.148 672.558 678.767 672.34L675.728 670.583C675.347 670.361 675.305 670.029 675.637 669.838L678.858 667.973C679.189 667.782 679.767 667.807 680.148 668.026L683.184 669.783C683.568 670.004 683.61 670.339 683.278 670.53Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M691.179 665.959L687.955 667.824C687.626 668.015 687.049 667.99 686.668 667.771L683.629 666.014C683.248 665.793 683.206 665.458 683.538 665.267L686.759 663.405C687.09 663.214 687.668 663.236 688.049 663.457L691.085 665.214C691.466 665.436 691.508 665.768 691.179 665.959Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M699.08 661.39L695.856 663.255C695.527 663.446 694.95 663.421 694.569 663.2L691.53 661.445C691.149 661.224 691.107 660.889 691.439 660.698L694.66 658.836C694.991 658.645 695.569 658.667 695.95 658.889L698.986 660.646C699.367 660.867 699.408 661.199 699.08 661.39Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M706.981 656.822L703.757 658.687C703.428 658.878 702.851 658.853 702.469 658.631L699.431 656.874C699.049 656.656 699.008 656.321 699.339 656.13L702.561 654.265C702.892 654.074 703.469 654.099 703.851 654.32L706.887 656.077C707.268 656.296 707.309 656.631 706.981 656.822Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M714.882 652.253L711.658 654.115C711.329 654.306 710.752 654.284 710.37 654.063L707.332 652.305C706.95 652.084 706.909 651.752 707.24 651.561L710.461 649.696C710.793 649.505 711.37 649.53 711.752 649.751L714.788 651.506C715.169 651.727 715.21 652.062 714.882 652.253Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M166.064 545.119L162.843 546.981C162.511 547.172 161.934 547.15 161.553 546.929L158.517 545.172C158.135 544.95 158.094 544.618 158.423 544.427L161.647 542.562C161.975 542.371 162.553 542.396 162.934 542.617L165.973 544.372C166.354 544.593 166.395 544.928 166.064 545.119Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M158.163 549.688L154.942 551.553C154.61 551.744 154.033 551.719 153.652 551.497L150.616 549.74C150.235 549.522 150.193 549.187 150.522 548.996L153.746 547.131C154.074 546.94 154.652 546.965 155.033 547.186L158.072 548.943C158.453 549.162 158.495 549.497 158.163 549.688Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M173.965 540.55L170.744 542.413C170.412 542.604 169.835 542.581 169.454 542.36L166.418 540.603C166.036 540.382 165.995 540.05 166.324 539.859L169.547 537.994C169.876 537.803 170.454 537.828 170.835 538.046L173.874 539.803C174.255 540.025 174.296 540.359 173.965 540.55Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M173.564 549.455L170.34 551.32C170.012 551.511 169.434 551.486 169.053 551.265L166.014 549.508C165.633 549.289 165.592 548.954 165.923 548.763L169.144 546.901C169.476 546.707 170.053 546.732 170.434 546.954L173.47 548.711C173.854 548.929 173.893 549.264 173.564 549.455Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M165.663 554.024L162.439 555.889C162.111 556.08 161.533 556.055 161.152 555.834L158.113 554.079C157.732 553.858 157.691 553.523 158.022 553.332L161.243 551.47C161.575 551.279 162.152 551.301 162.533 551.522L165.569 553.279C165.953 553.501 165.995 553.833 165.663 554.024Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M181.465 544.887L178.241 546.749C177.912 546.94 177.335 546.918 176.954 546.696L173.915 544.939C173.534 544.718 173.492 544.386 173.824 544.195L177.045 542.33C177.377 542.139 177.954 542.164 178.335 542.385L181.371 544.139C181.752 544.361 181.794 544.696 181.465 544.887Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M181.498 554.046L178.277 555.908C177.946 556.099 177.368 556.074 176.987 555.856L173.951 554.098C173.57 553.877 173.528 553.545 173.857 553.351L177.081 551.489C177.41 551.298 177.987 551.323 178.368 551.542L181.407 553.299C181.788 553.52 181.83 553.852 181.498 554.046Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M173.6 558.614L170.376 560.477C170.045 560.668 169.47 560.646 169.086 560.424L166.05 558.667C165.669 558.446 165.627 558.114 165.956 557.923L169.18 556.058C169.509 555.867 170.086 555.892 170.467 556.11L173.506 557.867C173.887 558.089 173.929 558.424 173.6 558.614Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M189.399 549.475L186.178 551.34C185.846 551.531 185.269 551.506 184.888 551.284L181.852 549.53C181.471 549.308 181.429 548.974 181.758 548.783L184.982 546.92C185.311 546.729 185.888 546.752 186.269 546.973L189.308 548.73C189.689 548.952 189.731 549.284 189.399 549.475Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M188.999 558.382L185.775 560.244C185.446 560.435 184.869 560.413 184.487 560.192L181.449 558.435C181.067 558.213 181.026 557.881 181.357 557.69L184.578 555.825C184.91 555.634 185.487 555.659 185.869 555.881L188.905 557.635C189.289 557.856 189.33 558.191 188.999 558.382Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M181.098 562.951L177.874 564.813C177.545 565.004 176.968 564.982 176.586 564.76L173.548 563.003C173.166 562.785 173.125 562.45 173.456 562.259L176.678 560.394C177.009 560.203 177.586 560.228 177.968 560.449L181.006 562.206C181.388 562.425 181.429 562.76 181.098 562.951Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M196.899 553.813L193.675 555.676C193.347 555.867 192.769 555.842 192.388 555.623L189.349 553.866C188.968 553.645 188.927 553.313 189.258 553.122L192.479 551.257C192.811 551.066 193.388 551.091 193.769 551.309L196.805 553.066C197.187 553.288 197.228 553.62 196.899 553.813Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M196.496 562.718L193.275 564.581C192.943 564.771 192.366 564.749 191.985 564.528L188.949 562.771C188.568 562.552 188.526 562.217 188.855 562.026L192.079 560.161C192.408 559.97 192.985 559.995 193.366 560.217L196.405 561.974C196.786 562.192 196.828 562.527 196.496 562.718Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M188.595 567.287L185.374 569.152C185.043 569.343 184.465 569.318 184.084 569.097L181.048 567.339C180.667 567.121 180.625 566.786 180.954 566.595L184.178 564.733C184.507 564.542 185.084 564.564 185.465 564.785L188.504 566.542C188.885 566.761 188.927 567.096 188.595 567.287Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M204.397 558.15L201.176 560.012C200.844 560.203 200.267 560.181 199.886 559.959L196.85 558.202C196.468 557.981 196.427 557.649 196.756 557.458L199.98 555.593C200.308 555.402 200.886 555.427 201.267 555.648L204.306 557.402C204.687 557.624 204.728 557.959 204.397 558.15Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M204.433 567.306L201.209 569.171C200.88 569.362 200.303 569.337 199.922 569.119L196.883 567.362C196.502 567.14 196.46 566.805 196.792 566.614L200.013 564.752C200.344 564.561 200.922 564.583 201.303 564.805L204.339 566.562C204.723 566.783 204.764 567.115 204.433 567.306Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M196.532 571.878L193.308 573.74C192.979 573.931 192.402 573.906 192.021 573.687L188.982 571.93C188.601 571.709 188.559 571.377 188.891 571.186L192.112 569.321C192.443 569.13 193.021 569.155 193.402 569.373L196.441 571.13C196.822 571.352 196.863 571.687 196.532 571.878Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M212.334 562.738L209.11 564.603C208.781 564.794 208.204 564.769 207.822 564.547L204.784 562.793C204.402 562.572 204.361 562.237 204.693 562.046L207.914 560.184C208.245 559.993 208.823 560.015 209.204 560.236L212.24 561.993C212.624 562.215 212.662 562.547 212.334 562.738Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M211.93 571.645L208.709 573.508C208.378 573.698 207.8 573.676 207.419 573.455L204.383 571.698C204.002 571.476 203.96 571.144 204.289 570.953L207.513 569.088C207.842 568.897 208.419 568.922 208.8 569.141L211.839 570.898C212.22 571.119 212.262 571.454 211.93 571.645Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M204.032 576.214L200.808 578.076C200.477 578.267 199.902 578.245 199.518 578.023L196.482 576.266C196.101 576.045 196.06 575.713 196.388 575.522L199.612 573.657C199.944 573.466 200.518 573.491 200.9 573.712L203.938 575.467C204.32 575.688 204.361 576.023 204.032 576.214Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M219.831 567.074L216.61 568.939C216.279 569.13 215.701 569.105 215.32 568.886L212.284 567.129C211.903 566.908 211.861 566.573 212.19 566.382L215.414 564.52C215.743 564.329 216.32 564.351 216.701 564.572L219.74 566.329C220.121 566.551 220.163 566.883 219.831 567.074Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M219.431 575.981L216.207 577.844C215.878 578.035 215.301 578.012 214.919 577.791L211.881 576.034C211.499 575.813 211.458 575.48 211.79 575.29L215.011 573.424C215.342 573.234 215.919 573.258 216.301 573.48L219.337 575.234C219.721 575.456 219.762 575.79 219.431 575.981Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M211.53 580.55L208.306 582.415C207.977 582.606 207.4 582.581 207.019 582.36L203.98 580.603C203.599 580.384 203.557 580.049 203.889 579.858L207.11 577.993C207.441 577.802 208.019 577.827 208.4 578.048L211.439 579.806C211.82 580.024 211.861 580.359 211.53 580.55Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M227.332 571.413L224.108 573.275C223.779 573.466 223.202 573.444 222.82 573.222L219.782 571.465C219.4 571.244 219.359 570.912 219.69 570.721L222.912 568.856C223.243 568.665 223.82 568.69 224.202 568.908L227.238 570.666C227.619 570.887 227.66 571.222 227.332 571.413Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M227.365 580.569L224.144 582.434C223.812 582.625 223.235 582.6 222.853 582.379L219.817 580.625C219.436 580.403 219.395 580.068 219.723 579.877L222.947 578.015C223.276 577.824 223.853 577.846 224.235 578.068L227.274 579.825C227.655 580.046 227.696 580.378 227.365 580.569Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M219.467 585.138L216.243 587.003C215.911 587.194 215.337 587.169 214.953 586.95L211.917 585.193C211.535 584.972 211.494 584.637 211.823 584.446L215.047 582.584C215.378 582.393 215.953 582.418 216.337 582.636L219.373 584.393C219.754 584.615 219.795 584.947 219.467 585.138Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M235.266 576.001L232.044 577.866C231.713 578.057 231.136 578.032 230.754 577.81L227.718 576.053C227.337 575.835 227.296 575.5 227.624 575.309L230.848 573.447C231.177 573.256 231.754 573.278 232.136 573.499L235.174 575.256C235.556 575.475 235.597 575.81 235.266 576.001Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M234.865 584.905L231.641 586.771C231.312 586.962 230.735 586.937 230.354 586.718L227.315 584.961C226.934 584.739 226.892 584.407 227.224 584.214L230.445 582.351C230.776 582.16 231.354 582.185 231.735 582.404L234.771 584.161C235.155 584.382 235.197 584.715 234.865 584.905Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M226.964 589.477L223.74 591.339C223.411 591.53 222.834 591.508 222.453 591.287L219.414 589.529C219.033 589.308 218.991 588.976 219.323 588.785L222.544 586.92C222.876 586.729 223.453 586.754 223.834 586.973L226.873 588.73C227.254 588.951 227.296 589.286 226.964 589.477Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M242.766 580.337L239.542 582.202C239.213 582.393 238.636 582.368 238.255 582.147L235.216 580.392C234.835 580.171 234.793 579.836 235.125 579.645L238.346 577.783C238.677 577.592 239.255 577.614 239.636 577.835L242.672 579.592C243.056 579.814 243.095 580.146 242.766 580.337Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M242.363 589.244L239.141 591.107C238.81 591.298 238.233 591.275 237.851 591.054L234.815 589.297C234.434 589.076 234.393 588.744 234.721 588.553L237.945 586.688C238.274 586.497 238.851 586.521 239.233 586.743L242.271 588.497C242.653 588.719 242.694 589.053 242.363 589.244Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M234.464 593.813L231.241 595.675C230.909 595.866 230.332 595.844 229.95 595.623L226.914 593.866C226.533 593.644 226.492 593.312 226.821 593.121L230.044 591.256C230.376 591.065 230.951 591.09 231.332 591.311L234.371 593.069C234.752 593.287 234.793 593.622 234.464 593.813Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M250.263 584.676L247.042 586.538C246.711 586.729 246.133 586.704 245.752 586.485L242.716 584.728C242.335 584.507 242.293 584.175 242.622 583.981L245.846 582.119C246.175 581.928 246.752 581.953 247.133 582.171L250.172 583.929C250.554 584.15 250.595 584.482 250.263 584.676Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M250.299 593.832L247.075 595.697C246.747 595.888 246.169 595.863 245.788 595.642L242.749 593.888C242.368 593.666 242.327 593.331 242.658 593.141L245.879 591.278C246.211 591.087 246.788 591.109 247.169 591.331L250.208 593.088C250.589 593.309 250.631 593.641 250.299 593.832Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M242.399 598.401L239.177 600.266C238.846 600.457 238.268 600.432 237.887 600.213L234.848 598.456C234.467 598.235 234.426 597.9 234.757 597.709L237.978 595.847C238.31 595.656 238.887 595.678 239.269 595.899L242.307 597.657C242.689 597.878 242.73 598.21 242.399 598.401Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M258.2 589.264L254.976 591.126C254.648 591.32 254.07 591.295 253.689 591.073L250.65 589.316C250.269 589.098 250.228 588.763 250.559 588.572L253.78 586.707C254.112 586.516 254.689 586.541 255.07 586.762L258.106 588.519C258.49 588.738 258.532 589.073 258.2 589.264Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M257.8 598.169L254.576 600.034C254.244 600.225 253.667 600.2 253.286 599.981L250.25 598.224C249.868 598.003 249.827 597.668 250.156 597.477L253.38 595.614C253.708 595.424 254.286 595.446 254.667 595.667L257.706 597.424C258.087 597.646 258.128 597.978 257.8 598.169Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M249.899 602.74L246.675 604.602C246.343 604.793 245.769 604.768 245.385 604.55L242.349 602.792C241.968 602.571 241.926 602.239 242.255 602.048L245.479 600.183C245.81 599.992 246.385 600.017 246.766 600.236L249.805 601.993C250.186 602.214 250.228 602.549 249.899 602.74Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M265.698 593.6L262.477 595.465C262.145 595.656 261.568 595.631 261.187 595.41L258.15 593.655C257.769 593.434 257.728 593.099 258.057 592.908L261.28 591.046C261.609 590.855 262.187 590.877 262.568 591.098L265.607 592.856C265.988 593.077 266.029 593.409 265.698 593.6Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M265.297 602.507L262.073 604.37C261.745 604.561 261.167 604.536 260.786 604.317L257.747 602.56C257.366 602.339 257.324 602.007 257.656 601.816L260.877 599.951C261.209 599.76 261.786 599.785 262.167 600.003L265.203 601.76C265.587 601.982 265.629 602.316 265.297 602.507Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M257.396 607.076L254.172 608.938C253.844 609.129 253.266 609.107 252.885 608.886L249.846 607.129C249.465 606.907 249.424 606.575 249.755 606.384L252.976 604.519C253.308 604.328 253.885 604.353 254.266 604.575L257.305 606.329C257.686 606.55 257.728 606.885 257.396 607.076Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M273.198 597.936L269.974 599.801C269.645 599.992 269.068 599.967 268.687 599.749L265.648 597.991C265.267 597.77 265.225 597.435 265.557 597.244L268.778 595.382C269.109 595.191 269.687 595.213 270.068 595.435L273.104 597.192C273.485 597.413 273.53 597.745 273.198 597.936Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M273.234 607.095L270.01 608.961C269.679 609.151 269.101 609.127 268.72 608.905L265.684 607.148C265.303 606.929 265.261 606.595 265.59 606.404L268.814 604.541C269.145 604.348 269.72 604.373 270.101 604.594L273.14 606.351C273.521 606.57 273.563 606.905 273.234 607.095Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M265.333 611.664L262.109 613.529C261.778 613.72 261.203 613.695 260.819 613.474L257.783 611.719C257.402 611.498 257.36 611.163 257.689 610.972L260.913 609.11C261.245 608.919 261.819 608.941 262.203 609.163L265.239 610.92C265.62 611.141 265.662 611.473 265.333 611.664Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M281.132 602.527L277.911 604.389C277.579 604.58 277.002 604.558 276.621 604.337L273.585 602.579C273.204 602.358 273.162 602.026 273.491 601.835L276.715 599.97C277.044 599.779 277.621 599.804 278.002 600.025L281.041 601.78C281.422 602.001 281.464 602.336 281.132 602.527Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M280.731 611.432L277.508 613.297C277.179 613.488 276.602 613.463 276.22 613.241L273.182 611.487C272.8 611.266 272.759 610.931 273.09 610.74L276.311 608.878C276.643 608.687 277.22 608.709 277.602 608.93L280.64 610.687C281.022 610.909 281.063 611.241 280.731 611.432Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M272.831 616L269.609 617.865C269.278 618.056 268.701 618.031 268.319 617.813L265.281 616.056C264.899 615.834 264.858 615.499 265.189 615.308L268.411 613.446C268.742 613.255 269.319 613.28 269.701 613.499L272.739 615.256C273.121 615.477 273.162 615.809 272.831 616Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M288.632 606.863L285.409 608.728C285.08 608.919 284.502 608.894 284.121 608.673L281.082 606.916C280.701 606.697 280.66 606.362 280.991 606.171L284.212 604.309C284.544 604.115 285.121 604.14 285.502 604.361L288.538 606.119C288.922 606.337 288.964 606.672 288.632 606.863Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M288.229 615.768L285.008 617.633C284.676 617.824 284.099 617.799 283.718 617.58L280.682 615.823C280.301 615.602 280.259 615.267 280.588 615.076L283.812 613.214C284.141 613.023 284.718 613.048 285.099 613.266L288.138 615.023C288.519 615.245 288.561 615.577 288.229 615.768Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M280.331 620.339L277.107 622.201C276.776 622.392 276.201 622.37 275.817 622.149L272.781 620.392C272.4 620.17 272.358 619.838 272.687 619.647L275.911 617.782C276.242 617.591 276.817 617.616 277.198 617.835L280.237 619.592C280.618 619.813 280.66 620.148 280.331 620.339Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M296.13 611.199L292.909 613.064C292.577 613.255 292 613.23 291.619 613.009L288.583 611.254C288.201 611.033 288.16 610.698 288.489 610.507L291.713 608.645C292.041 608.454 292.619 608.476 293 608.698L296.039 610.455C296.42 610.676 296.461 611.008 296.13 611.199Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M296.166 620.358L292.942 622.221C292.613 622.412 292.036 622.39 291.655 622.168L288.616 620.411C288.235 620.192 288.193 619.858 288.525 619.667L291.746 617.802C292.077 617.611 292.655 617.636 293.036 617.857L296.075 619.614C296.456 619.833 296.497 620.168 296.166 620.358Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M288.265 624.927L285.044 626.792C284.712 626.983 284.135 626.958 283.754 626.737L280.715 624.98C280.334 624.761 280.292 624.426 280.624 624.235L283.845 622.373C284.176 622.182 284.754 622.204 285.135 622.426L288.174 624.183C288.555 624.401 288.596 624.736 288.265 624.927Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M304.067 615.79L300.843 617.652C300.514 617.843 299.937 617.821 299.555 617.6L296.517 615.842C296.135 615.621 296.094 615.289 296.425 615.098L299.647 613.233C299.978 613.042 300.555 613.067 300.937 613.288L303.973 615.043C304.357 615.264 304.398 615.599 304.067 615.79Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M303.666 624.695L300.442 626.56C300.111 626.751 299.536 626.726 299.152 626.504L296.116 624.747C295.735 624.529 295.693 624.194 296.022 624.003L299.246 622.141C299.575 621.95 300.152 621.972 300.533 622.193L303.572 623.95C303.953 624.169 303.995 624.504 303.666 624.695Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M295.765 629.263L292.541 631.128C292.21 631.319 291.635 631.294 291.251 631.073L288.215 629.319C287.834 629.097 287.793 628.762 288.121 628.571L291.345 626.709C291.677 626.518 292.251 626.54 292.635 626.762L295.671 628.519C296.053 628.74 296.094 629.072 295.765 629.263Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M311.564 620.126L308.343 621.988C308.012 622.179 307.434 622.157 307.053 621.936L304.017 620.179C303.636 619.96 303.594 619.625 303.923 619.434L307.147 617.569C307.476 617.378 308.053 617.403 308.434 617.625L311.473 619.382C311.854 619.6 311.896 619.935 311.564 620.126Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M311.164 629.031L307.94 630.896C307.611 631.087 307.034 631.062 306.652 630.843L303.614 629.086C303.232 628.865 303.191 628.53 303.522 628.339L306.744 626.477C307.075 626.286 307.652 626.308 308.034 626.529L311.072 628.286C311.454 628.508 311.495 628.84 311.164 629.031Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M303.263 633.602L300.039 635.465C299.71 635.655 299.133 635.631 298.752 635.412L295.713 633.655C295.332 633.433 295.29 633.101 295.622 632.91L298.843 631.045C299.174 630.854 299.752 630.879 300.133 631.098L303.172 632.855C303.553 633.076 303.594 633.409 303.263 633.602Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M319.065 624.462L315.841 626.327C315.512 626.518 314.935 626.493 314.553 626.272L311.515 624.518C311.133 624.296 311.092 623.961 311.423 623.77L314.644 621.908C314.976 621.717 315.553 621.739 315.935 621.961L318.971 623.718C319.355 623.939 319.396 624.271 319.065 624.462Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M319.1 633.622L315.877 635.484C315.545 635.675 314.97 635.653 314.586 635.431L311.55 633.674C311.169 633.453 311.128 633.121 311.456 632.93L314.68 631.065C315.012 630.874 315.587 630.899 315.968 631.12L319.007 632.874C319.388 633.096 319.429 633.431 319.1 633.622Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M311.2 638.19L307.976 640.055C307.644 640.246 307.07 640.221 306.686 640L303.65 638.243C303.268 638.024 303.227 637.689 303.556 637.498L306.78 635.633C307.111 635.442 307.686 635.467 308.07 635.689L311.106 637.446C311.487 637.664 311.528 637.999 311.2 638.19Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M326.999 629.053L323.777 630.915C323.446 631.106 322.869 631.084 322.487 630.863L319.451 629.105C319.07 628.884 319.029 628.552 319.357 628.361L322.581 626.496C322.91 626.305 323.487 626.33 323.869 626.549L326.907 628.306C327.289 628.527 327.33 628.862 326.999 629.053Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M326.598 637.958L323.374 639.823C323.045 640.014 322.468 639.989 322.087 639.768L319.048 638.01C318.667 637.792 318.625 637.457 318.957 637.266L322.178 635.401C322.509 635.21 323.087 635.235 323.468 635.456L326.507 637.213C326.888 637.432 326.93 637.767 326.598 637.958Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M318.697 642.526L315.476 644.391C315.145 644.582 314.567 644.557 314.186 644.336L311.147 642.582C310.766 642.36 310.724 642.026 311.056 641.835L314.277 639.972C314.609 639.781 315.186 639.803 315.567 640.025L318.606 641.782C318.987 642.003 319.029 642.335 318.697 642.526Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M334.499 633.389L331.275 635.251C330.946 635.442 330.369 635.42 329.988 635.199L326.949 633.442C326.568 633.22 326.526 632.888 326.858 632.697L330.079 630.832C330.41 630.641 330.988 630.666 331.369 630.888L334.405 632.642C334.789 632.863 334.83 633.198 334.499 633.389Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M334.098 642.294L330.874 644.159C330.543 644.35 329.968 644.325 329.584 644.104L326.548 642.349C326.167 642.128 326.126 641.793 326.454 641.602L329.678 639.74C330.01 639.549 330.584 639.571 330.966 639.792L334.004 641.549C334.386 641.771 334.427 642.103 334.098 642.294Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M326.197 646.862L322.974 648.728C322.642 648.918 322.067 648.894 321.683 648.675L318.647 646.918C318.266 646.696 318.225 646.362 318.553 646.171L321.777 644.308C322.109 644.117 322.683 644.14 323.067 644.361L326.103 646.118C326.485 646.339 326.526 646.672 326.197 646.862Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M341.996 637.725L338.775 639.59C338.444 639.781 337.866 639.756 337.485 639.535L334.449 637.778C334.068 637.559 334.026 637.224 334.355 637.034L337.579 635.168C337.908 634.978 338.485 635.002 338.866 635.224L341.905 636.981C342.286 637.2 342.328 637.534 341.996 637.725Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M342.032 646.885L338.811 648.747C338.48 648.938 337.902 648.916 337.521 648.694L334.482 646.937C334.101 646.716 334.06 646.384 334.391 646.193L337.612 644.328C337.944 644.137 338.521 644.162 338.902 644.38L341.941 646.137C342.322 646.359 342.364 646.694 342.032 646.885Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M334.131 651.453L330.91 653.315C330.579 653.506 330.001 653.484 329.62 653.263L326.581 651.506C326.2 651.284 326.159 650.952 326.49 650.761L329.714 648.896C330.043 648.705 330.62 648.73 331.001 648.952L334.04 650.709C334.422 650.927 334.463 651.262 334.131 651.453Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M349.933 642.313L346.709 644.178C346.381 644.369 345.803 644.344 345.422 644.126L342.383 642.369C342.002 642.147 341.96 641.815 342.292 641.622L345.513 639.759C345.845 639.568 346.422 639.593 346.803 639.812L349.842 641.569C350.223 641.79 350.265 642.122 349.933 642.313Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M143.657 522.719L140.433 524.581C140.104 524.772 139.527 524.747 139.146 524.528L136.107 522.771C135.726 522.55 135.684 522.218 136.016 522.027L139.237 520.162C139.568 519.971 140.146 519.996 140.527 520.214L143.566 521.972C143.947 522.193 143.988 522.528 143.657 522.719Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M143.256 531.623L140.032 533.486C139.701 533.679 139.126 533.655 138.742 533.433L135.706 531.676C135.325 531.457 135.284 531.123 135.612 530.932L138.836 529.067C139.168 528.876 139.742 528.901 140.126 529.122L143.162 530.879C143.544 531.098 143.585 531.433 143.256 531.623Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M151.157 527.055L147.933 528.917C147.602 529.108 147.027 529.086 146.643 528.865L143.607 527.107C143.226 526.886 143.185 526.554 143.513 526.363L146.737 524.498C147.069 524.307 147.643 524.332 148.025 524.553L151.063 526.308C151.445 526.529 151.486 526.864 151.157 527.055Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M151.19 536.211L147.969 538.077C147.638 538.267 147.06 538.243 146.679 538.024L143.64 536.267C143.259 536.045 143.218 535.711 143.549 535.52L146.773 533.657C147.102 533.466 147.679 533.491 148.06 533.71L151.099 535.467C151.48 535.688 151.522 536.021 151.19 536.211Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M143.29 540.783L140.068 542.645C139.737 542.836 139.16 542.814 138.778 542.592L135.742 540.835C135.358 540.614 135.317 540.282 135.648 540.091L138.872 538.226C139.201 538.035 139.778 538.06 140.16 538.279L143.198 540.036C143.58 540.257 143.621 540.592 143.29 540.783Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M159.091 531.643L155.87 533.508C155.539 533.699 154.961 533.674 154.58 533.453L151.541 531.698C151.16 531.477 151.119 531.142 151.45 530.951L154.671 529.089C155.003 528.898 155.58 528.92 155.961 529.141L159 530.898C159.381 531.12 159.423 531.452 159.091 531.643Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M158.691 540.55L155.467 542.413C155.135 542.604 154.561 542.581 154.179 542.36L151.141 540.603C150.759 540.382 150.718 540.05 151.047 539.859L154.271 537.994C154.602 537.803 155.18 537.828 155.561 538.046L158.597 539.803C158.978 540.025 159.019 540.359 158.691 540.55Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M150.79 545.119L147.566 546.981C147.237 547.172 146.66 547.15 146.279 546.929L143.24 545.172C142.859 544.95 142.817 544.618 143.149 544.427L146.37 542.562C146.701 542.371 147.279 542.396 147.66 542.617L150.696 544.372C151.077 544.593 151.119 544.928 150.79 545.119Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M166.592 535.979L163.368 537.844C163.036 538.035 162.462 538.01 162.078 537.792L159.042 536.034C158.66 535.813 158.619 535.478 158.948 535.287L162.171 533.425C162.503 533.234 163.078 533.259 163.462 533.478L166.498 535.235C166.879 535.456 166.92 535.788 166.592 535.979Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M147.56 513.023C150.025 516.711 153.889 520.248 159.196 523.316L489.87 714.484C507.76 724.828 534.819 725.948 550.311 716.985L695.104 633.22C701.652 629.432 705.047 624.448 705.478 619.177L705.503 619.163C707.127 610.474 704.632 600.49 704.632 600.49L694.997 601.032L694.988 601.066C693.682 600.103 692.303 599.159 690.759 598.268L360.085 407.098C342.195 396.754 315.136 395.636 299.644 404.596L154.851 488.361L143.839 487.786C143.839 487.786 139.637 499.723 146.881 511.988C146.922 512.057 146.961 512.104 147.002 512.17C147.157 512.43 147.334 512.688 147.505 512.945C147.524 512.97 147.544 512.998 147.56 513.023Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M148.925 512.046L148.955 512.09C151.387 515.729 155.113 519.016 160.033 521.858L490.709 713.031C508.108 723.087 534.468 724.211 549.471 715.533L694.265 631.765C700.102 628.386 703.4 623.986 703.806 619.039L703.983 618.084C704.958 611.877 703.862 605.078 703.298 602.247L696.259 602.646L695.908 603.789L693.994 602.419C692.519 601.331 691.226 600.476 689.919 599.724L359.246 408.553C341.853 398.495 315.484 397.371 300.484 406.052L155.26 490.069L145.096 489.535C144.325 492.736 142.867 501.893 148.326 511.13C148.538 511.47 148.652 511.639 148.765 511.808L147.549 513.006L148.925 512.046ZM524.059 724.681C511.868 724.681 499.028 721.723 489.03 715.942L158.356 524.769C152.992 521.67 148.895 518.037 146.174 513.972L145.977 513.678C145.834 513.468 145.696 513.255 145.566 513.045C137.88 500.053 142.077 487.744 142.259 487.227L142.676 486.04L154.442 486.66L298.804 403.141C314.736 393.926 342.596 395.05 360.925 405.64L691.599 596.813C692.853 597.535 694.088 598.34 695.45 599.323L705.922 598.733L706.26 600.081C706.367 600.504 708.826 610.527 707.152 619.473L707.124 619.62C706.519 625.589 702.663 630.788 695.944 634.676L551.151 718.441C543.913 722.628 534.208 724.681 524.059 724.681Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M154.24 473.82L299.033 390.052C314.523 381.092 341.585 382.21 359.475 392.554L690.149 583.724C708.039 594.065 709.984 609.716 694.494 618.676L549.701 702.441C534.209 711.404 507.15 710.283 489.259 699.942L158.586 508.772C140.695 498.428 138.751 482.78 154.24 473.82Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M325.289 385.666C315.747 385.666 306.65 387.589 299.873 391.51L155.08 475.273C148.878 478.862 145.461 483.738 145.461 489.001C145.461 495.512 150.552 502.186 159.423 507.317L490.099 698.487C507.495 708.543 533.858 709.661 548.861 700.986L693.654 617.22C699.856 613.631 703.273 608.758 703.273 603.495C703.273 596.987 698.182 590.307 689.309 585.179L358.635 394.009C349.096 388.494 336.864 385.666 325.289 385.666ZM523.448 710.137C511.257 710.137 498.42 707.176 488.419 701.398L157.746 510.228C147.806 504.483 142.107 496.743 142.107 489.001C142.107 482.484 146.116 476.576 153.4 472.364L298.194 388.596C314.12 379.385 341.988 380.505 360.315 391.098L690.988 582.268C700.925 588.016 706.63 595.75 706.63 603.495C706.63 610.009 702.619 615.917 695.334 620.132L550.541 703.897C543.303 708.083 533.598 710.137 523.448 710.137Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M300.497 401.373L289.798 407.585C286.331 409.597 286.337 412.881 289.809 414.882C293.282 416.883 293.287 420.164 289.823 422.176L209.287 468.941C205.822 470.953 200.145 470.962 196.673 468.964C193.2 466.963 187.523 466.971 184.056 468.983L161.5 482.083C153.713 486.604 153.724 493.982 161.528 498.478L496.149 691.215C510.487 699.472 533.927 699.434 548.237 691.124L687.234 610.413C695.022 605.892 695.011 598.512 687.204 594.018L352.585 401.281C338.245 393.021 314.807 393.063 300.497 401.373Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M190.391 469.143C188.388 469.143 186.385 469.575 184.899 470.438L162.343 483.538C159.116 485.409 157.34 487.805 157.345 490.285C157.351 492.767 159.133 495.16 162.365 497.02L496.986 689.759C510.895 697.77 533.51 697.731 547.394 689.671L686.391 608.96C689.618 607.084 691.392 604.688 691.386 602.209C691.386 599.729 689.599 597.336 686.367 595.473L351.748 402.737C337.833 394.726 315.222 394.765 301.34 402.828L290.641 409.04C289.519 409.691 288.881 410.49 288.881 411.235C288.881 411.979 289.525 412.776 290.646 413.424C292.837 414.683 294.091 416.542 294.097 418.521C294.099 420.499 292.848 422.362 290.666 423.632L210.129 470.394C206.129 472.719 199.85 472.732 195.836 470.419C194.358 469.567 192.374 469.143 190.391 469.143ZM522.089 699.054C512.393 699.054 502.702 696.929 495.312 692.673L160.691 499.937C156.376 497.452 153.994 494.023 153.989 490.293C153.983 486.56 156.351 483.129 160.66 480.627L183.217 467.53C187.219 465.206 193.496 465.192 197.51 467.505C200.474 469.215 205.485 469.204 208.444 467.486L288.98 420.723C290.099 420.073 290.74 419.273 290.74 418.526C290.737 417.782 290.094 416.982 288.972 416.337C286.784 415.076 285.527 413.219 285.525 411.24C285.522 409.262 286.773 407.399 288.956 406.132L299.655 399.917C314.465 391.319 338.582 391.275 353.422 399.823L688.041 592.559C692.359 595.047 694.737 598.47 694.743 602.203C694.751 605.933 692.381 609.37 688.074 611.869L549.076 692.579C541.645 696.896 531.863 699.054 522.089 699.054Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M262.115 425.096C260.142 423.956 260.142 422.107 262.115 420.967C264.084 419.827 267.281 419.824 269.253 420.964C271.223 422.104 271.223 423.953 269.253 425.093C267.281 426.233 264.084 426.233 262.115 425.096Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.361 451.483L251.33 427.204C253.037 426.216 256.021 426.341 257.99 427.478C259.963 428.618 260.178 430.345 258.471 431.33L216.502 455.612C214.795 456.597 211.812 456.475 209.842 455.335C207.869 454.195 207.654 452.471 209.361 451.483Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M519.495 722.177L517.559 722.157L517.686 709.578L519.622 709.6L519.495 722.177Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M697.058 631.718L695.207 631.153C697.204 624.62 695.287 618.377 695.267 618.314L697.113 617.732C697.201 618.012 699.229 624.617 697.058 631.718Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M691.276 635.096L689.425 634.532C691.422 627.999 689.508 621.756 689.486 621.692L691.331 621.114C691.419 621.391 693.447 627.999 691.276 635.096Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M159.105 524.028C157.845 518.56 158.169 510.535 158.185 510.197L160.119 510.277C160.116 510.358 159.793 518.38 160.992 523.593L159.105 524.028Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M152.748 520.35C151.489 514.882 151.812 506.857 151.826 506.52L153.76 506.6C153.76 506.68 153.434 514.702 154.635 519.916L152.748 520.35Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M231.144 555.886C230.989 555.886 230.87 555.914 230.787 555.961C230.52 556.116 230.478 556.553 230.478 556.799V557.286C230.478 558.753 231.752 560.729 233.257 561.598L253.545 573.328C254.286 573.757 254.808 573.754 255.043 573.626C255.313 573.472 255.352 573.034 255.352 572.788V572.301C255.352 570.834 254.081 568.859 252.576 567.99L232.288 556.26C231.812 555.983 231.423 555.886 231.144 555.886ZM254.692 575.641C254.029 575.641 253.302 575.431 252.578 575.01L232.288 563.28C230.188 562.065 228.542 559.434 228.542 557.286V556.799C228.542 555.654 228.995 554.757 229.821 554.281C230.749 553.747 231.997 553.855 233.257 554.577L253.543 566.307C255.642 567.522 257.289 570.154 257.289 572.301V572.788C257.289 573.936 256.836 574.83 256.012 575.306C255.62 575.53 255.175 575.641 254.692 575.641Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M263.698 574.706C263.543 574.706 263.424 574.736 263.341 574.78C263.071 574.938 263.032 575.375 263.032 575.619V576.106C263.032 577.575 264.305 579.548 265.808 580.417L286.099 592.147C286.842 592.579 287.367 592.573 287.594 592.449C287.867 592.291 287.906 591.854 287.906 591.608V591.121C287.906 589.654 286.632 587.678 285.127 586.809L264.841 575.079C264.363 574.802 263.977 574.706 263.698 574.706ZM287.248 594.463C286.585 594.463 285.856 594.247 285.129 593.827L264.841 582.1C262.742 580.885 261.095 578.253 261.095 576.106V575.619C261.095 574.473 261.548 573.579 262.372 573.101C263.303 572.569 264.551 572.672 265.808 573.397L286.096 585.127C288.196 586.344 289.842 588.976 289.842 591.121V591.608C289.842 592.756 289.389 593.65 288.563 594.126C288.174 594.353 287.726 594.463 287.248 594.463Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M201.632 538.821C201.477 538.821 201.358 538.851 201.275 538.901C201.005 539.056 200.966 539.493 200.966 539.74V540.227C200.966 541.693 202.239 543.669 203.745 544.538L215.154 551.135C215.897 551.564 216.42 551.558 216.654 551.431C216.922 551.276 216.964 550.839 216.964 550.592V550.105C216.964 548.639 215.69 546.663 214.185 545.794L202.775 539.197C202.297 538.921 201.908 538.821 201.632 538.821ZM216.303 553.448C215.64 553.448 214.914 553.235 214.187 552.817L202.775 546.22C200.676 545.006 199.029 542.374 199.029 540.227V539.742C199.029 538.591 199.482 537.697 200.308 537.221C201.237 536.685 202.488 536.793 203.742 537.518L215.154 544.112C217.254 545.327 218.9 547.961 218.9 550.105V550.592C218.9 551.741 218.447 552.635 217.621 553.111C217.232 553.338 216.784 553.448 216.303 553.448Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M208.878 545.36V544.873C208.875 543.066 210.336 542.449 212.138 543.489L214.671 544.953C216.472 545.996 217.931 548.301 217.931 550.105V550.592C217.933 552.397 216.472 553.016 214.671 551.976L212.138 550.512C210.336 549.469 208.878 547.164 208.878 545.36Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.845 545.357C209.845 546.826 211.118 548.799 212.624 549.668L215.154 551.135C215.897 551.564 216.42 551.558 216.654 551.431C216.922 551.276 216.964 550.839 216.964 550.592V550.105C216.964 548.639 215.69 546.663 214.185 545.794L211.654 544.33C210.908 543.901 210.381 543.907 210.157 544.031C209.883 544.189 209.845 544.626 209.845 544.87V545.357ZM216.303 553.448C215.64 553.448 214.914 553.235 214.187 552.817L211.657 551.351C209.555 550.139 207.908 547.507 207.908 545.357V544.87C207.908 543.724 208.361 542.831 209.187 542.352C210.115 541.821 211.364 541.926 212.621 542.648L215.154 544.112C217.254 545.327 218.9 547.961 218.9 550.105V550.592C218.9 551.741 218.447 552.635 217.621 553.111C217.232 553.338 216.784 553.448 216.303 553.448Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M524.645 722.592L524.479 709.603L526.415 709.578L526.581 722.567L524.645 722.592Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M501.32 718.729L499.384 718.709L499.517 706.13L501.453 706.152L501.32 718.729Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M471.844 702.779L469.908 702.759L470.035 690.18L471.971 690.199L471.844 702.779Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M558.726 625.066L555.138 622.99L556.173 621.189L559.762 623.264L558.726 625.066Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M571.448 632.426L565.085 628.746L566.121 626.944L572.484 630.628L571.448 632.426ZM584.169 639.787L577.807 636.107L578.843 634.308L585.205 637.988L584.169 639.787ZM596.891 647.148L590.528 643.467L591.564 641.669L597.927 645.349L596.891 647.148Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M606.839 652.903L603.25 650.825L604.286 649.032L607.875 651.11L606.839 652.903Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M505.229 650.413L599.764 596.187L568.061 577.702L473.527 631.928L505.229 650.413ZM604.001 596.204L505.221 652.865L469.289 631.914L568.069 575.254L604.001 596.204Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M601.883 588.591L505.227 644.034L471.408 624.316L568.063 568.872L601.883 588.591Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M525.233 616.067C526.291 616.681 526.714 617.381 526.498 618.17C526.283 618.958 525.465 619.764 524.04 620.588C522.531 621.46 521.114 622.002 519.794 622.215L518.194 621.291C519.017 621.133 519.824 620.92 520.609 620.652C521.393 620.381 522.075 620.079 522.658 619.741C523.556 619.221 524.106 618.737 524.302 618.291C524.501 617.846 524.305 617.453 523.716 617.113C523.274 616.855 522.703 616.747 522.004 616.789C521.305 616.828 520.183 617.046 518.642 617.442C517.189 617.827 516.092 618.062 515.351 618.15C514.611 618.239 513.945 618.239 513.351 618.148C512.76 618.059 512.205 617.863 511.686 617.564C510.76 617.027 510.404 616.385 510.622 615.638C510.84 614.893 511.608 614.138 512.923 613.38C514.147 612.671 515.592 612.123 517.26 611.733L518.05 612.898C516.437 613.286 515.166 613.748 514.241 614.285C513.423 614.758 512.937 615.187 512.777 615.577C512.617 615.964 512.763 616.288 513.213 616.548C513.517 616.725 513.854 616.83 514.224 616.864C514.594 616.897 515.05 616.869 515.589 616.783C516.13 616.695 517.073 616.473 518.423 616.116C520.263 615.621 521.669 615.378 522.642 615.391C523.614 615.408 524.479 615.632 525.233 616.067Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M533.598 613.416C533.902 613.239 534.175 613.056 534.413 612.865C534.651 612.674 534.83 612.519 534.946 612.395L536.267 613.156C536.17 613.316 535.968 613.518 535.664 613.762C535.361 614.008 535.062 614.213 534.772 614.381C532.57 615.654 530.311 615.621 527.993 614.279L521.225 610.366L519.597 611.307L518.766 610.828L519.68 609.47L517.976 607.646L518.973 607.071L521.609 608.595L524.91 606.686L526.25 607.461L522.948 609.37L529.642 613.241C530.327 613.637 531.018 613.847 531.708 613.872C532.402 613.894 533.032 613.742 533.598 613.416Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M540.532 609.406C541.604 608.783 542.154 608.128 542.176 607.433C542.201 606.738 541.684 606.085 540.626 605.474L539.598 604.879L537.946 605.917C536.626 606.738 535.849 607.433 535.623 608C535.394 608.568 535.678 609.082 536.474 609.544C537.095 609.904 537.758 610.067 538.46 610.037C539.165 610.009 539.852 609.796 540.532 609.406ZM545.637 607.856L543.676 607.118L543.593 607.165C543.739 607.906 543.656 608.512 543.344 608.985C543.032 609.456 542.455 609.937 541.612 610.424C540.482 611.077 539.308 611.421 538.087 611.454C536.866 611.49 535.72 611.196 534.648 610.577C532.349 609.246 532.982 607.488 536.546 605.3L538.413 604.146L537.709 603.739C536.816 603.221 535.965 602.953 535.156 602.928C534.347 602.903 533.518 603.136 532.675 603.625C531.725 604.173 530.943 604.962 530.33 605.989L528.482 605.532C528.736 605.034 529.112 604.539 529.606 604.054C530.101 603.567 530.686 603.13 531.355 602.743C532.711 601.957 534.018 601.55 535.275 601.52C536.529 601.489 537.822 601.86 539.151 602.629L546.916 607.118L545.637 607.856Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M544.071 595.365C544.576 595.072 545.071 594.834 545.554 594.651L546.916 595.714C546.342 595.924 545.875 596.135 545.513 596.342C544.593 596.876 544.181 597.546 544.272 598.357C544.366 599.167 544.971 599.895 546.085 600.54L552.187 604.068L550.466 605.064L539.09 598.487L540.51 597.665L542.817 598.769L542.899 598.719C542.579 598.047 542.518 597.424 542.712 596.849C542.905 596.27 543.358 595.778 544.071 595.365Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M561.602 597.219C561.906 597.042 562.179 596.86 562.417 596.671C562.657 596.481 562.834 596.323 562.953 596.198L564.27 596.962C564.174 597.123 563.972 597.325 563.668 597.568C563.364 597.812 563.066 598.019 562.776 598.185C560.574 599.458 558.314 599.425 555.997 598.083L549.228 594.17L547.601 595.114L546.77 594.632L547.684 593.276L545.98 591.45L546.977 590.874L549.615 592.399L552.914 590.49L554.253 591.264L550.952 593.174L557.649 597.045C558.334 597.441 559.022 597.651 559.712 597.676C560.406 597.701 561.035 597.549 561.602 597.219Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M314.139 494.671L393.161 488.699C393.17 488.699 393.178 488.702 393.186 488.707L404.69 496.627C404.695 496.633 404.701 496.635 404.709 496.635C421.969 498.163 438.533 502.654 451.678 510.059C456.332 512.682 460.313 515.543 463.725 518.554C463.733 518.562 463.747 518.565 463.758 518.562L477.958 516.113C477.985 516.108 478.007 516.127 478.007 516.155L477.847 528.89L477.811 528.912C477.701 541.591 469.289 554.217 452.509 563.803C418.867 583.024 364.486 582.736 331.046 563.161C327.197 560.908 323.844 558.482 320.885 555.95C320.877 555.941 320.871 555.93 320.871 555.919L321.029 543.224C321.029 543.201 321.042 543.185 321.062 543.182L358.652 536.696C358.696 536.69 465.648 541.209 382.473 525.419L313.973 507.433C313.957 507.427 313.943 507.411 313.943 507.391L314.1 494.712C314.1 494.69 314.117 494.673 314.139 494.671Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M322.17 555.346C325.126 557.826 328.311 560.062 331.698 562.046C364.729 581.38 418.638 581.668 451.871 562.679C467.656 553.664 476.411 541.666 476.518 528.903L476.698 517.641L463.319 519.952L462.813 519.467C459.396 516.457 455.418 513.651 451.045 511.185C438.437 504.085 422.373 499.497 404.596 497.925L404.267 497.897L403.971 497.703L392.794 490.005L315.377 495.872L315.247 506.431L382.802 524.169C415.9 530.447 415.624 532.883 415.422 534.662C415.251 536.17 414.795 540.191 358.823 537.996L322.308 544.281L322.17 555.346ZM392.496 579.283C370 579.285 347.472 574.271 330.396 564.279C326.681 562.101 323.2 559.63 320.045 556.935L319.595 556.547L319.578 555.919L319.752 542.078L320.902 541.898L358.566 535.398L358.925 535.415C386.542 536.494 408.342 536.037 412.447 534.346C410.9 533.361 404.944 531.001 382.236 526.69L312.683 508.429L312.65 507.391L312.686 494.347L314.039 493.381L393.653 487.368L394.101 487.794L405.156 495.382C423.232 497.028 439.489 501.71 452.31 508.933C456.653 511.379 460.623 514.154 464.126 517.187L478.322 514.738L479.284 515.51L479.3 516.152L479.085 529.653C478.654 543.102 469.471 555.604 453.15 564.926C436.381 574.506 414.455 579.283 392.496 579.283Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M391.998 515.577L314.164 494.69C314.132 494.682 314.122 494.646 314.142 494.624C319.612 487.747 327.794 481.424 339.107 476.349C372.531 461.359 421.356 462.942 451.835 480.115C486.477 499.635 486.712 531.604 452.669 551.052C419.024 570.273 364.644 569.985 331.203 550.41C327.372 548.166 324.032 545.753 321.081 543.235C321.056 543.212 321.065 543.174 321.095 543.163L392.001 515.654C392.04 515.64 392.037 515.585 391.998 515.577Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M323.551 543.597C326.15 545.675 328.932 547.582 331.852 549.295C364.89 568.632 418.798 568.917 452.028 549.928C467.988 540.811 476.759 528.701 476.72 515.831C476.679 502.776 467.617 490.492 451.202 481.242C420.922 464.185 372.956 462.583 339.634 477.531C329.805 481.936 321.993 487.454 316.379 493.946L393.222 514.55L393.382 516.465L392.482 516.855L323.551 543.597ZM392.653 566.534C370.155 566.534 347.626 561.523 330.554 551.528C326.863 549.369 323.396 546.909 320.244 544.217L319.705 543.755L319.893 542.285L320.598 541.967L387.971 515.831L313.139 495.755L312.686 494.347L313.175 493.763C319.067 486.355 327.631 480.079 338.582 475.17C372.578 459.92 421.536 461.564 452.468 478.989C469.731 488.716 479.259 501.796 479.303 515.82C479.342 529.664 470.112 542.576 453.31 552.175C436.539 561.755 414.613 566.531 392.653 566.534Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M321.258 543.099L392.037 515.64C392.067 515.629 392.095 515.66 392.081 515.687L367.459 563.108L367.456 563.111C354.174 560.767 341.665 556.534 331.203 550.41C327.427 548.199 324.131 545.825 321.214 543.345C321.128 543.273 321.156 543.138 321.258 543.099Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M323.548 543.597C326.131 545.659 328.918 547.573 331.852 549.295C341.579 554.987 353.627 559.256 366.751 561.669L389.402 518.05L323.548 543.597ZM368.169 564.545L367.235 564.381C353.453 561.954 340.77 557.508 330.554 551.528C326.913 549.397 323.493 546.979 320.379 544.333L319.813 543.852L320.02 542.272L320.791 541.895L392.402 514.113L393.601 515.413L393.255 516.224L368.169 564.545Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M392.021 515.804C392.037 515.77 392.12 515.779 392.12 515.812L391.86 565.278L391.858 565.281C383.631 565.236 375.418 564.486 367.475 563.078L392.021 515.804Z"
        fill="#7CDEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M369.437 562.101C376.387 563.241 383.484 563.872 390.573 563.975L390.802 520.95L369.437 562.101ZM393.142 566.578L391.852 566.57C383.6 566.529 375.321 565.781 367.252 564.351L365.519 564.046L391.36 514.304L393.382 514.78L393.413 515.812L393.142 566.578Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M391.7 565.369L391.962 515.989C391.962 515.953 392.051 515.947 392.062 515.983L409.063 564.417C403.336 565.068 397.523 565.402 391.703 565.369H391.7Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M393.211 523.15L392.998 564.077C397.697 564.058 402.499 563.8 407.308 563.305L393.211 523.15ZM392.479 566.664C392.217 566.664 391.957 566.664 391.695 566.661L390.404 566.656L390.675 514.86L392.863 514.536L393.277 515.549L410.82 565.519L409.212 565.701C403.59 566.34 397.965 566.664 392.479 566.664Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M368.611 575.876L366.028 575.846L366.185 563.064L368.765 563.095L368.611 575.876Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M461.532 558.349L458.949 558.318L459.106 545.537L461.689 545.567L461.532 558.349Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M451.846 565.35L449.266 565.319L449.423 552.538L452.006 552.568L451.846 565.35Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M333.761 513.136L331.181 513.106L331.338 500.321L333.921 500.352L333.761 513.136Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M353.851 581.812C337.336 578.602 324.507 569.73 318.33 564.727C314.852 561.916 312.672 557.71 312.343 553.185L312.249 543.124L314.832 543.099L314.923 553.08C315.194 556.813 317.029 560.352 319.954 562.718C325.943 567.566 338.378 576.169 354.345 579.271L353.851 581.812Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M379.702 586.364L363.652 584.012L364.025 581.452L380.075 583.804L379.702 586.364Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M360.108 463.568L359.29 461.113C359.947 460.894 375.57 455.772 393.261 457.474L393.015 460.05C375.862 458.385 360.265 463.515 360.108 463.568Z"
        fill="#101113"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M340.963 469.606L340.035 467.193L352.466 462.394L353.395 464.807L340.963 469.606Z"
        fill="#101113"
      />
    </svg>
  </div>
);

export default StreetArt;
